import React, {useEffect, useRef, useState} from 'react';
import './cd.scss';
import {ArrowBack} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import {Search, ArrowDown, DeleteProduct} from '../../../assets/icons/component';
import {useLocation, useNavigate} from 'react-router-dom';
import {AppRoutes} from "../../../Router";
import { EditInfo } from './popup/EditInfo';
import { AddEditInfo } from './popup/AddEditItem';
import { Attribute } from './popup/Attribute';
import { TableF } from '../../shared/Table/Table';
import { Popup } from '../../shared/Popup';
import { InventoryService } from '../../../services/InventoryService';
import NumberFormat from 'react-number-format';
import { EditProduct } from './popup/EditProduct';

const CategoryHeader = [
    {
        header: 'Nombre del producto'
    },
    {
        header: 'Valor comercial'
    },
    {
        header: 'Stock'
    },
    // {
    //     header: 'Foto'
    // },
    {
        header: 'Acciones'
    }
]
const Options = [
    {option: 'Nombre del producto'},
    {option: 'Valor comercial'},
    {option: 'Stock'},
]

interface InfoProps {
    info: {
        R_s_cod_prod: string,
        R_s_nom_prod: string,
        R_s_precio_comer: string,
        R_s_unid_stock: string,
        R_s_link_img: string,

    };
    setFunction: React.Dispatch<React.SetStateAction<boolean>>;
}




interface OptionProps {
    Options: {
        option: string
    }[];
    bg: string;
    clr: string;
    str: string;
}

export const CategoryDetail: React.FC = () => {
    const navigate = useNavigate();
    const { state }: { state: any } = useLocation();
    const [infoCategory,setInfoCategory] = React.useState([] as any);
    const [editInfo, setEditInfo] = useState(false);
    const [addeditInfo, setAddEditInfo] = useState(false);
    const [editInfoProduct, setEditInfoProduct] = useState(false);
    const [attribute, setAttribute] = useState(false);
    const textInputRef = useRef<HTMLInputElement>(null);
    const [productos, setProductos] = React.useState([] as any);
    const [selectedOption, setSelectedOption] = useState('');
    const [productosFilter, setproductosFilter] = React.useState([] as any);
    const [editProduct, setEditProduct] = React.useState([] as any);

    const Select: React.FC <OptionProps> = ({Options,bg,clr,str}) => {
        const handleSelectChange = (event : any) => {
            setSelectedOption(event.target.value);
        };
        return(
            <li className='filter'>
                <ArrowDown stroke={str} />
                <select id="search" value={selectedOption} onChange={handleSelectChange} style={{background:bg,color:clr}}>
                    {
                        Options.map((option,i)=>{
                            return(
                                <option key={i} value={option.option}>{option.option}</option>
                            )
                        })
                    }
                </select>
            </li>
        )
    }

    useEffect(()=>{
        setInfoCategory(state); 
        ProductCategory();  
        if (addeditInfo){
            setEditProduct([])
        }
    },[state,addeditInfo,editInfoProduct]);

    const ProductCategory= async ()=>{ 
        const valorProduct= await InventoryService.postProductQuery({
            pws_cod_producto:"",
            pws_tip_producto:"",
            pws_cate_producto:state.R_s_cod_cate
        });
        if(valorProduct.payload.data.RespProd != null){
            setProductos(valorProduct.payload.data.RespProd.RespuestaProd); 
            setproductosFilter(valorProduct.payload.data.RespProd.RespuestaProd);        
        }    
    }
    
    const handleChange = async (event: any) => {
        const filtro = event.target.value.toString().toUpperCase();
        let filterNumbers = productos.filter((num:any) => {
            if(selectedOption === "Nombre del producto" || selectedOption === ""){
                if((num.R_s_nom_prod).includes(filtro))
                    return true
                else
                    return false
            }
            if(selectedOption === "Valor comercial" || selectedOption === ""){
                if((num.R_s_precio_comer.toString()).includes(filtro))
                    return true
                else
                    return false
            }
            if(selectedOption === "Stock" || selectedOption === ""){
                if((num.R_s_unid_stock).includes(filtro))
                    return true
                else
                    return false
            }
            else{
                return true
            }
        });
        setproductosFilter(filterNumbers)
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        let enteredText = textInputRef.current!.value;
    }
    const handleSubmitBack = () => {
        navigate(AppRoutes.CATALAGO_ADMINI,{replace:true});
    };

    const handleDeleteProduct = () => {
        ProductCategory();
    };
    const CategoryItem:  React.FC <InfoProps> = ({info,setFunction}) => {
        return (
          <>
              <ul>
                  <li className="listItem firstItem">{info.R_s_nom_prod}</li>
                  <li className="listItem">{
                  <NumberFormat value={info.R_s_precio_comer} displayType={'text'} thousandSeparator={true} prefix={'$'}/> }</li>
                  <li className="listItem">{info.R_s_unid_stock}</li>
                  {/* <li className="listItem">{info.R_s_link_img =! "" ? "SI" : "NO"}</li> */}
                  <li className="listItem" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                    <button  style={{color: '#fff', padding: '4px 10px'}} 
                             onClick={()=>{setEditProduct(info); setEditInfoProduct(true);
                              }}>
                        Editar
                    </button>
                    <DeleteProduct TableItem ={info.R_s_cod_prod} onClick={handleDeleteProduct} />
                  </li>  
              </ul>
          </>
        )
    }

    

  return (
    <>
        {
            editInfo
            ?<>
                <Popup setfunc={setEditInfo} Body={<EditInfo/>} />
            </>
            :<></>
        }
        {
            addeditInfo
            ?<>
                <Popup setfunc={setAddEditInfo} Body={<AddEditInfo productInfo={editProduct}/>} />
            </>
            :<></>
        }
        {
            editInfoProduct
            ?<>
                <Popup setfunc={setEditInfoProduct} Body={<EditProduct productInfo={editProduct}/>} />
            </>
            :<></>
        }
        {
            attribute
            ?<>
                <Popup setfunc={setAttribute} Body={<Attribute />} />
            </>
            :<></>
        }
        <div className="UserDoc">
            <Fab variant="extended" color="neutral" aria-label="add"
                            onClick={handleSubmitBack} style={{float:"left"}} className="hooverButtonRed">
                            <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                        ATRAS
                        </Fab>
            <div className="UserDocument">
                <h4>Detalle de la categoría</h4>
                <div className="UserTable">
                    <div className="first-part">
                        <div className="cat-head">
                            <div className="foto">
                                <img src="img/modelo-1.png" alt="moto" />
                                <p>foto</p>
                            </div>
                            <div className="desc">
                                <h5>Nombre categoría: {infoCategory.R_s_nom_cate}</h5>
                                <h5>Número de productos: {infoCategory.R_s_cant_prod_cate}</h5>
                                <h6 onClick={()=>setEditInfo(true)}>Editar información categoría</h6>
                            </div>
                        </div>
                        <div className="userAdd">
                            <button onClick={()=>setAddEditInfo(true)} style={{background: '#00908E', color: '#fff'}}>Agregar nuevo producto </button>
                            {/* <button onClick={()=>setAttribute(true)} style={{background: '#00908E', color: '#fff'}}>Parametrización atributos </button> */}
                        </div>

                        <div className="table">
                            <TableF TableItem={CategoryItem} headers={CategoryHeader} data={productosFilter} setFunction={setEditInfo}/>
                        </div>
                    </div>
                    <div className="second-part">
                        <div className="search">
                            <form action="" method="post" onSubmit={handleSubmit}>
                                <Select Options={Options} bg={`#00908E`} clr={`#fff`} str={`#fff`} />
                                <li>
                                    <Search />
                                    <input type="text" name="seacrh" placeholder="Search" ref={textInputRef} onChange={handleChange}/>
                                    {/* <input type="text" name="seacrh" placeholder="Search" ref={textInputRef}/> */}
                                </li>
                            </form>
                        </div>
                        <div className="image">
                            <img src="img/corporative.png" alt="corporativa"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
