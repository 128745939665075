import './request-info.scss';
import {useIntl} from "react-intl";
import {Field} from 'formik';
import {typeIdentification} from '../../../__mocks__/data';
import _ from 'lodash';
import {WLOCreditAPIService} from '../../../services';
import toast from "react-hot-toast";
import {useLoginContext} from '../../../hooks/useLoginContext';
import {useRequestContext} from '../../../hooks/useRequestContext';
import {ANIPersona, CygnusPersona} from '../../../context/RequestContext';
import {useEffect, useState} from 'react';
import BarLoader from "react-spinners/BarLoader";
import ClockLoader from "react-spinners/ClockLoader";

interface FormKnowYouProps {
    errors?: any,
    values?: any,
}
export function FormKnowYou(props: FormKnowYouProps) {
    const {user} = useLoginContext();
    const {request, setRequest} = useRequestContext();
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const {errors, values} = props;
    const intl = useIntl();
    
    useEffect(() => {
        const datosPersonaString = sessionStorage.getItem("personaValidada");
        if (datosPersonaString) {
            const datosPersona = JSON.parse(datosPersonaString);
            const numDoc = datosPersona.numIdenVal;
            const numTipoDoc = datosPersona.tipIdenVal;
            if (numDoc) {
            values.Pws_Identificacion = numDoc;
            }
            if (numTipoDoc) {
            values.Pws_Tip_Identificacion = numTipoDoc;
            }
        }
        handleSearch()
    }, []);
    const handleSearch = async () => {
        let consultaANI = false;
        setIsLoadingInfo(true);
        const datosPersona = sessionStorage.getItem('personaValidada');
        if (datosPersona){
                    if( !isNaN(values.Pws_Identificacion)){
                        try {
                            const person = await WLOCreditAPIService.consultaSolicitudCygnus({
                                Pws_Tip_Identificacion: values.Pws_Tip_Identificacion!,
                                Pws_Identificacion: values.Pws_Identificacion!
                            });
                            if (!_.isEmpty(person.payload)) {
                                if (person.payload.result === "1") {
                                    const pCygnus = person.payload.data.datos_persona.Datos_persona[0] as CygnusPersona;
                                    values.Pws_Apellidos1=pCygnus.Pws_Apellidos1;
                                    values.Pws_Apellidos2=pCygnus.Pws_Apellidos2;
                                    values.Pws_Nombres=pCygnus.Pws_Nombres;
                                    setRequest({...request, personCygnus: pCygnus, searchANI: false, errorConsulta: false});
                                    sessionStorage.setItem('person', JSON.stringify(person.payload.data.datos_persona.Datos_persona[0]));
                                    setIsLoadingInfo(false);
                                } else {
                                    consultaANI = true;
                                    const personANI = await WLOCreditAPIService.consultaSolicitudANI({
                                        Pws_Tip_Identificacion: values.Pws_Tip_Identificacion!,
                                        Pws_Identificacion: values.Pws_Identificacion!
                                    });
                                    
                                    const pANI = personANI as ANIPersona;
                                    const fecha = personANI.fechaExpedicion.split('/');
                                    values.Pws_Apellidos1=pANI.primerApellido;
                                    values.Pws_Apellidos2=pANI.segundoApellido;
                                    values.Pws_Nombres=pANI.primerNombre+" "+pANI.segundoNombre;
                                    values.Pws_Fec_expe= fecha[2]+'-'+fecha[1]+'-'+fecha[0]
                                    pANI.fechaExpedicion = fecha[2]+'-'+fecha[1]+'-'+fecha[0]
                                    setIsLoadingInfo(false);
                                    setRequest({...request, personANI: pANI, searchANI: true, errorConsulta: false});
                                    sessionStorage.setItem('person', JSON.stringify(person));
                                }
                            }else{
                                toast.error('En estos momentos no podemos atender su solicitud',{
                                    duration:5000,
                                });
                                
                            }
                        } catch (e) {
                            const error = e as any;
                            if (!_.isEmpty(error.response.data)) {
                                setRequest({...request, errorConsulta: true});
                                const menssage = error.response.data as any;
                                toast.error("El documento de identificación no existe.",{
                                    duration:5000,
                                });
                                setTimeout(() => {
                                    setIsLoadingInfo(false);
                                }, 5000);
                        } else {
                            setRequest({...request, errorConsulta: true});
                            toast.error('En estos momentos no podemos atender su solicitud',{
                                duration:5000,
                            });
                            setTimeout(() => {
                                setIsLoadingInfo(false);
                            }, 5000);
                            
                        }
                        }
                    }else{
                        toast.error("El documento de identificacion debe ser numerico",{
                            duration:5000,
                        })
                    }
        }
    }

    return (
        <div className={'row'}>
            <ClockLoader id='spinner' color={"#009694"} loading={isLoadingInfo} size={100}/>
            <div className={"title-medium-red"}>{intl.formatMessage({id: "form_credit_information_title"})}</div>
            <div className={"subtitle-medium"}>{intl.formatMessage({id: "form_credit_information_subtitle"})}</div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-right flex-grow-6"}>
                    <Field as="select"
                        id={"Pws_Tip_Identificacion"}
                        name={"Pws_Tip_Identificacion"}
                        className={`${errors.Pws_Tip_Identificacion ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                        disabled>
                        {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item, key: number) => {
                            return (
                                <option key={key} value={item.s_codigo}>{item.s_descripcion}</option>
                            )
                        })}
                    </Field>
                </div>
                <div className={"flex-container-form-know-you-item-left flex-grow-6"}>
                    <Field id={"Pws_Identificacion"}
                        name={"Pws_Identificacion"}
                        disabled
                        className={`${errors.Pws_Identificacion ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}/>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-6"}>
                    <Field id={"Pws_Nombres"}
                        name={"Pws_Nombres"}
                        disabled
                        className={`${errors.Pws_Nombres ? 'form-know-you-item-input-large input-error' : 'form-know-you-item-input-large'}`}
                        placeholder={"Nombres"}/>
                </div>
            </div>
                <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-right flex-grow-6"}>
                    <Field id={"Pws_Apellidos1"}
                        name={"Pws_Apellidos1"}
                        disabled
                        className={`${errors.Pws_Apellidos1 ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                        placeholder={'Primer Apellido'}/>
                </div>
                <div className={"flex-container-form-know-you-item-left flex-grow-6"}>
                    <Field id={"Pws_Apellidos2"}
                        name={"Pws_Apellidos2"}
                        disabled
                        className={`${errors.Pws_Apellidos2 ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                        placeholder={'Segundo Apellido'}/>
                </div>
                </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-right flex-grow-6"}>
                    <Field id={"Pws_Telefono1"}
                        name={"Pws_Telefono1"}
                        className={`${errors.Pws_Telefono1 ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                        placeholder={intl.formatMessage({id: "placeholder_phone_1"})}/>
                </div>
                <div className={"flex-container-form-know-you-item-left flex-grow-6"}>
                    <Field id={"Pws_Correo"}
                        name={"Pws_Correo"}
                        className={`${errors.Pws_Correo ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                        placeholder={intl.formatMessage({id: "placeholder_mail"})}/>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-right flex-grow-6"}>
                    <Field id={"Pws_Telefono2"}
                        name={"Pws_Telefono2"}
                        className={`${errors.Pws_Telefono2 ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                        placeholder={intl.formatMessage({id: "placeholder_phone_2"})}/>
                </div>
                <div className={"flex-container-form-know-you-item-left flex-grow-6"}>
                    <Field id={"mail_confirmation"}
                    onPaste={(e:any)=>{
                        e.preventDefault()
                        return false;
                    }} 
                    autoComplete="off"
                    name={"mail_confirmation"}
                    className={`${errors.mail_confirmation ? 'form-know-you-item-input-small input-error' : 'form-know-you-item-input-small'}`}
                    placeholder={intl.formatMessage({id: "placeholder_mail_confirmation"})}/>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-6"}>
                    <Field id={"Num_poliza"}
                        name={"Num_poliza"}
                        className={`${errors.Num_poliza ? 'form-know-you-item-input-large input-error' : 'form-know-you-item-input-large'}`}
                        placeholder={"Numero de poliza"}/>
                </div>
            </div>
        </div>

    )
}