import './ud.scss';
import React, {useRef} from 'react';
import Fab from '@mui/material/Fab';
import {Table} from '../Table/Table';
import {AppRoutes} from "../../../Router";
import { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import {ArrowBack} from '@mui/icons-material';
import { WloCreditoService } from '../../../types';
import { AdminService, WLOCreditAPIService,InventoryService, AuthService } from '../../../services';
import {Search, ArrowDown, UserIcon, UserAddIcon} from '../../../assets/icons/component';
import moment from 'moment';
import { log } from 'console';

const userHeaders = [
    {header: 'Empleado'},
    {header: 'Perfil'},
    {header: 'Identificación'},
    {header: 'Acciones'}
]

interface InfoProps {
    info: {
        Pws_Nombre: string,
        Pws_tipoIdentificacion: string,
        Pws_Tip_Usuario: string,
        Pws_identificacion: string,
        Pws_Codigo_Persona: string,
        color1: string,
    };
}

const Options = [
    {option: 'Empleado'},
    {option: 'Perfil'},
    {option: 'Identificación'}
]

interface OptionProps {
    Options: {
        option: string
    }[];
    bg: string;
    clr: string;
    str: string;
}

export const Select2: React.FC <OptionProps> = ({Options,bg,clr,str}) => {
    return(
        <>
            <ArrowDown stroke={str} />
            <select name="search" id="search" style={{background:bg,color:clr}}>
                {
                    Options.map((option,i)=>{
                        return(
                            <option key={i} value={option.option}>{option.option}</option>
                        )
                    })
                }
            </select>
        </>
    )
}

export const UserDocument: React.FC = () => {

    const navigate = useNavigate();

    const textInputRef = useRef<HTMLInputElement>(null);
    useEffect(()=>{  
        checkUser("1");        
    },[]);

    const [ArrayPerson, setArrayPerson] = React.useState({
        Nombre: '',
        Identificacion:'',
        Cargo:'',
        Email:'',
        Telefono1:'',
        Telefono2:'',
        PerfilUsuario:'',
        TipoIdentificacion:''
    });

    const [usuariosConsulta, setUsuariosConsulta] = React.useState([] as any);

    const UserItem: React.FC <InfoProps> = ({info}) => {
        console.log(info);
        
        const handleClick = async () => {            
            let sends:WloCreditoService.SolicitudDetalla={
                Pws_Num_Solicitud:"",
                Pws_Identificacion: info.Pws_identificacion,
                Pws_Tip_Identificacion: info.Pws_tipoIdentificacion,
                Pws_Perfil_Acceso:"",
                Pws_Fec_Solicitud:"",
            }
            const consulta = await WLOCreditAPIService.consultaSolicitudCygnusDetal(sends)            
            setUsuariosConsulta(consulta.payload.data.datos_persona.Datos_persona)  
            
            var ConsultaDetalles = consulta.payload.data.datos_persona.Datos_persona;
            console.log(ConsultaDetalles);
            let datosUser = {
                iden: ConsultaDetalles[0].Pws_Identificacion,
                tipoIden: ConsultaDetalles[0].Pws_Tip_Identificacion,
                tipoPer: ConsultaDetalles[0].Pws_Tip_Perfil
            }            
            let usuario = datosUser.iden != null || datosUser.iden ? sessionStorage.setItem('cambioAdm', JSON.stringify(datosUser)) : false;            
            const Perfil = ConsultaDetalles[0].Pws_Tip_Perfil;

            if(Perfil === '1')
                ArrayPerson.PerfilUsuario = "Gestor";
            else if(Perfil === '2')
                ArrayPerson.PerfilUsuario = "Autogestión"
            else if(Perfil === '3')
                ArrayPerson.PerfilUsuario = "Administrador"
            else if(Perfil === '4')
                ArrayPerson.PerfilUsuario = "Vendedor"

            ArrayPerson.Email = ConsultaDetalles[0].Pws_Correo;
            ArrayPerson.TipoIdentificacion = ConsultaDetalles[0].Pws_Tip_Identificacion;
            ArrayPerson.Cargo = ConsultaDetalles[0].Pws_Car_emp;
            ArrayPerson.Telefono1 = ConsultaDetalles[0].Pws_Telefono1;
            ArrayPerson.Telefono2 = ConsultaDetalles[0].Pws_Telefono2;
            ArrayPerson.Identificacion = ConsultaDetalles[0].Pws_Identificacion;
            ArrayPerson.Nombre = ConsultaDetalles[0].Pws_Nombres + " " + ConsultaDetalles[0].Pws_Apellidos1 + " " + ConsultaDetalles[0].Pws_Apellidos2; 
            
            setArrayPerson(ArrayPerson) 
            console.log(ArrayPerson);
                      
        };
        
        return (
          <>
              <ul>
                    <li className="listItem firstItem">{info.Pws_Nombre}</li>
                    <li className="listItem">{
                        info.Pws_Tip_Usuario === "1"  ? "Gestor" : 
                        info.Pws_Tip_Usuario === "2"  ? "Autogestión" : 
                        info.Pws_Tip_Usuario === "3"  ? "Administrador" : 
                        info.Pws_Tip_Usuario === "4"  ? "Vendedor" : ""
                    }
                    </li>
                    <li className="listItem"><span style={{background: info.color1}}></span>{info.Pws_identificacion}</li>
                    <li className="listItem" style={{display: 'flex', alignItems: 'flex-start'}}><button style={{color: '#fff', padding: '4px 10px',  width: '100%'}} onClick={() => handleClick()}>Ver detalle</button></li>
              </ul>
          </>
        )
    };

    const [selectedOption, setSelectedOption] = useState('');

    const Select: React.FC <OptionProps> = ({Options,bg,clr,str}) => {         
        const handleSelectChange = (event : any) => {
            setSelectedOption(event.target.value);
        };
        return(
            <li className='filter'>
                <ArrowDown stroke={str} />
                <select id="search" value={selectedOption} onChange={handleSelectChange} style={{background:bg,color:clr}} >
                    {
                        Options.map((option,i)=>{
                            return(
                                <option key={i} value={option.option}>{option.option}</option>
                            )
                        })
                    }
                </select>
            </li>
        )
    }

    const [valor, setValor] = useState('');
    const [usuarios, setUsuarios] = React.useState([] as any);
    const [usuariosFiltro, setUsuariosFiltro] = React.useState([] as any);
    
    const handleSubmit = (event: React.FormEvent) => {
        console.log(document.getElementById('filter'))
        event.preventDefault();
        let enteredText = textInputRef.current!.value;
        console.log(enteredText);
    }

    const handleSubmitBack = () => {
        navigate(AppRoutes.MENU_USER,{replace:true});
    };

    const checkUser= async (valorCheck:string)=>{
      const valor= await AdminService.getUsuarios({
            Pws_Tip_Estado:valorCheck
        })
        console.log(valor);
        
        setUsuarios(valor.payload.data.datos_per.datos_Personas)
        setUsuariosFiltro(valor.payload.data.datos_per.datos_Personas)
    }

    const mewUser = () => {
        navigate(AppRoutes.NEW_USER,{replace:true});
    };

    const admProfiles = () => {
        console.log('si');
        
        navigate(AppRoutes.PROFILEMANAGEMENT,{replace:true});
    };
    
    const handleChange = (event: any) => {        
        setValor(event.target.value);
        const filtro = event.target.value.toUpperCase();
        let filterNumbers = usuarios.filter((num:any) => {

            if(selectedOption === "Empleado" || selectedOption === ""){
                if((num.Pws_Nombre).includes(filtro))
                    return true
                else
                    return false
            }
            else if(selectedOption === "Identificación"){
                if((num.Pws_identificacion).includes(filtro))
                    return true
                else
                    return false
            }
            else if(selectedOption === "Perfil"){
                if((num.Pws_Tip_Usuario).includes(filtro) || (num.Pws_Tip_Usuario.toUpperCase()).includes(filtro))
                    return true
                else
                    return false
            }
            else{
                return true
            }
        });
        setUsuariosFiltro(filterNumbers)
    }
    
    const handleChangeBloqueo = async(event: any) => {
        const isChecked = event.target.checked;
        let userPerfil = sessionStorage.getItem('User');
        const usuarioSeleccionado = JSON.parse(sessionStorage.getItem('cambioAdm')!)
        console.log(isChecked );
        
        if (isChecked) {
            const BloqueoCheck= await AuthService.LoginBloqueo({
                Pws_Identificacion: usuarioSeleccionado.iden,
                Pws_Tip_identificacion: usuarioSeleccionado.tipoIden,
                Pws_Identificacion_aud: JSON.parse(userPerfil!).idUser,
                Pws_Tip_identificacion_aud: JSON.parse(userPerfil!).typeUse,
                Pws_Fecha_Cam: String(moment(new Date()).format('MM/DD/YYYY')),
                Pws_Tip_Accion: "1",
                Pws_Observacion: "Prueba de cambio"
            })
            console.log(BloqueoCheck)
        } else {
            const BloqueoSinCheck= await AuthService.LoginBloqueo({
                Pws_Identificacion: usuarioSeleccionado.iden,
                Pws_Tip_identificacion: usuarioSeleccionado.tipoIden,
                Pws_Identificacion_aud: JSON.parse(userPerfil!).idUser,
                Pws_Tip_identificacion_aud: JSON.parse(userPerfil!).typeUse,
                Pws_Fecha_Cam: String(moment(new Date()).format('MM/DD/YYYY')),
                Pws_Tip_Accion: "2",
                Pws_Observacion: "Prueba de cambio"
            })
            console.log(BloqueoSinCheck)
        }
    }
    const handleChangeCambioContraseña= async (event:any)=>{ 
        navigate(AppRoutes.CHANGE,{replace:true})
    }


  return (
    <div className="UserDoc">
        <Fab variant="extended" color="neutral" aria-label="add"
                         onClick={handleSubmitBack} style={{float:"left"}} className="hooverButtonRed">
                        <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                      ATRAS
        </Fab>
        <div className="UserDocument">
            <h4>Usuarios de la plataforma</h4 >
            <div className="UserTable">
                <div className="first-part">
                    <div className="userAdd">
                        <button onClick={()=>admProfiles()}  style={{background: '#00908E', color: '#fff' , width: '100%'}}>Administración de perfiles <UserIcon/></button>
                        <button onClick={mewUser} style={{background: '#000000', color: '#fff',  width: '100%' }}>Agregar nuevo usuario <UserAddIcon/></button>
                    </div>
                    <div className="table">
                        <Table TableItem={UserItem} headers={userHeaders} data={usuariosFiltro}/>
                    </div>
                </div>
                <div className="second-part">
                    <div className="search">
                        <form action="" method="post" onSubmit={handleSubmit}>
                            <Select Options={Options} bg={`#00908E`} clr={`#fff`} str={`#fff`} />
                            <li>
                                <Search />
                                <input type="text" name="search" placeholder="Buscar" ref={textInputRef} onChange={handleChange}/>
                            </li>
                        </form>
                    </div>
                    <div className="search-result">
                        <ul>
                            <li className="name">{ArrayPerson.Nombre}</li>
                            <li className="action"><button style={{color: '#fff', background: '#00908E' ,  width: '100%'}} onClick={handleChangeCambioContraseña} disabled={usuariosConsulta.length == 0 }>Cambiar Contraseña</button></li>
                        </ul>
                        <div className="search-info">
                            <div>
                                <div>
                                    <p style={{fontSize: '14px'}}><b>Identificación:</b> {ArrayPerson.Identificacion}</p>
                                    <p style={{fontSize: '14px'}}><b>Cargo: </b>{ArrayPerson.Cargo}</p>
                                    <p style={{fontSize: '14px'}}><b>Email: </b> {ArrayPerson.Email}</p>
                                    <p style={{fontSize: '14px'}}><b>Teléfono1: </b> {ArrayPerson.Telefono1}</p>
                                    <p style={{fontSize: '14px'}}><b>Perfil de usuario: </b> {ArrayPerson.PerfilUsuario}</p>
                                    <p style={{fontSize: '14px'}}><b>Bloquear Usuario: </b> <input type="checkbox" className="mycheck2" id="mycheck" onChange={handleChangeBloqueo}/></p>
                                </div>    
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
        </div>
    </div>
  )
}

export default UserDocument;