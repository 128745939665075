import {ErrorMessage, Field} from "formik";
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useRequestContext} from "../../../hooks/useRequestContext";
import {cities, civilStatus, departments, estractos, nivelEstudios, typeVivienda} from "../../../__mocks__/data";
import {FormSpouseMobile} from "./ModalSpouse/FormSpouseMobile";
import './applicant-info.scss';
import {renderTypeIdentificationDescription} from "../../../utils/utils";
import _ from "lodash";
import { AdminService } from "../../../services";

interface FormPersonalInfoMobileProps {
    errors?: any;
    values?: any;
    setPopup: React.Dispatch<React.SetStateAction<string>>;
}

export function FormPersonalInfoMobile(props: FormPersonalInfoMobileProps) {
    const {request} = useRequestContext();
    const {errors, values,setPopup} = props;
    const intl = useIntl();
    const [activelugar, setactive] = useState(false);
    const [modal, setModal] = useState(false);
    
    const [EstadosCivil, setEstadosCivil] = useState([]);
    const [tipovivi, setTipoVivienda] = useState([]);
    const [fechaexp, setactive2] = useState(false);
    const [NivelEsti, setNivelEstudio] = useState([]);
    const [ciudades, setCiudades] = useState(null) as any;
    const [ciudades2, setCiudades2] = useState(cities as any);
    const [departamentos, setDepartamentos] = useState(cities as any);
    const [allCities, setAllCiudades] = useState(cities as any);
    const [one] = useState(null);
    useEffect(()=>{
        allData()
        AdminService.getAllCiudades().then((Datos:any)=>{
            setAllCiudades(Datos.payload.data)
            
        if(!request.searchANI){
            if( request.personCygnus?.Pws_Lugar_exp){
                values.Pws_Lugar_exp=request.personCygnus?.Pws_Lugar_exp
                setactive(true)
            }else{
                setactive(true)

            }
        }else{
            if(request.personANI?.municipioExpedicion){
                setactive(true)
              values.Pws_Lugar_exp= _.filter( Datos.payload.data.ListCiudad.Datos_Ciudad, { s_nivel: '3',s_nombre: request.personANI?.municipioExpedicion}).length>0?_.filter( Datos.payload.data.ListCiudad.Datos_Ciudad, {s_nivel: '3', s_nombre: request.personANI?.municipioExpedicion})[0].s_codigo:""
           
             }else{
                setactive(true)

            }
        }
        if(!request.searchANI){
            if( request.personCygnus?.Pws_Fec_expe){
                setactive2(true)
            }else{
                
                setactive2(true)

            }
        }else{
            if(request.personANI?.fechaExpedicion){
                setactive2(true)
            }else{
                setactive2(true)

            }
        }
        })
    },[one])
    useEffect(()=>{
       if(allCities){
        
           
        let citiesDepent = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        
        
        let citiesDepent2 = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '2'});
        setDepartamentos(citiesDepent)
        citiesDepent2.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3'});
        setCiudades2(citiesDepent2);
       }
    },[allCities])
    const allData= async ()=>{
        const estadosCvil= await AdminService.getEstadosCiviles();
        if(estadosCvil.payload.data.listEstCivil.Datos_EstCivil.length>0){
            setEstadosCivil(estadosCvil.payload.data.listEstCivil.Datos_EstCivil)
        }
        const tipovivienda= await AdminService.gettipovivienda();
        if(tipovivienda.payload.data.tip_viv.datos_tip_vivi.length>0){
            setTipoVivienda(tipovivienda.payload.data.tip_viv.datos_tip_vivi)
        }
        const nivelEstudi= await AdminService.getNivelEstudio();
        if(nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio.length>0){
            setNivelEstudio(nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio)
        }
    }
    const toggle = () => setModal(!modal);


    const handleCallModalConyugue = (e: { target: { value: string; }; }) => {
        if (e.target.value === "1") {
            setModal(!modal);
        }
    }

    
    const handleCities = (e: { target: { value: string; }; }) => {
        let citiesDepent = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3', s_ubicacion: e.target.value });
        setCiudades(citiesDepent);
    }

    return (
        <div className="row container-border-app-mobile">
            
            <div className="row">
            <div className="row" >
                <div className="text-left">
                    
                <div className="col" style={{"textAlign":"left"}}>
                     <h3 >Información del solicitante</h3>

                </div>
                </div>
            </div>
                <div className="row">
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_field_names"})}</div>
                        <div className={"label-fields-peps-mobile-ligth display-flex"}>
                            {!request.searchANI ? request.personCygnus?.Pws_Nombres : request.personANI?.primerNombre + " " + request.personANI?.segundoNombre}
                        </div>
                    </div>
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_field_last_names"})}</div>
                        <div
                            className={"label-fields-peps-mobile-ligth display-flex"}>
                            {!request.searchANI ? request.personCygnus?.Pws_Apellidos1 + ' ' + request.personCygnus?.Pws_Apellidos2 : request.personANI?.primerApellido + " " + request.personANI?.segundoApellido}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_identification_type"})}</div>
                        <div
                            className={"label-fields-peps-mobile-ligth display-flex"}>
                            {!request.searchANI ? renderTypeIdentificationDescription(request.personCygnus?.Pws_Tip_Identificacion!) : renderTypeIdentificationDescription(values.Pws_Tip_Identificacion!)}
                        </div>
                    </div>
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_identification"})}</div>
                        <div
                            className={"label-fields-peps-mobile-ligth display-flex"}>
                            {!request.searchANI ? request.personCygnus?.Pws_Identificacion : request.personANI?.nuip}
                        </div>
                    </div>
                </div>
                <div className="row">
              
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_expedition_date"})}</div>
                        <div className={"label-fields-peps-mobile-ligth display-flex"}>
                        {fechaexp?<>
                        <Field id={"Pws_Fec_expe"}
                                   name={"Pws_Fec_expe"}
                                   type={"date"}
                                   className={`${errors.Pws_Fec_expe ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                                   placeholder={'MM/dd/YYYY'}/>
                           
                        </>:<>
                        {!request.searchANI ? request.personCygnus?.Pws_Fec_expe.split("-00.")[0] : request.personANI?.fechaExpedicion.split("-00.")[0]}
           
                        </>}
                      </div>
                    </div>
                    <div className="col">
                    <div className={"label-fields-peps-mobile-ligth display-flex"}>
                        {activelugar?<>
                            <Field as="select"
                               className={`${errors.Pws_Lugar_exp ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Lugar_exp"
                        >
                            <option>Lugar Expedicion</option>
                            {ciudades2 && ciudades2.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>
                        </>:<>
                        {_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3',s_codigo:(!request.searchANI ? request.personCygnus?.Pws_Lugar_exp: values.Pws_Lugar_exp)}).length>0?_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3',s_codigo:(!request.searchANI ? request.personCygnus?.Pws_Lugar_exp: values.Pws_Lugar_exp)})[0].s_nombre:""}
           
                        </>}
                 </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_date_birth"})}</div>
                        <div className={"label-fields-peps-mobile-ligth display-flex"}>
                                <div className="display-flex">
                                    <Field id={"Pws_Fec_nacime"}
                                           name={"Pws_Fec_nacime"}
                                           type="date"
                                           className={`${errors.Pws_Fec_nacime ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                                           placeholder={intl.formatMessage({id: "placeholder_date_birth"})}/>
                                           <div className="label-fields-peps-warning">
                            <ErrorMessage name="Pws_Fec_nacime"/>
                        </div>
                                </div>
                           </div>
                    </div>
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_marital_status"})}</div>
                        <Field as="select" onBlur={handleCallModalConyugue}
                               className={`${errors.Pws_Estado_Civil ? 'form-know-you-item-input-xs display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                               name="Pws_Estado_Civil"
                        >
                            <option>{intl.formatMessage({id: "placeholder_marital_status"})}</option>
                            {EstadosCivil && EstadosCivil.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_descripcion}</option>
                                )
                            })}
                        </Field>
                        <div className="label-fields-peps-warning">
                            <ErrorMessage name="Pws_Estado_Civil"/>
                            <ErrorMessage name="Pws_Con_Tip_identif"/>
                            <ErrorMessage name="Pws_Con_identif"/>
                            <ErrorMessage name="Pws_Con_nomsol"/>
                            <ErrorMessage name="Pws_Con_tel"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="row">
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "residence_address"})}</div>
                        <Field id={"Pws_Direccion_res"}
                         onFocus={()=>{
                            setPopup("open")
                        }}
                        readonly='readonly'
                               name={"Pws_Direccion_res"}
                               className={`${errors.Pws_Direccion_res ? 'form-know-you-item-input-mobile display-flex input-error' : 'form-know-you-item-input-mobile display-flex'}`}
                               placeholder={intl.formatMessage({id: "enter"})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "department"})}</div>

                        <Field as="select"
                                className={`${errors.Pws_Departamento ? 'form-know-you-item-input-mobile display-flex input-error' : 'form-know-you-item-input-mobile display-flex'}`}
                               name="Pws_Departamento"
                               onClick={handleCities}
                        >
                            <option>{intl.formatMessage({id: "form_financial_city_foreign_currency"})}</option>
                            {departamentos && departamentos.ListCiudad.Datos_Ciudad.map((item:any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "form_financial_city_foreign_currency"})}</div>

                        <Field as="select"
                               className={`${errors.Pws_Ciudad_res ? 'form-know-you-item-input-mobile display-flex input-error' : 'form-know-you-item-input-mobile display-flex'}`}
                               name="Pws_Ciudad_res"
                        >
                            <option>{intl.formatMessage({id: "form_financial_city_foreign_currency"})}</option>
                            {ciudades && ciudades.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                return (
                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                )
                            })}
                        </Field>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_mobile_phone_1"})}</div>
                        <div className={"label-fields-peps-mobile-ligth display-flex"}>{values.Pws_Telefono1}</div>
                    </div>
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_mobile_phone_2"})}</div>
                        <div className={"label-fields-peps-mobile-ligth display-flex"}>{values.Pws_Telefono2}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_mail"})}</div>
                        <div className={"label-fields-peps-mobile-ligth display-flex"}>{values.Pws_Correo}</div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="row">
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "gender"})}</div>
                        <div className="display-flex">
                            <Field as="select"
                                   className={`${errors.Pws_Genero ? 'form-know-you-item-input-mobile display-flex input-error' : 'form-know-you-item-input-mobile display-flex'}`}
                                   name="Pws_Genero">
                                <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                                <option value={'F'}>{'Femenino'}</option>
                                <option value={'M'}>{'Masculino'}</option>
                            </Field>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_social_stratum"})}</div>
                        <div className="display-flex">
                            <Field as="select"
                                   className={`${errors.Pws_Estrato_per ? 'form-know-you-item-input-mobile display-flex input-error' : 'form-know-you-item-input-mobile display-flex'}`}
                                   name="Pws_Estrato_per">
                                <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                                {estractos && estractos.WS_ESTRACTOS_SOCResult.estratos_soc.Datos_estratos_soc.map((item, key: number) => {
                                    return (
                                        <option key={key} value={item.i_codigo}>{item.c_descripcion}</option>
                                    )
                                })}
                            </Field>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_number_of_people_in_charge"})}</div>
                        <div className="display-flex">
                            <Field as="select"
                                   className={`${errors.Pws_Per_cargo ? 'form-know-you-item-input-mobile display-flex input-error' : 'form-know-you-item-input-mobile display-flex'}`}
                                   name="Pws_Per_cargo">
                                <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                                <option value={'0'}>{'0'}</option>
                                <option value={'1'}>{'1'}</option>
                                <option value={'2'}>{'2'}</option>
                                <option value={'3'}>{'3'}</option>
                                <option value={'4'}>{'4'}</option>
                                <option value={'5'}>{'5'}</option>
                                <option value={'6'}>{'6'}</option>
                                <option value={'7'}>{'6'}</option>
                                <option value={'8'}>{'6'}</option>
                                <option value={'9'}>{'6'}</option>
                                <option value={'10'}>{'10'}</option>
                            </Field>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_type_of_housing"})}</div>
                        <div className="display-flex">
                            <Field as="select"
                                    className={`${errors.Pws_Tip_vivienda ? 'form-know-you-item-input-mobile display-flex input-error' : 'form-know-you-item-input-mobile display-flex'}`}
                                   name="Pws_Tip_vivienda">
                                <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                                {tipovivi && tipovivi.map((item:any, key: number) => {
                                    return (
                                        <option key={key} value={item.i_codigo}>{item.c_descripcion}</option>
                                    )
                                })}
                            </Field>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className={"label-fields-peps-mobile display-flex"}>{intl.formatMessage({id: "placeholder_educational_level"})}</div>
                        <div className="display-flex">
                            <Field as="select"
                                  className={`${errors.Pws_Niv_estudio ? 'form-know-you-item-input-mobile display-flex input-error' : 'form-know-you-item-input-mobile display-flex'}`}
                                   name="Pws_Niv_estudio">
                                <option>{intl.formatMessage({id: "placeholder_dropdown"})}</option>
                                {NivelEsti && NivelEsti.map((item:any, key: number) => {
                                    return (
                                        <option key={key} value={item.s_codigo}>{item.s_descripcion}</option>
                                    )
                                })}
                            </Field>
                        </div>

                    </div>
                </div>
                <FormSpouseMobile values={values} errors={errors} show={modal} onHide={toggle}/>
            </div>
        </div>
    )
}