import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import './MenuBarGeneral.scss';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../Router';
import { useLoginContext } from '../../../hooks/useLoginContext';
import Cookies from 'js-cookie';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import toast from 'react-hot-toast';

const pages = ['Cambio Contraseña', 'Gestión de Crédito', 'Solicitud de Crédito'];
const settings = ['ES', 'EN'];

const   ResponsiveAppBar = () => {
  const languageApp = Cookies.get('language') || 'es';
  const {user, setUser} = useLoginContext();
  const navigate = useNavigate();
  const [language, setLanguage] = React.useState(languageApp.toUpperCase());
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseSession = () => {
    sessionStorage.removeItem('User');
    setUser({isSuccessfulLogin: false });
    navigate(AppRoutes.PROFILE_OPTIONS, {replace: true});
  };

  const handleGoToHome = () => {
    if(Number(user.idProfile)===2){
      navigate(AppRoutes.CATALOG, {replace: true});
    }else{
      toast("Bienvenido ¿Cómo te podemos ayudar?",{duration:3000})
      navigate(AppRoutes.MENU_USER, {replace: true});

    }
  };


  return (
    <AppBar position="static" className='prueba' >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
           <div className='img-logo-sarmiento'/>
           {/* <div className='v-line'/> */}
           {/* <div className='img-logo-american'/>
           <div className='v-line'/>
           <div className='img-logo-afianzando'/>
           <div className='v-line'/>
           <div className='img-logo-credimoto'/> */}
           <div className='img-logo-home'/>
           <div className='v-line'/>
           
          </Typography>
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
            }}
          >
             <Link
      component="button"
      variant="body2"
      onClick={handleGoToHome}
    >
     <div className='menu-init'>
            {"Inicio"}
           </div>

    </Link>
          
          </Typography>
          
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <div className='img-logo-sarmiento'/>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            
          </Box>

          <Box sx={{ flexGrow: 1, p: 2, textAlign: "right" }}>
          <Tooltip title="Open languages">
              <div className='menu-language' onClick={handleOpenUserMenu}>
                {language}<ArrowDropDownIcon/>
              </div>

            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => {
                  Cookies.set('language', setting.toLowerCase());
                  setLanguage(setting);
                }}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 0, p : 2, textAlign: "right" }}>
          <div className='name-user'>
            {user.idUser}
          </div>
            <Link
      component="button"
      variant="body2"
      onClick={handleCloseSession}
    >
      <div className='btn-close-session'>
        {"Cerrar sesión"}
      </div>

    </Link>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Avatar alt="L" src="/static/images/avatar/2.jpg" />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
