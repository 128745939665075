import React from 'react';
import { Table } from '../../../components/shared/Table/Table';
import './ConfirmPolicies.scss';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../Router';

const headers = [
    { header: 'N° cuota' },
    { header: 'Fecha de pago' },
    { header: 'Cuota' },
    { header: 'Intereses' },
    { header: 'IVA intereses' },
    { header: 'Abono capital' },
    { header: 'Saldo' },
];
type Cuota = {
    n_num_cuo: string;
    f_fecha: string;
    n_sal_ini: string;
    n_capital: string;
    n_int_1: string;
    n_int_2: string;
    n_int_3: string;
    n_int_4: string;
    n_int_5: string;
    n_int_6: string;
    n_int_7: string;
    n_int_8: string;
    n_int_9: string;
    n_int_10: string;
    n_cap_tf: string;
    n_int_tf: string;
    n_total: string;
    n_sal_fin: string;
    n_intnom_1: string;
    n_intnom_2: string;
    n_intnom_3: string;
    n_intnom_4: string;
    n_intnom_5: string;
    n_intnom_6: string;
    n_intnom_7: string;
    n_intnom_8: string;
    n_intnom_9: string;
    n_intnom_10: string;
};
type ConfirmPoliciesProps = {
    cuotas: Cuota[];
    valorCuota: string;
};

export const ModalConfirmPolicies: React.FC<ConfirmPoliciesProps> = ({cuotas, valorCuota}) => {
    const navigate = useNavigate();


    const finalizar=()=>{
        navigate(AppRoutes.THANKYOU, {replace: true});
    }

    const aceptar=()=>{
        navigate(AppRoutes.HOME, {replace: true});
    }

    function formatCurrency(value: string): string {
        // Convierte el string a número y luego formatea
        const number = parseFloat(value);
        if (isNaN(number)) {
          return "0.00"; // o cualquier valor por defecto que prefieras
        }
        return number.toLocaleString('es-CO', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
    const datosP = [
        {
            fechaPago: new Date(2024, 3, 29), // Fecha de pago en formato Date
            numeroPago: '1',
            intereses: 108938,
            capitalPagado: 0,
            estado: 'Pendiente',
            saldoPendiente: 291062,
            descuentoEmpresa: 0,
            ivaDescuento: 108938,
            seguroAval: 291062
        },
        {
            fechaPago: new Date(2024, 7, 29), // Fecha de pago en formato Date
            numeroPago: '2',
            intereses: 108938,
            capitalPagado: 0,
            estado: 'Pendiente',
            saldoPendiente: 400000,
            descuentoEmpresa: 0,
            ivaDescuento: 108938,
            seguroAval: 400000
        }
    ];

  
    const data = cuotas?.map((pago, index) => ({
        item1: pago.n_num_cuo,
        item2: pago.f_fecha,
        item3: formatCurrency(valorCuota),
        item4: formatCurrency(pago.n_int_1), // IVA intereses (asumiendo que es igual a intereses en este caso)
        item5: formatCurrency(pago.n_int_2),
        item6: formatCurrency(pago.n_capital),
        item7: formatCurrency(pago.n_sal_fin)
    }))

    return (
        <div className="pagos">
            <h3 className="redhead">De acuerdo a los datos introducidos las cuotas quedarían de la siguiente manera:</h3>
            <div className="table">
                {/* <Table TableItem={Item} headers={headers} data={data} /> */}
                <table>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.item1}</td>
            <td>{item.item2}</td>
            <td>{item.item3}</td>
            <td>{item.item4}</td>
            <td>{item.item5}</td>
            <td>{item.item6}</td>
            <td>{item.item7}</td>
          </tr>
        ))}
      </tbody>
    </table>

            </div>
            <div className="submit">
                <button type='button' className='botones' onClick={aceptar}>Continuar con el proceso</button>
            </div>
            <div className="submit">
                <button type='button' className='botones' onClick={finalizar}>Finalizar con el proceso</button>
            </div>
        </div>
    );
};


interface ItemProps {
    item1: string;
    item2: string;
    item3: string;
    item4: string;
    item5: string;
    item6: string;
    item7: string;
}

const Item: React.FC<ItemProps> = ({ item1, item2, item3, item4, item5, item6, item7 }) => {
    return (
        <ul>
            <li className="listItem firstItem">{item1}</li>
            <li className="listItem">{item2}</li>
            <li className="listItem">{item3}</li>
            <li className="listItem">{item4}</li>
            <li className="listItem">{item5}</li>
            <li className="listItem">{item6}</li>
            <li className="listItem">{item7}</li>

        </ul>
    );
};
