import {Form, Field, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useNavigate,useLocation} from 'react-router-dom';
import { Row} from 'reactstrap';
import {AppRoutes} from '../../Router';
import * as Yup from "yup";
import {Fab} from '@mui/material';
import {typeIdentification} from '../../__mocks__/data';
import {MenuBar} from '../../components/shared/Menubar/Menubar';
import './LoginOTP.scss';
import { useIntl } from 'react-intl';
import { useSignContext } from '../../hooks/useSignContext';
import { AuthService } from '../../services/AuthService';
import toast from 'react-hot-toast';
import ClockLoader from 'react-spinners/ClockLoader';
import { WLOCreditAPIService } from '../../services/WLOCreditService';
import { useRequestContext } from '../../hooks/useRequestContext';
import {useLoginContext} from '../../hooks/useLoginContext';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import {decodeToken} from 'react-jwt';
import Moment from 'moment';

export function LoginOTP() {
    let [isLoading, setIsLoading] = useState(false);
    const [numeroSolicitud, setNumeroSolicitud] = useState(0);
    const [valoreslogins, setvalores] = useState({}as any);
    const {user, setUser} = useLoginContext();
    const intl = useIntl();
    const {request, setRequest} = useRequestContext();
    const {sign, setSign} = useSignContext();
    const navigate = useNavigate();
    const buscar = useLocation();
    const [token, setToken] = useState<string>('')
    const [identidad, setIdentidad] = useState<number>(0)
    const [tipIden, setTipIden] = useState<number>(0)
    const [showModal, setShowModal] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const handleSubmit = async (values: any) => {        
        setIsLoading(true);
        values.Pws_Identificacion = identidad;
        values.Pws_Tip_Identificacion = tipIden
        console.log(identidad);
        console.log(tipIden);
        
        
        try {
            const validation = await AuthService.loginValidacion({
                Pws_Identificacion: values.Pws_Identificacion,
                Pws_Tip_identificacion: values.Pws_Tip_Identificacion,
                Pws_Correo:values.Pws_Correo,
                Pws_Telefono:values.Pws_Telefono
            });

            if (Number(validation.payload.result) > 0) {
                if(numeroSolicitud>0){
                    const solicitudResulado = await WLOCreditAPIService.addSolicitud({
                        Pws_num_solicitud:String(numeroSolicitud),
                        Pws_Identificacion: values.Pws_Identificacion,
                        Pws_Tip_Identificacion: values.Pws_Tip_Identificacion

                    });
                    sessionStorage.setItem('num_solicitud', solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_num_solicitud);
                    sessionStorage.setItem('correo',values.Pws_Correo );
                    sessionStorage.setItem('tel',values.Pws_Telefono );

                    if(Number(solicitudResulado.payload.result)>0){
                        if(solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado != 5){
                          
                                if(Number(valoreslogins.tipodeudor)<2){
                                    solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado="2"
                                    const solicitudResulados = await WLOCreditAPIService.addSolicitud(solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0])

                                }
                                 setRequest({
                                ...request,
                                numSol: String(numeroSolicitud),
                                personCygnus:solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0],
                                valoresLogin:valoreslogins
                            });
                            setUser({
                                ...user,
                                idUser: values.Pws_Identificacion,
                                lastLoginDate: new Date().toDateString(),
                                isSuccessfulLogin: true
                            });
                                setSign({...sign, numIdentification: values.Pws_Identificacion,
                                typeIdentification: values.Pws_Tip_Identificacion,
                                email: values.Pws_Correo,
                                phoneNumber: values.Pws_Telefono,
                                identityValidated: true
                            })
                            setIsLoading(false);
                            const dataDefault = {
                                Pws_Num_solicitud: solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_num_solicitud, 
                                Pws_Identificacion: values.Pws_Identificacion,
                                Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
                                Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
                                Pws_estado_resotp: "1",
                                Pws_Tip_val: "1",
                                email: values.Pws_Correo,
                                Pws_phone: values.Pws_Telefono,
                            };
                            (async () => {
                                const regOTP = await WLOCreditAPIService.sendOTP(dataDefault);
                                const {payload} = regOTP;
                                const {token: tokenValue} = payload;
                                setToken(tokenValue);
                                sessionStorage.setItem('token', regOTP.payload.token);
                                if(regOTP.payload.token){
                                    navigate(AppRoutes.LOGINPAGARE, {replace: true});
                                }else{
                                    toast.error('Se ha presentado un error intente nuevamente');
                                }
                            })();
                        }else{ 
                            setIsLoading(false);
                            toast.error('La Solicitud Fue Negada');

                        }
                    }else{
                        setIsLoading(false);
                        toast.error('No es el Numero de Solicitud de la persona');

                    }
                }else{
                    setIsLoading(false);
                    toast.error('No Cuentas con el Token');

                }
                    
                
            } else {
                setSign({...sign, numIdentification: values.Pws_Identificacion,
                    typeIdentification: values.Pws_Tip_Identificacion,
                    email: values.Pws_Correo,
                    phoneNumber: values.Pws_Telefono,
                    identityValidated: false
                })
                setIsLoading(false);
                toast.error(intl.formatMessage({id: "toast_failed_identity_validation"}));
            }
        } catch (error) {
            setIsLoading(false);
            toast.error('En este momento no podemos procesar su solicitud');
        }
    };

    useEffect(()=>{
        if(buscar){

          const valorde:any = JSON.parse(sessionStorage.getItem('solicitud')!);          
          const identidad:any = sessionStorage.getItem('identificacion');
          const tipIden:any = sessionStorage.getItem('tipoidentificacion');
          setIdentidad(identidad);
          setTipIden(tipIden)          
          if(valorde){
            if(Number(valorde.solicitudnum)>0){
                setNumeroSolicitud(Number(valorde.solicitudnum))
                setvalores(valorde)
              }else{
                toast.error("No tienes La Solicitud")
                          }
          }else{
            toast.error("No tienes La Solicitud")
          }
       
        }
        },[buscar,navigate]);
    useEffect(() => {
        console.log(sign)
        document.body.classList.add('body-login');
        return () => {
            document.body.classList.remove('body-login');
        };
    }, []);
    const initialValues = {
        Pws_Correo: '',
        Pws_Telefono: ''
    };

    const validationSchema = Yup.object().shape({
        Pws_Correo: Yup.string()
            .required('( * )'),
        Pws_Telefono: Yup.string()
            .required('( * )'),
    })

    return (
        <div className="loginfirma17">
            <MenuBar/>
            <div className='loginfirm'>
                <div className='img-logo'/>
                <div className='title-login'>{'Antes que todo validemos tu identidad'}</div>
                <div className='login-container'>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {() => (
                            <>
                                <Form>
                                    <Row>
                                        <section>
                                        <h4 className="title-firma">{'Insertar  una dirección  de correo electrónico y su número celular para  enviar el código de validación'}</h4>
                                        <br/>
                                        <Field
                                                className='form-control-50-credit'
                                                id="Pws_Correo"
                                                name="Pws_Correo"
                                                type="email"
                                                disabled={isLoading}
                                                placeholder='Correo electrónico'
                                            />
                                        </section>
                                        <section>
                                            
                                            <br/>
                                            <Field
                                                className='form-control-50-credit'
                                                id="Pws_Telefono"
                                                name="Pws_Telefono"
                                                type="text"
                                                disabled={isLoading}
                                                placeholder='Celular'
                                            />
                                            <br/>
                                        </section>
                                        <section>
                                            <Fab className="form-control-small-button" variant="extended" type='submit'
                                                size="medium"
                                                color="neutral" aria-label="add">
                                                {'Ingresar'}
                                            </Fab>
                                        </section>
                                    </Row>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}