import React from 'react';
import './ThankYouView.scss';

export const ThankYouView = () => {
  const handleRedirect = () => {
    // Lógica para redirigir a la página inicial
    window.location.href = 'https://finmeridian.com.co';
  };

  return (
    <div className="thank-you-view">
      <div className="content">
        <h1>MUCHAS GRACIAS POR UTILIZAR NUESTROS SERVICIOS</h1>
        <p>Será redireccionado a nuestra página inicial</p>
        <button onClick={handleRedirect}>Ir a inicio</button>
      </div>
    </div>
  );
};
