import React, {useEffect, useRef, useState} from 'react';
import './cd.scss';
import {ArrowBack} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import {Search, ArrowDown, Delete} from '../../../assets/icons/component';
import {TableF} from '../Table/Table';
import {useLocation, useNavigate} from 'react-router-dom';
import {AppRoutes} from "../../../Router";
import { Popup } from '../Popup';
import { EditInfo } from './popup/EditInfo';
import { AddEditInfo } from './popup/AddEditItem';
import { Attribute } from './popup/Attribute';

const CategoryHeader = [
    {
        header: 'Nombre del producto'
    },
    {
        header: 'Valor comercial '
    },
    {
        header: 'Stock'
    },
    {
        header: 'Foto'
    },
    {
        header: 'Acciones'
    }
]

const CategiryData = [
    {
        item1: '200 DUKE',
        item2: '$ 12’790.000',
        item3: '20',
        item4: 'Si',
    },
    {
        item1: 'Jane Cooper',
        item2: '$ $ 3’500.000',
        item3: '10',
        item4: 'No',
    },
    {
        item1: 'Stella Williamson',
        item2: '$ $ 3’500.000',
        item3: '30',
        item4: 'Si',
    },
    {
        item1: '200 DUKE',
        item2: '$ 12’790.000',
        item3: '20',
        item4: 'Si',
    },
    {
        item1: 'Jane Cooper',
        item2: '$ $ 3’500.000',
        item3: '10',
        item4: 'No',
    },
    {
        item1: 'Stella Williamson',
        item2: '$ $ 3’500.000',
        item3: '30',
        item4: 'Si',
    },
    {
        item1: '200 DUKE',
        item2: '$ 12’790.000',
        item3: '20',
        item4: 'Si',
    },
    {
        item1: 'Jane Cooper',
        item2: '$ $ 3’500.000',
        item3: '10',
        item4: 'No',
    },
    {
        item1: 'Stella Williamson',
        item2: '$ $ 3’500.000',
        item3: '30',
        item4: 'Si',
    },
    {
        item1: '200 DUKE',
        item2: '$ 12’790.000',
        item3: '20',
        item4: 'Si',
    },
    {
        item1: 'Jane Cooper',
        item2: '$ $ 3’500.000',
        item3: '10',
        item4: 'No',
    },
    {
        item1: 'Stella Williamson',
        item2: '$ $ 3’500.000',
        item3: '30',
        item4: 'Si',
    },
    {
        item1: '200 DUKE',
        item2: '$ 12’790.000',
        item3: '20',
        item4: 'Si',
    },
    {
        item1: 'Jane Cooper',
        item2: '$ $ 3’500.000',
        item3: '10',
        item4: 'No',
    },
    {
        item1: 'Stella Williamson',
        item2: '$ $ 3’500.000',
        item3: '30',
        item4: 'Si',
    },
    {
        item1: '200 DUKE',
        item2: '$ 12’790.000',
        item3: '20',
        item4: 'Si',
    },
    {
        item1: 'Jane Cooper',
        item2: '$ $ 3’500.000',
        item3: '10',
        item4: 'No',
    },
    {
        item1: 'Stella Williamson',
        item2: '$ $ 3’500.000',
        item3: '30',
        item4: 'Si',
    },
]

interface InfoProps {
    info: {
        item1: string,
        item2: string,
        item3: string,
        item4: string,
    };
    setFunction: React.Dispatch<React.SetStateAction<boolean>>;
}


export const CategoryItem: React.FC <InfoProps> = ({info, setFunction}) => {
    return (
      <>
          <ul>
              <li className="listItem firstItem">{info.item1}</li>
              <li className="listItem">{info.item2}</li>
              <li className="listItem">{info.item3}</li>
              <li className="listItem">{info.item4}</li>
              <li className="listItem" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}><button onClick={()=>setFunction(true)} style={{color: '#fff', padding: '4px 10px'}}>Editar</button><Delete /></li>
          </ul>
      </>
    )
}

// const Options = [
//     {
//         option: 'Filtrar resultados'
//     },
//     {
//         option: 'Filter2'
//     },
//     {
//         option: 'Filter3'
//     },
//     {
//         option: 'Filter4'
//     }
// ]

interface OptionProps {
    Options: {
        option: string
    }[];
    bg: string;
    clr: string;
    str: string;
}

export const Select: React.FC <OptionProps> = ({Options,bg,clr,str}) => {
    return(
        <li className='filter'>
            <ArrowDown stroke={str} />
            <select name="filter" id="search" style={{background:bg,color:clr}}>
                {
                    Options.map((option,i)=>{
                        return(
                            <option key={i} value={option.option}>{option.option}</option>
                        )
                    })
                }
            </select>
        </li>
    )
}

export const Select2: React.FC <OptionProps> = ({Options,bg,clr,str}) => {
    return(
        <>
            <ArrowDown stroke={str} />
            <select name="search" id="search" style={{background:bg,color:clr}}>
                {
                    Options.map((option,i)=>{
                        return(
                            <option key={i} value={option.option}>{option.option}</option>
                        )
                    })
                }
            </select>
        </>
    )
}

export const CategoryDetail: React.FC = () => {
    const navigate = useNavigate();
    const {state} = useLocation();
    useEffect(()=>{
        console.log(state)
    },[state])
    const textInputRef = useRef<HTMLInputElement>(null);
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        let enteredText = textInputRef.current!.value;
        console.log(enteredText);
    }
    const handleSubmitBack = () => {
        navigate(AppRoutes.MENU_USER,{replace:true});
    };
    const [editInfo, setEditInfo] = useState(false);
    const [addeditInfo, setAddEditInfo] = useState(false);
    const [attribute, setAttribute] = useState(false);
  return (
    <>
        {
            editInfo
            ?<>
                <Popup setfunc={setEditInfo} Body={<EditInfo />} />
            </>
            :<></>
        }
        {
            addeditInfo
            ?<>
                <Popup setfunc={setAddEditInfo} Body={<AddEditInfo />} />
            </>
            :<></>
        }
        {
            attribute
            ?<>
                <Popup setfunc={setAttribute} Body={<Attribute />} />
            </>
            :<></>
        }
        <div className="UserDoc">
            <Fab variant="extended" color="neutral" aria-label="add"
                            onClick={handleSubmitBack} style={{float:"left"}} className="hooverButtonRed">
                            <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                        ATRAS
                        </Fab>
            <div className="UserDocument">
                <h4>Detalle de la categoría</h4>
                <div className="UserTable">
                    <div className="first-part">
                        <div className="cat-head">
                            <div className="foto">
                                <img src="img/modelo-1.png" alt="moto" />
                                <p>foto</p>
                            </div>
                            <div className="desc">
                                <h5>Nombre de la categoría</h5>
                                <h5>Número de productos</h5>
                                <h6 onClick={()=>setEditInfo(true)}>Editar información categoría</h6>
                            </div>
                        </div>
                        <div className="userAdd">
                            <button onClick={()=>setAddEditInfo(true)} style={{background: '#00908E', color: '#fff'}}>Agregar nuevo producto </button>
                            <button onClick={()=>setAttribute(true)} style={{background: '#00908E', color: '#fff'}}>Parametrización atributos </button>
                        </div>

                        <div className="table">
                            <TableF TableItem={CategoryItem} headers={CategoryHeader} data={CategiryData} setFunction={setAddEditInfo}/>
                        </div>
                    </div>
                    <div className="second-part">
                        <div className="search">
                            <form action="" method="post" onSubmit={handleSubmit}>
                                <button style={{background: '#00908E', color: '#fff', padding: '5px 50px', borderRadius: '20px'}}>Filtrar resultados</button>
                                <li>
                                    <Search />
                                    <input type="text" name="seacrh" placeholder="Search" ref={textInputRef}/>
                                </li>
                            </form>
                        </div>
                        <div className="image">
                            <img src="img/corporative.png" alt="corporativa"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
