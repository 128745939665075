import React, {useEffect, useRef, useState} from 'react';
import './ProfileManagement.scss';
import {ArrowBack} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import {Search, ArrowDown, UserIcon, UserAddIcon} from '../../../src/assets/icons/component';
import {Table} from '../../components/shared/Table/Table';
import {useNavigate} from 'react-router-dom';
import {AppRoutes} from "../../../src/Router";
import { PerfilFunc } from '../../components/shared/PerfilFunc';
import { AdminService, WLOCreditAPIService } from '../../services';
import { WloCreditoService } from '../../types';

const userHeaders = [
    {
        header: 'Codigo'
    },
    {
        header: 'Tipo de perfil'
    },
    {
        header: 'Descripción'
    },
    {
        header: 'Acciones'
    }
]

interface InfoProps2 {
    info: {
        Pws_Nombre: string,
        Pws_tipoIdentificacion: string,
        Pws_Tip_Usuario: string,
        Pws_identificacion: string,
        Pws_Codigo_Persona: string,
        Pws_Codigo_Area: string,
    };
}


// const Options = [
//     {
//         option: 'Código'
//     },
//     {
//         option: 'Tipo de perfil'
//     },
//     {
//         option: 'Descripción'
//     },
//     {
//         option: 'Tipo de Perfil'
//     }
// ]

// interface OptionProps {
//     Options: {
//         option: string
//     }[];
//     bg: string;
//     clr: string;
//     str: string;
// }

// export const Select: React.FC <OptionProps> = ({Options,bg,clr,str}) => {
//     return(
//         <li className='filter'>
//             <ArrowDown stroke={str} />
//             <select name="filter" id="search" style={{background:bg,color:clr}}>
//                 {
//                     Options.map((option,i)=>{
//                         return(
//                             <option key={i} value={option.option}>{option.option}</option>
//                         )
//                     })
//                 }
//             </select>
//         </li>
//     )
// }
// export const Select2: React.FC <OptionProps> = ({Options,bg,clr,str}) => {
//     return(
//         <>
//             <ArrowDown stroke={str} />
//             <select name="search" id="search" style={{background:bg,color:clr}}>
//                 {
//                     Options.map((option,i)=>{
//                         return(
//                             <option key={i} value={option.option}>{option.option}</option>
//                         )
//                     })
//                 }
//             </select>
//         </>
//     )
// }

export const ProfileManagement: React.FC = () => {
    const navigate = useNavigate();
    const textInputRef = useRef<HTMLInputElement>(null);
    const [usuariosConsulta, setUsuariosConsulta] = React.useState([] as any);
    const [usuarios, setUsuarios] = React.useState([] as any);
    const [usuariosFiltro, setUsuariosFiltro] = React.useState([] as any);
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        let enteredText = textInputRef.current!.value;
    }
    const handleSubmitBack = () => {
        navigate(AppRoutes.USUARIOS_PLATAFORM,{replace:true});
    };
    const mewUser = () => {
        navigate(AppRoutes.NEW_USER,{replace:true});
    };
    const [perfunc, setPerfunc] = useState(false)
    

    const [ArrayPerson, setArrayPerson] = React.useState({
        Nombre: '',
        Identificacion:'',
        Cargo:'',
        Email:'',
        Telefono1:'',
        Telefono2:'',
        PerfilUsuario:'',
        TipoIdentificacion:''
    });
    useEffect(()=>{  
        checkUser("1");        
    },[]);

    const checkUser= async (valorCheck:string)=>{
        const valor= await AdminService.getUsuarios({
              Pws_Tip_Estado:valorCheck
          })
          console.log(valor);
          
          setUsuarios(valor.payload.data.datos_per.datos_Personas)
          setUsuariosFiltro(valor.payload.data.datos_per.datos_Personas)
      }

    const UserItem: React.FC <InfoProps2> = ({info}) => {
        console.log(info);
        
        const handleClick = async () => {
            let sends:WloCreditoService.SolicitudDetalla={
                Pws_Num_Solicitud:"",
                Pws_Identificacion: info.Pws_identificacion,
                Pws_Tip_Identificacion: info.Pws_tipoIdentificacion,
                Pws_Perfil_Acceso:"",
                Pws_Fec_Solicitud:"",
            }
            const consulta = await WLOCreditAPIService.consultaSolicitudCygnusDetal(sends)            
            setUsuariosConsulta(consulta.payload.data.datos_persona.Datos_persona)  
            
            var ConsultaDetalles = consulta.payload.data.datos_persona.Datos_persona;
            console.log(ConsultaDetalles);
            let datosUser = {
                iden: ConsultaDetalles[0].Pws_Identificacion,
                tipoIden: ConsultaDetalles[0].Pws_Tip_Identificacion,
                tipoPer: ConsultaDetalles[0].Pws_Tip_Perfil
            }            
            let usuario = datosUser.iden != null || datosUser.iden ? sessionStorage.setItem('cambioAdm', JSON.stringify(datosUser)) : false;            
            const Perfil = ConsultaDetalles[0].Pws_Tip_Perfil;

            if(Perfil === '1')
                ArrayPerson.PerfilUsuario = "Gestor";
            else if(Perfil === '2')
                ArrayPerson.PerfilUsuario = "Autogestión"
            else if(Perfil === '3')
                ArrayPerson.PerfilUsuario = "Administrador"
            else if(Perfil === '4')
                ArrayPerson.PerfilUsuario = "Vendedor"

            ArrayPerson.Email = ConsultaDetalles[0].Pws_Correo;
            ArrayPerson.TipoIdentificacion = ConsultaDetalles[0].Pws_Tip_Identificacion;
            ArrayPerson.Cargo = ConsultaDetalles[0].Pws_Car_emp;
            ArrayPerson.Telefono1 = ConsultaDetalles[0].Pws_Telefono1;
            ArrayPerson.Telefono2 = ConsultaDetalles[0].Pws_Telefono2;
            ArrayPerson.Identificacion = ConsultaDetalles[0].Pws_Identificacion;
            ArrayPerson.Nombre = ConsultaDetalles[0].Pws_Nombres + " " + ConsultaDetalles[0].Pws_Apellidos1 + " " + ConsultaDetalles[0].Pws_Apellidos2; 
            
            setArrayPerson(ArrayPerson) 
            console.log(ArrayPerson);
                      
        };
        return (
            <>
                <ul>
                      <li className="listItem firstItem">{info.Pws_Codigo_Persona}</li>
                      <li className="listItem">{
                          info.Pws_Tip_Usuario === "1"  ? "Gestor" : 
                          info.Pws_Tip_Usuario === "2"  ? "Autogestión" : 
                          info.Pws_Tip_Usuario === "3"  ? "Administrador" : 
                          info.Pws_Tip_Usuario === "4"  ? "Vendedor" : ""
                      }
                      </li>
                      <li className="listItem">{info.Pws_Codigo_Area}</li>
                      <li className="listItem" style={{display: 'flex', alignItems: 'flex-start'}}><button style={{color: '#fff', padding: '4px 10px',  width: '100%'}} onClick={() => handleClick()}>Ver detalle</button></li>
                </ul>
            </>
          )
    }
  return (
    <>
    {
        perfunc
        ?<PerfilFunc setPerfunc={setPerfunc}/>
        :<></>
    }
    <div className="UserDoc adminDoc">
        <Fab variant="extended" color="neutral" aria-label="add"
                         onClick={handleSubmitBack} style={{float:"left"}} className="hooverButtonRed">
                        <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                      ATRAS
                    </Fab>
        <div className="UserDocument">
            <h4>Administración de perfiles</h4>
            <div className="UserTable">
                <div className="first-part only">
                    <div className="userAdd">
                        <button onClick={()=>setPerfunc(true)} style={{background: '#00908E', color: '#fff', width: '20%'}}>Agregar nuevo perfil <UserIcon/></button>
                        <button onClick={mewUser} style={{background: '#00908E', color: '#fff', width: '20%'}}>Filtrar resultados <UserAddIcon/></button>
                        <div className="search">
                            <form action="" method="post" onSubmit={handleSubmit}>
                                <li>
                                    <Search />
                                    <input type="text" name="seacrh" placeholder="Search" ref={textInputRef}/>
                                </li>
                            </form>
                        </div>
                    </div>
                    <div className="table">
                        <Table TableItem={UserItem} headers={userHeaders} data={usuariosFiltro}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
