import React, { useEffect, useState } from 'react';
import './SolicitudesCliente.scss';
import { WLOCreditAPIService } from '../../services/WLOCreditService';
import ClockLoader from 'react-spinners/ClockLoader';
import { format, parse } from 'date-fns';

export const SolicitudesCliente = () => {
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [solicitudes, setSolicitudes] = useState({}as any);
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);
  const [nombres, setNombres] = React.useState("");
  const [fechaExpedicion, setFechaExpedicion] = useState('');

  useEffect(()=>{    
    if(Object.keys(solicitudes).length == 0){
      consultarSolPagare();
    }    
  },[solicitudes])
  const consultarSolPagare = async ()=>{
    try{
      setIsLoadingInfo(true);    
      const identidad:any = sessionStorage.getItem('identificacion');
      const savePerson = await WLOCreditAPIService.findPagare(identidad)   
      console.log(savePerson);
         
      const person = await WLOCreditAPIService.findPersona(identidad)      
      setNombres(person[0].CNombres + " " + person[0].CApellidos)
      setIsLoadingInfo(false);
      setSolicitudes(savePerson);
    }catch(e){
      console.log(e);
    }
    
  }
  const iniciarFirma = async ()=>{
    try{
      const solicitudPagare = solicitudes;
      const resultadoFirma = await WLOCreditAPIService.iniciarProcesoFirma(solicitudPagare[0]);
    console.log("Proceso de firma iniciado:", resultadoFirma);

    }catch(e){
      console.log(e);
    }
    
  }

  const handleFechaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFechaExpedicion(event.target.value); // Actualiza el estado con el valor del input
  };
  const handleIniciarProceso = () => {
    setShowModal(true);
    console.log("showModal after setShowModal(true):", showModal);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowSecondModal(false);
    console.log("showModal after setShowModal(false):", showModal);
  };

  const handleValidarModal =async () => {
    // Aquí puedes realizar la validación

    const personANI = await WLOCreditAPIService.consultaSolicitudANI({
      Pws_Tip_Identificacion: sessionStorage.getItem('tipoidentificacion')!,
      Pws_Identificacion: sessionStorage.getItem('identificacion')!
  });
  console.log(personANI);
  console.log(fechaExpedicion);
  
  const fechaAni = parse(personANI.fechaExpedicion, 'dd/MM/yyyy', new Date());
    const fechaAniConv = format(fechaAni, 'yyyy-MM-dd');
    if (sessionStorage.getItem('identificacion')===personANI.nuip && fechaExpedicion==fechaAniConv ){      
      const isValidado = true; // Por ahora, lo establecemos en true
      if (isValidado) {
        setShowModal(false);
        setShowSecondModal(true);
      }
    }
  };

  return (
    <div className="mis-pagare-container">
      <ClockLoader id='spinner' color={"#d72222"} loading={isLoadingInfo} size={100} />
      <h2>Mis pagaré</h2>
      <div className="table-container">
        <div className="btn-cargar-solicitudes">
          <button className="cargar-solicitudes-btn">Cargar Solicitudes</button>
        </div>
        <h3>Mis pagaré</h3>
        {solicitudes.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>N° de radicado</th>
                <th>Deudor</th>
                <th>Estado</th>
                <th>Fecha de solicitud</th>
                <th>Fecha inicio proceso</th>
                <th>Fecha fin proceso</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {solicitudes.map((pagare:any) => (
                <tr key={pagare.ICodigo}>
                  <td>{pagare.iNumRadicado}</td>
                  <td>{nombres}</td>
                  <td>{pagare.iTipoEstadoSolPagare.CDescripcion}</td>
                  <td>{pagare.fFechaSolicitud}</td>
                  <td>{pagare.fFechaInicioProceso}</td>
                  <td>{pagare.fFechaFinProceso}</td>
                  <td>
                    <div className="acciones-btns">
                      <button className="acciones-btn visible" onClick={handleIniciarProceso}>
                        Iniciar proceso
                      </button>
                      <button className="acciones-btn oculto">Actualizar</button>
                      <button className="acciones-btn oculto">Suspender OPF</button>
                      <button className="acciones-btn oculto">Cancelar OPF</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No hay solicitudes disponibles.</p>
        )}
        <div className="pagination">
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <span>5</span>
        </div>
      </div>
      <div className="total-pagare">
        <p>En total hay 1 Pagaré.</p>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content-ANI">
            <div className="cabecera-modal">
              <h2>Validación de identidad</h2>
              <span className="close-button" onClick={handleCloseModal}>
                &times;
              </span>
            </div>
            <br />
            <div style={{color:'black', textAlign:'justify'}}>
              {'Ingrese la fecha de expedición de su documento de identidad para realizar la validación.'}
            </div>
            <br />
            <input 
              type="date" 
              placeholder="dd/mm/aaaa" 
              value={fechaExpedicion} 
              onChange={handleFechaChange} // Maneja el cambio de valor del input
            />
            <div className="modal-buttons">
              <button onClick={handleCloseModal}>Cancelar</button>
              <button onClick={handleValidarModal}>Validar</button>
            </div>
          </div>
        </div>
      )}

      {showSecondModal && (
        <div className="modal">
          <div className="modal-content-ANI">
            <div className="cabecera-modal">
              <h2>Iniciar proceso firma pagaré</h2>
              <span className="close-button" onClick={handleCloseModal}>
                &times;
              </span>
            </div>
            <div style={{color:'black', textAlign:'justify'}}>
              Al iniciar el proceso firma pagaré, debe tener en cuenta que se llegan un SMS o número de celular
              registrado con la entidad y un email al correo de contacto que tiene ingresado en la entidad. Tenga en
              cuenta que el tiempo de caducidad del link o código OTP enviado es de 5 minutos.
            </div>
            <div style={{color:'black', textAlign:'justify'}}>
              Tenga en cuenta que la vigencia del link o código OTP enviado es de 5 minutos.
            </div>
            <div className="modal-buttons">
              <button onClick={handleCloseModal}>Cancelar</button>
              <button onClick={iniciarFirma}>Validar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};