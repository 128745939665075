import React from 'react';
import {ArrowBack, ArrowForward} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import {useNavigate} from 'react-router-dom';
import './header.scss';

interface headerProp {
  next: string;
  backReturn:string;
}
export const NewHeader: React.FC<headerProp> = ({next,backReturn}) => {
  const navigate = useNavigate();
  const handleSubmitBack = () => {
    navigate(backReturn,{replace:true});
};
  return (
    <div className="back-forward">
            <Fab variant="extended" color="neutral" aria-label="add"
                         onClick={handleSubmitBack} style={{float:"left"}} className="hooverButtonRed">
                        <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                      ATRAS
                    </Fab>
        <Fab style={{display: `${next}`}} type={next=="block"?'submit':'button'} variant="extended" color="neutral" aria-label="add">
            {'Crear usuario'}
            <ArrowForward sx={{mr: 1}}/>
        </Fab>
    </div>
  )
}
