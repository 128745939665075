import axios from "axios";
import {WloCreditoService} from "../types";

export class AuthService {
    static async getPerfiles(): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/profiles');
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async login(user: WloCreditoService.LoginRequest): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/login', user);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static obtenerNavegador = () => {
        let ua = navigator.userAgent;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
          return 'iOS';
        } else if (/Chrome/i.test(ua)) {
          return 'Chrome';
        } else {
          return 'Desktop';
        }
      };

    static async loginWeb(user: WloCreditoService.LoginRequest): Promise<any> {
        try {
            let navegador = this.obtenerNavegador();
            const {data} = await axios.post(process.env.REACT_APP_BACK_WEB +'/LoginPinc/Cliente/loginCliente?iTipoPerfil='+ user.Pws_Tip_perfil+'&xiTipoIden='+user.Pws_Tip_identificacion +'&xcIdentificacion='+user.Pws_Identificacion+'&xcClave='+user.Pws_clave_usu+'&xcNavegador='+navegador +'&xiTipoCanal='+user.Pws_Tip_Canal);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async changepassword(changepass: any): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/changepass', changepass);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async createLogin(CreateLogin: any): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/createUser', CreateLogin);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async LoginBloqueo(CreateLogin:{
        Pws_Identificacion: string,
        Pws_Tip_identificacion: string,
        Pws_Identificacion_aud: string,
        Pws_Tip_identificacion_aud: string,
        Pws_Fecha_Cam: string,
        Pws_Tip_Accion: string,
        Pws_Observacion: string
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/loginBloqueo', CreateLogin);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async loginFirma(user: {
        Pws_Correo: string,
        Pws_Telefono: string
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/login-firma-correo', user);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async loginValidacion(user: {
        Pws_Identificacion: string,
        Pws_Tip_identificacion: string,
        Pws_Correo: string,
        Pws_Telefono: string
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/login-firma-identidad', user);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async documentSagrilaft(datoconsul: {
        idDatoConsultado: string
    }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/auth/documentoSagrilaft', datoconsul);
            return data;
        } catch (e) {
            throw e;
        }
    }
}