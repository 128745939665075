import {BrowserRouter, Navigate, Route, RouteProps, Routes} from "react-router-dom";
import {Catalog, 
        ChangePassword, 
        Home, 
        Login, 
        LoginAuto,
        MenuAgent, 
        ProfileOptions,
        New,
        LoginFirma17,
        ReferenciasYDocumentos,
        InformacionFinanciera10,
        SolicitudesDeCredito,
        DetalleDelCredito,
        DetalleDelCodeudorRequerido,
        MensajeDespuesdeValidarSargilat,
        ConfirmInformation,
        CodebtInfo
    } from "./views";
import {LoginFirma} from "./views/LoginFirma/LoginFirma";
import {PDFView} from "./components/Sign/DocumentView";
import { Confirmation } from "./views/Confirmation/Confirmation";
import * as _ from 'lodash';
import { useLoginContext } from "./hooks/useLoginContext";
import { ProtectedRoute } from "./components/Authorization/ProtectedRoute";
import { PDFViewSign } from "./components/Sign/DocumentViewSign";
import { ProtectedRouteSign } from "./components/Authorization/ProtectedRouteSign";
import { useSignContext } from "./hooks/useSignContext";
import { NewPdf } from "./views/New/NewPdf";
import { UsersPlatform } from "./views/UsersPlatform/UsersPlatform";
import { NewUser } from './views/NewUser/NewUser';
import { Perfil } from "./views/Perfil";
import { Datos } from "./views/Datos";
import { AdminCat } from "./views/AdminstrateCatalog";
import { CatalogoMain } from "./views/AdminstrateCatalog/AdministradorCatalogo";
import { Cambio } from "./views/Cambio";
import { UserDetails } from "./views/UserDetails";
import { OTP } from "./views/OTP";
import { NewPDF2 } from "./views/NewPdf2";
import { Components } from "./views/Components";
import { useEffect, useState } from "react";
import { AdminService } from "./services";
import { AdminPerfil } from "./views/UsersPlatform/AdminPerfil";
import { DetailCategory } from "./components/Inventory/DetailCategory";
import { DetailCategoryView } from "./views/DetailCategory";
import { PoliciesView } from "./views/Policies/PoliciesView";
import { ThankYouView } from "./views/ThankYouView/ThankYouView";
import { LoginPagare } from "./views/LoginPagare/LoginPagare";
import { SolicitudClienteContainer } from "./views/SolicitudesCliente/SolicitudesClienteContainer";
import { LoginOTP } from "./views/LoginOTP/LoginOTP";
import { ProfilesPlataform } from "./views/UsersPlatform/ProfilesPlataform";  

export enum AppRoutes {
    LOGIN = '/login',
    LOGINAUTO = '/loginauto',
    PROFILE = '/profile',
    PROFILE_OPTIONS = '/profile-options',
    CATALOG = '/catalog',
    HOME = '/home',
    MENU_USER = '/menu-agent',
    CHANGE = '/change-password',
    DOCUMENTS = '/documents',
    SIGN = '/sign',
    VIEW_DOCUMENT = '/view_document',
    SARGILAT = '/sargilat',
    PROPETARIO='/CoDebt-information',
    VIEW_DOCUMENT_SIGN = '/view_document_sign',
    CONFIRMATION = '/confirmation',
    // New
    NEW = '/new',
    LOGINFIRMA17 = '/loginfirma17',
    REFERENCIAS_Y_DOCUMENTOS_13 = '/Referencias-y-documentos-13',
    INFORMACION_FINANCIERA_10 = '/Informacion-financiera-10',
    SOLICITUDES_DE_CREDITO = '/Solicitudes-de-credito',
    DETALLE_DEL_CREDITO = '/Detalle-del-credito',
    DETALLE_DEL_CODEUDOR_REQUERIDO = '/Detalle-del-codeudor-requerido',
    MENSAJE_DESPUES_DE_VALIDAR_SARGILAT = '/Mensaje-despues-de-validar-sargilat',
    OLVIDAR_PASS='/forgetpass',
    USUARIOS_PLATAFORM='/Users_Platform_view',
    CATALAGO_ADMINI='/Adminstartive_Catalog_view',
    IMAGEN_CORPORTAVIA='/imagen_corportiva',
    NEW_USER='/New_User_view',
    CATEGORIA_VIEW_DETAIL='/Detail_Category',
    POLICIES='/Policies',
    THANKYOU='/ThankYouView',
    LOGINPAGARE='/Login-Pagare',
    SOLCLIENTESPAGARE='/Sol-Pagare-Clientes',
    LOGINOTP='/LoginOTP',
    PROFILEMANAGEMENT='/Profile-Management'
}


export function Router() {
    const { user } = useLoginContext();
    const { sign } = useSignContext();
    const [one,setone] = useState(true);
    useEffect(()=>{
        if(one){
            AdminService.getColors().then((valor)=>{
            document.documentElement.style.setProperty('--primary-color', valor.payload?.primary);
            document.documentElement.style.setProperty('--secondary-color', valor.payload?.secondary);
            document.documentElement.style.setProperty('--tartiary-color', valor.payload?.tartiary);
            document.documentElement.style.setProperty('--fourth-color', valor.payload?.fourth);
            })
            setone(false)
        }
    },[
        one 
    ])
    return (
        <BrowserRouter>    
            <Routes>
                <Route index element={<ProfileOptions />} />
                <Route path="profile-options" element={<ProfileOptions />} />
                <Route path="catalog" element={ <Catalog/>} />
                <Route element={<ProtectedRoute user={user} />}>
                    <Route path="Adminstartive_Catalog_view" element={<CatalogoMain />} />
                    <Route path="forgetpass" element={<Cambio />} />
                    <Route path="Policies" element={<PoliciesView/>} />
                    <Route path="ThankYouView" element={<ThankYouView/>} />
                    <Route path="home" element={<Home />} />
                    <Route path="login" element={<Login />} />
                    <Route path="loginauto" element={<LoginAuto />} />
                    <Route path="menu-agent" element={<MenuAgent/>} />
                    <Route path="CoDebt-information" element={<CodebtInfo/>} />
                    <Route path="Detalle-del-codeudor-requerido" element={<DetalleDelCodeudorRequerido/>} />
                    <Route path="Solicitudes-de-credito" element={<SolicitudesDeCredito/>} />
                    <Route path="Detalle-del-credito" element={<DetalleDelCredito/>} />
                    <Route path="Users_Platform_view" element={<UsersPlatform/>} />
                    <Route path="imagen_corportiva" element={<Datos />} />
                    <Route path="New_User_view" element={<NewUser/>} />
                    <Route path="Detail_Category" element={<DetailCategory/>} />
                </Route>
                <Route path="sign" element={<LoginFirma17 />} />
                <Route element={<ProtectedRouteSign sign={sign} />}>
                    <Route path="view_document" element={<PDFView/>} />
                    <Route path="view_document_sign" element={<PDFViewSign/>} />
                    <Route path="sargilat" element={<MensajeDespuesdeValidarSargilat/>} />
                </Route>
                <Route path="confirmation" element={<Confirmation />} />
                <Route path="*" element={<p>There's nothing here: 404!</p>} />

                {/* Sprint1 Pages Without ProtectRouted */}
                <Route path="Login-Pagare" element={<LoginPagare/>} />
                <Route path="Sol-Pagare-Clientes" element={<SolicitudClienteContainer/>} />
                <Route path="home_view" element={<Home />} />
                <Route path="login_view" element={<Login />} />
                <Route path="menu_agent_view" element={<MenuAgent/>} />
                <Route path="CoDebt-information_view" element={<CodebtInfo/>} />
                <Route path="Detalle-del-codeudor-requerido_view" element={<DetalleDelCodeudorRequerido/>} />
                <Route path="Solicitudes-de-credito_view" element={<SolicitudesDeCredito/>} />
                <Route path="Detalle-del-credito_view" element={<DetalleDelCredito/>} />
                <Route path="view_document_view" element={<PDFView/>} />
                <Route path="view_document_sign_view" element={<PDFViewSign/>} />
                <Route path="sargilat_view" element={<MensajeDespuesdeValidarSargilat/>} />
                <Route path="confirmation_view" element={<Confirmation />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="LoginOTP" element={<LoginOTP />} />
                <Route path="Profile-Management" element={<ProfilesPlataform />} />

                {/* Sprint 2 New */}
                <Route path="new" element={<New/>} />
                <Route path="loginfirma17" element={<LoginFirma17/>} />
                <Route path="Referencias-y-documentos-13" element={<ReferenciasYDocumentos/>} />
                <Route path="Informacion-financiera-10" element={<InformacionFinanciera10/>} />
                <Route path="Confirm-information" element={<ConfirmInformation/>} />
                <Route path="new-pdf" element={<NewPdf/>} />

                {/* Sprint 3 New */}
                <Route path="Perfil_Detail_view" element={<Perfil />} />
                <Route path="Users_Platform_view2" element={<UsersPlatform/>} />
                <Route path="information-solicitud" element={<ReferenciasYDocumentos/>} />
                <Route path="Detalle_credito_view" element={<DetalleDelCredito/>} />
                <Route path="Cambio_view" element={<Cambio/>} />
                <Route path="Datos_view" element={<Datos/>} />
                <Route path="Detail_Category_view" element={<DetailCategoryView/>} />
                <Route path="UserDetails_view" element={<UserDetails/>} />
                <Route path="OTP_view" element={<OTP/>} />
                <Route path="New_PDF2_view" element={<NewPDF2/>} />
                <Route path="Components_view" element={<Components />} />
                <Route path="Admin_perfil_view" element={<AdminPerfil/>} />
            </Routes>
      </BrowserRouter>
    );
}