import React from 'react';
import './Policies.scss';
import { Policies } from './Policies';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';


export const PoliciesView = () => {
  return (
    <>
      <ResponsiveAppBar/>
      <Policies />
    </>
  )
}
