import './request-info.scss';
import {useIntl} from "react-intl";
import {Field} from 'formik';
import {typeIdentification} from '../../../__mocks__/data';
import {WLOCreditAPIService} from '../../../services';
import _ from 'lodash';
import {useLoginContext} from '../../../hooks/useLoginContext';
import {useRequestContext} from '../../../hooks/useRequestContext';
import toast from 'react-hot-toast';
import {useState} from 'react';
import {ANIPersona, CygnusPersona} from '../../../context/RequestContext';
import BarLoader from "react-spinners/BarLoader";

interface FormKnowYouMobileProps {
    errors?: any;
    values?: any;
}

export function FormKnowYouMobile(props: FormKnowYouMobileProps) {
    const {user} = useLoginContext();
    const {request, setRequest} = useRequestContext();
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const {errors, values} = props;
    const intl = useIntl();

    const handleSearch = async () => {
        setIsLoadingInfo(true);
        if (!_.isEmpty(values.Pws_Tip_Identificacion!) || !_.isEmpty(values.Pws_Identificacion!)) {
            if (values.Pws_Identificacion.length < 5) {
                toast.error('Debe indicar un Número de Identificación entre 5 y 10 caracteres');
            } else {
                try {
                    const person = await WLOCreditAPIService.consultaSolicitudCygnus({
                        Pws_Tip_Identificacion: values.Pws_Tip_Identificacion!,
                        Pws_Identificacion: values.Pws_Identificacion!
                    });
                    if (!_.isEmpty(person.payload)) {
                        if (person.payload.result === "1") {
                            const pCygnus = person.payload.data.datos_persona.Datos_persona[0] as CygnusPersona;
                            
                            values.Pws_Apellidos1=pCygnus.Pws_Apellidos1;
                            values.Pws_Apellidos2=pCygnus.Pws_Apellidos2;
                            values.Pws_Nombres=pCygnus.Pws_Nombres;
                            setRequest({...request, personCygnus: pCygnus, searchANI: false});
                            sessionStorage.setItem('person', JSON.stringify(person.payload.data.datos_persona.Datos_persona[0]));
                            setIsLoadingInfo(false);
                        } else {
                            const personANI = await WLOCreditAPIService.consultaSolicitudANI({
                                Pws_Tip_Identificacion: values.Pws_Tip_Identificacion!,
                                Pws_Identificacion: values.Pws_Identificacion!
                            });
                            const pANI = personANI as ANIPersona;
                            values.Pws_Apellidos1=pANI.primerApellido;
                            values.Pws_Apellidos2=pANI.segundoApellido;
                            values.Pws_Nombres=pANI.primerNombre+" "+pANI.segundoNombre;
                            setIsLoadingInfo(false);
                            setRequest({...request, personANI: pANI, searchANI: true});
                            sessionStorage.setItem('person', JSON.stringify(person));
                        }
                    }
                } catch (e) {
                    const error = e as any;
                    if (!_.isEmpty(error.response.data)) {
                        setRequest({...request, errorConsulta: true});
                        const menssage = error.response.data as any;
                        toast.error(menssage);
                        setTimeout(() => {
                            setIsLoadingInfo(false);
                        }, 4000);
                    } else {
                        setRequest({...request, errorConsulta: true});
                        toast.error('En estos momentos no podemos atender su solicitud');
                        setTimeout(() => {
                            setIsLoadingInfo(false);
                        }, 4000);

                    }
                }
            }
        }
    }

    return (
        <div className={'row'}>
            <div className="col-2"/>
            <div className="col-8">
                <div
                    className={"title-medium-red-mobile"}>{intl.formatMessage({id: "form_credit_information_title"})}</div>
            </div>
            <div className="col-2"/>
            <div
                className={"subtitle-medium-mobile"}>{intl.formatMessage({id: "form_credit_information_subtitle"})}</div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                    <Field id={"Pws_Nombres"}
                           name={"Pws_Nombres"}
                           disabled
                           className={'form-know-you-item-input-mobile'}
                           placeholder={"Nombres"}/>
                </div>
            </div>
            
            <div className={'flex-container-form-know-you'}>
                 <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                    <Field id={"Pws_Apellidos1"}
                           name={"Pws_Apellidos1"}
                           disabled
                           className={'form-know-you-item-input-mobile'}
                           placeholder={"Primer apellido"}/>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
            <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                    <Field id={"Pws_Apellidos2"}
                           name={"Pws_Apellidos2"}
                           disabled
                           className={'form-know-you-item-input-mobile'}
                           placeholder={"Segundo apellido"}/>
                </div>
            </div>
            
         
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                    <Field as="select"
                           id={"Pws_Tip_Identificacion"}
                           name={"Pws_Tip_Identificacion"}
                           className={'form-know-you-item-input-mobile'}>
                        <option>{errors && errors.Pws_Tip_Identificacion ? errors.Pws_Tip_Identificacion : intl.formatMessage({id: "placeholder_identification_type"})}</option>
                        {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item, key: number) => {
                            return (
                                <option key={key} value={item.s_codigo}>{item.s_descripcion}</option>
                            )
                        })}
                    </Field>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                    <Field id={"Pws_Identificacion"}
                           onBlur={handleSearch}
                           name={"Pws_Identificacion"}
                           className={'form-know-you-item-input-mobile'}
                           placeholder={errors && errors.Pws_Identificacion ? errors.Pws_Identificacion : intl.formatMessage({id: "placeholder_document_number"})}/>
                    <BarLoader height={4} width={300} color={"#d72222"} loading={isLoadingInfo}/>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                    <Field id={"Pws_Telefono1"}
                           name={"Pws_Telefono1"}
                           className={'form-know-you-item-input-mobile'}
                           placeholder={errors && errors.Pws_Telefono1 ? errors.Pws_Telefono1 : intl.formatMessage({id: "placeholder_phone_1"})}
                    />
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                    <Field id={"Pws_Telefono2"}
                           name={"Pws_Telefono2"}
                           className={'form-know-you-item-input-mobile'}
                           placeholder={errors && errors.Pws_Telefono2 ? errors.Pws_Telefono2 : intl.formatMessage({id: "placeholder_phone_2"})}/>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                    <Field id={"Pws_Correo"}
                           name={"Pws_Correo"}
                           className={'form-know-you-item-input-mobile'}
                           placeholder={errors && errors.Pws_Correo ? errors.Pws_Correo : intl.formatMessage({id: "placeholder_mail"})}/>
                </div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                    <Field id={"mail_confirmation"}
                            autoComplete="off"
                           name={"mail_confirmation"}
                           onPaste={(e:any)=>{
                            e.preventDefault()
                            return false;
                          }}
                           className={'form-know-you-item-input-mobile'}
                           placeholder={intl.formatMessage({id: "placeholder_mail_confirmation"})}/>
                </div>
            </div>
        </div>

    )
}