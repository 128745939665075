import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconAgentDark } from '../../../assets/icons';
import { AppRoutes } from '../../../Router';
import { AgenteH,AgenteM,Agente } from '../../../assets/icons';
import './user-profile.scss';
import { Desktop, LandscapePhonesAndSmaller, LandscapeTablet, TabletAndMinis } from "../../../utils/breakpoints";
import { Col, Row } from "reactstrap";
import { AdminService, AuthService } from '../../../services';
import { WLOCreditAPIService } from '../../../services';
import { useIntl } from 'react-intl';
import { useLoginContext } from '../../../hooks/useLoginContext';
import * as _ from 'lodash';
import toast from 'react-hot-toast';
import ClockLoader from "react-spinners/ClockLoader";
import { infoProfiles } from '../../../__mocks__/data';

export function UserProfile() {
    const { user, setUser } = useLoginContext();
    const [isLoading, setIsLoading] = useState(false);
    const [profiles, setProfiles] = useState(null);
    const intl = useIntl();
    const navigate = useNavigate();
    const handleSubmit =  async(value: any) => {
        setUser({ ...user, idProfile: value, isSuccessfulLogin: false });
        const tiposIden = await AdminService.gettipoidentificacion();
        sessionStorage.setItem('tiposIden', JSON.stringify(tiposIden.payload.data.LstTiposIdent.Datos_TiposIdentificacion))
      if(Number(value)===2){
        navigate(AppRoutes.LOGINAUTO, { replace: true});
      }else if(Number(value)===4){
        sessionStorage.setItem('Cliente', 'Cliente');
        navigate(AppRoutes.LOGIN, { replace: true });

      }else if(Number(value)===1){
        navigate(AppRoutes.LOGIN, { replace: true });

      }else if(Number(value)===3){
        navigate(AppRoutes.LOGIN, { replace: true });

      }
    };
    useEffect(() => {
        sessionStorage.setItem('User', JSON.stringify({}))
    
        setIsLoading(true);
        document.body.classList.add('body-login');
        return () => {
            document.body.classList.remove('body-login');
        };
    }, []);

    useEffect(() => {
        setIsLoading(true);
        fechInventary();
      }, []); 

    const fechInventary = async () => {
        try {
            const result = await AuthService.getPerfiles();
            if (!_.isEmpty(result.payload.result) && result.payload.result > 0) {
                setProfiles(result.payload.data);
            }            
        } catch (error) {
            setIsLoading(true);
            toast.error('En este momento no podemos atender su solicitud');
            setTimeout(() => {
                setIsLoading(false);
            }, 5000);
        }
    
    }
    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => {
            setIsLoading(false);
            
        }, 1000);
        return () => clearTimeout(timer);
      }, []);

      const renderDetailProfileLocal = (type: string) => {
        const localProfile = infoProfiles
        const showProfiles = infoProfiles && !isLoading;
        if(showProfiles) {
            if (localProfile && !_.isEmpty(localProfile.Ws_Perfiles_consultaResult)) {
                return (
                    <Row className={type === 'desktop' ? 'container-profiles' : 'container-mobile-profiles'}>
                    {localProfile && localProfile.Ws_Perfiles_consultaResult.Array_Perfiles.Datos_Perfiles.map((item: any, index: number) => {
                        return (
                            <Col key={`${index}-${item.Pws_Est_perf}`}
                                id={`${index}-${item.Pws_Est_perf}`}
                                md={2} className={type === 'desktop' ? 'items-container-profiles' : 'items-container-mobile-profiles'}
                                onClick={() => handleSubmit(item.Pws_Tip_perfil)}>
                                {<IconAgentDark />}
                                <p>{item.Pws_Nom_perf}</p>
                            </Col>
                        )
                    })}
                </Row>
                ) 
            }
        }
        else {
            return (<div></div>);
        }
    }

    const renderDetailProfile = (type: string) => {
        const showProfiles = profiles && !isLoading;
        if(showProfiles) {
            const tempProfiles = profiles as any;
            const firstTwoProfiles = tempProfiles?.Array_Perfiles?.Datos_Perfiles;
            if (!_.isEmpty(firstTwoProfiles)) {
                return (
                    <Row className={type === 'desktop' ? 'container-profiles' : 'container-mobile-profiles'}>
                    {firstTwoProfiles.map((item: any, index: number) => {
                        let icono;
                        if (index === 0) {
                            icono = <Agente />;
                        } else if (index === 1) {
                            icono = <AgenteH />;
                        } 
                        else {
                            icono = <AgenteM />;
                        }
                        return (
                            <Col  key={`${index}-${item.Pws_Est_perf}`}
                                id={`${index}-${item.Pws_Est_perf}`}
                                md={2} className={type === 'desktop' ? 'items-container-profiles' : 'items-container-mobile-profiles'}
                                onClick={() => handleSubmit(item.Pws_Tip_perfil)}
                                style={{ borderColor: '#ffff' }}>
                                

                                {icono}
                                <div style={{color:'#fff'}}>
                                    {item.Pws_Nom_perf}
                                </div>
                            </Col>
                        )
                    })}
                </Row>
                ) 
            }
        }
        else {
            return (<div></div>);
        }
    }

    const renderDesktopTablet = () => {
        return (
            <div id={"user-profile"}>
                <ClockLoader id='spinner' color={"#d72222"} loading={isLoading} size={100}/>
                <div className='title-text-profiles'>{intl.formatMessage({ id: "title_confirm_your_profile_to_continue" })}</div>
                {renderDetailProfile('desktop')}
            </div>
        )
    }

    const renderMobile = () => {
        return (
            <div id={"user-profile-mobile"}>
                <div
                    className='title-text-mobile-profiles'>{intl.formatMessage({ id: "title_confirm_your_profile_to_continue" })}</div>
                {renderDetailProfile('mobile')}
            </div>
        )
    }

    return (
        <>
            <Desktop>
                {renderDesktopTablet()}
            </Desktop>
            <TabletAndMinis>
                {renderMobile()}
            </TabletAndMinis>
            <LandscapePhonesAndSmaller>
                {renderMobile()}
            </LandscapePhonesAndSmaller>
            <LandscapeTablet>
                {renderDesktopTablet()}
            </LandscapeTablet>
        </>
    )
}