import React, { useState } from 'react';
import { NewHeader } from '../../components/shared/Header';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import './perfil.scss';
import { PlusIcon } from '../../assets/icons/component';
import { Table } from '../../components/shared/Table/Table';
import { PerfilFunc } from '../../components/shared/PerfilFunc';

const perfileHeaders = [
    {
        header: 'Código'
    },
    {
        header: 'Funcionalidad'
    },
    {
        header: 'Estado'
    },
    {
        header: 'Acciones'
    }
]

const perfilData = [
    {
        item1: '121423434',
        item2: 'login Perfiles',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '345353',
        item2: 'Cambio de contraseña',
        item3: 'Inhabilitada',
        color1: '#00908E',
    },
    {
        item1: '454645646',
        item2: 'Listado necesidades o sueños a cumplir',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '121423434',
        item2: 'login Perfiles',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '345353',
        item2: 'Cambio de contraseña',
        item3: 'Inhabilitada',
        color1: '#00908E',
    },
    {
        item1: '454645646',
        item2: 'Listado necesidades o sueños a cumplir',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '121423434',
        item2: 'login Perfiles',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '345353',
        item2: 'Cambio de contraseña',
        item3: 'Inhabilitada',
        color1: '#00908E',
    },
    {
        item1: '454645646',
        item2: 'Listado necesidades o sueños a cumplir',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '121423434',
        item2: 'login Perfiles',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '345353',
        item2: 'Cambio de contraseña',
        item3: 'Inhabilitada',
        color1: '#00908E',
    },
    {
        item1: '454645646',
        item2: 'Listado necesidades o sueños a cumplir',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '121423434',
        item2: 'login Perfiles',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '345353',
        item2: 'Cambio de contraseña',
        item3: 'Inhabilitada',
        color1: '#00908E',
    },
    {
        item1: '454645646',
        item2: 'Listado necesidades o sueños a cumplir',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '121423434',
        item2: 'login Perfiles',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '345353',
        item2: 'Cambio de contraseña',
        item3: 'Inhabilitada',
        color1: '#00908E',
    },
    {
        item1: '454645646',
        item2: 'Listado necesidades o sueños a cumplir',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '121423434',
        item2: 'login Perfiles',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '345353',
        item2: 'Cambio de contraseña',
        item3: 'Inhabilitada',
        color1: '#00908E',
    },
    {
        item1: '454645646',
        item2: 'Listado necesidades o sueños a cumplir',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '121423434',
        item2: 'login Perfiles',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '345353',
        item2: 'Cambio de contraseña',
        item3: 'Inhabilitada',
        color1: '#00908E',
    },
    {
        item1: '454645646',
        item2: 'Listado necesidades o sueños a cumplir',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '121423434',
        item2: 'login Perfiles',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
    {
        item1: '345353',
        item2: 'Cambio de contraseña',
        item3: 'Inhabilitada',
        color1: '#00908E',
    },
    {
        item1: '454645646',
        item2: 'Listado necesidades o sueños a cumplir',
        item3: 'Habilitada',
        color1: '#3DF04F',
    },
]

interface InfoProps {
    info: {
        item1: string,
        item2: string,
        item3: string,
        color1: string,
    };
}


export const PerfilItem: React.FC <InfoProps> = ({info}) => {
    return (
      <>
          <ul>
              <li className="listItem firstItem">{info.item1}</li>
              <li className="listItem">{info.item2}</li>
              <li className="listItem" style={{color: info.color1}}>{info.item3}</li>
              <li className="listItem" style={{display: 'flex', alignItems: 'flex-start'}}><button style={{color: '#fff', padding: '4px 10px'}}>Validate</button></li>
          </ul>
      </>
    )
}


export const Perfil = () => {
    const [perfunc, setPerfunc] = useState (false);
  return (
    <>
        {
            perfunc
            ?<PerfilFunc setPerfunc={setPerfunc} />
            :<></>
        }
        <ResponsiveAppBar/>
        <NewHeader next={`block`} backReturn={''}/>
        <h4 className="redhead">Detalle del perfil</h4>
        <div className='perfildetail'>
            <ul className='detail'>
                <li>
                    <h4>Tipo de ferfil:</h4>
                    <p>Vendedor</p>
                </li>
                <li>
                    <h4>Código:</h4>
                    <p>121423434</p>
                </li>
                <li>
                    <h4>Descripción:</h4>
                    <p>Usuario</p>
                </li>
            </ul>
            <ul className='perfilFunc'>
                <button onClick={()=>setPerfunc(true)}> Agregar otra funcionaldiad <PlusIcon /></button>
            </ul>
            <div className="table">
                <Table TableItem={PerfilItem} headers={perfileHeaders} data={perfilData} />
            </div>
        </div>
    </>
  )
}
