import {Field, Form, Formik} from 'formik';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Row} from 'reactstrap';
import {AppRoutes} from '../../Router';
import './LoginAuto.scss';
import '../../assets/styles/shared.scss';
import * as Yup from "yup";
import {typeIdentification} from '../../__mocks__/data';
import {WloCreditoService} from '../../types';
import {AdminService, AuthService} from '../../services';
import toast from 'react-hot-toast';
import {useIntl} from "react-intl";
import ClockLoader from "react-spinners/ClockLoader";
import * as _ from 'lodash';
import {Fab} from '@mui/material';
import {useLoginContext} from '../../hooks/useLoginContext';
import { MenuBar } from '../../components';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { WLOCreditAPIService } from '../../services';
import { DirectionButton } from '../../components/shared/Menubar/DirectionButton';
import { duration } from 'moment';
import { ArrowBack } from "@mui/icons-material";

export function LoginAuto() {
    const {user, setUser} = useLoginContext();
    const navigate = useNavigate();
    const intl = useIntl();
    let [isLoading, setIsLoading] = useState(false);
    const [tiposIdentidad, setTiposIdentidad] = useState([]as any);
    const handleSubmit = async (values: any) => {
        setIsLoading(true);
        try {
            if (_.isEmpty(values.Pws_Tip_Identificacion)){
                toast.error('Por favor seleccione el tipo de documento.',{
                    duration:5000,
                });
                setTimeout(() => {
                    setIsLoading(false);
                }, 5000);
            }else{
                if (_.isEmpty(values.Pws_Identificacion) || _.isEmpty(values.Pws_Tip_Identificacion) || _.isEmpty(values.Pws_Fec_expe)) {
                    toast.error('Debe completar todos los campos para continuar.',{
                        duration:5000,
                    });
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 5000);
                } else {
                    const personANI = await WLOCreditAPIService.consultaSolicitudANI({
                        Pws_Tip_Identificacion: values.Pws_Tip_Identificacion!,
                        Pws_Identificacion: values.Pws_Identificacion!
                    });
                    const fechaAni = parse(personANI.fechaExpedicion, 'dd/MM/yyyy', new Date());
                    const fechaAniConv = format(fechaAni, 'yyyy-MM-dd');
                    if (values.Pws_Identificacion===personANI.nuip && values.Pws_Fec_expe==fechaAniConv ){
                        sessionStorage.setItem('User', JSON.stringify({
                            ...user,
                            idUser: values.Pws_Identificacion,
                            lastLoginDate: new Date().toDateString(),
                            isSuccessfulLogin: true,
                            istipo:'2'
                        }))
                        const datosPersona={
                            numIdenVal: personANI.nuip,
                            tipIdenVal: values.Pws_Tip_Identificacion,
                            nombresVal: personANI.primerNombre + ' ' + personANI.primerApellido,
                        }
                        sessionStorage.setItem('personaValidada', JSON.stringify(datosPersona))
                        setIsLoading(false);
                        toast.success('Bienvenido',{duration:5000});
                        navigate(AppRoutes.CATALOG, {replace: true});
                        setUser({
                            ...user,
                            idUser: values.Pws_Identificacion,
                            lastLoginDate: new Date().toDateString(),
                            isSuccessfulLogin: true,
                            istipo:'2'
                        });
                    }else{
                        toast.error('Valide que sus datos sean correctos.',{
                            duration:5000,
                        });
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 5000);
                    }
                }
            }
        } catch (e) {
            setIsLoading(true);
            toast.error('En este momento no podemos atender su solicitud',{
                duration:5000,
            });
            setTimeout(() => {
                setIsLoading(false);
            }, 5000);
        }
    };
    useEffect(() => {
        const tI =  JSON.parse(sessionStorage.getItem('tiposIden')!);
        setTiposIdentidad(tI)        
        document.body.classList.add('body-login');
        return () => {
            document.body.classList.remove('body-login');
        };
    }, []);

    const handleSubmitBack = () => {
        navigate(AppRoutes.PROFILE_OPTIONS, {replace: true});
    };

    return (
        <>
            <ClockLoader id='spinner' color={"#009694"} loading={isLoading} size={100}/>
            <MenuBar/>
            <div className='login-container'>
            <div className="flex-item-left-catalog">
                    <Fab variant="extended" color="neutral" aria-label="add"
                        onClick={handleSubmitBack} style={{float:"left"}} className="hooverButtonRed">
                        <ArrowBack sx={{mr: 1}} className="hooverButtonRedsvg"/>
                        {intl.formatMessage({id: "button_back"})}
                    </Fab>
                </div>
            <br />
            <br />
                <div className='title-login'>{intl.formatMessage({id: "login_title"})}</div>
                <div className='subtitle-login'>{intl.formatMessage({id: "login_subtitle"})}</div>
                <br/>
                <br/>
                <Formik
                    initialValues={{
                        Pws_Tip_Identificacion: '',
                        Pws_Identificacion: '',
                        Pws_Fec_expe: '',
                    }}
                    onSubmit={handleSubmit}
                    // validationSchema={validationSchema}
                >
                    {({errors, isSubmitting}) => (
                        <>
                            <Form>
                                <Row>
                                    <section>
                                        <Field disabled={isLoading}
                                            as="select"
                                            className='form-control-50-login'
                                            name="Pws_Tip_Identificacion">
                                            <option>{intl.formatMessage({id: "placeholder_document_type"})}</option>
                                            {tiposIdentidad.map((item:any, key:any) => (
                                            <option key={key} value={item.s_codigo}>
                                                {item.s_descripcion}
                                            </option>
                                            ))}
                                        </Field>
                                        <div
                                            className='form-control-login-text'>{intl.formatMessage({id: "form_login_document"})}</div>
                                        <br/>
                                    </section>
                                    <section>
                                        <Field
                                            disabled={isLoading}
                                            className='form-control-50-login'
                                            id="Pws_Identificacion"
                                            name="Pws_Identificacion"
                                            type="text"
                                            placeholder={errors.Pws_Identificacion ? 'Numero de Documento' : intl.formatMessage({id: "placeholder_document_number"})}
                                        />
                                    </section>
                                    <section>
                                        <div
                                            className='form-control-login-text2'>{"Fecha de expedición"}</div>
                                        <br />
                                        <Field id={"Pws_Fec_expe"}
                                        name={"Pws_Fec_expe"}
                                        type={"date"}
                                        className= 'form-control-50-login'/>
                                    </section>
                                    <section>
                                        <Fab className={"btn-login-inactive"} aria-label="add" disabled={isSubmitting}  style={{float:'left',margin:'15px 0px 0px 0px', backgroundColor:'transparent' }}
                                            type="submit">
                                            {intl.formatMessage({id: "button_continue"})}
                                        </Fab>
                                    </section>
                                </Row>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </>
    )
}
