import React, { useEffect, useState } from 'react';
import './LoginPagare.scss';
import { WLOCreditAPIService } from '../../services';
import { useSignContext } from '../../hooks/useSignContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { decodeToken } from 'react-jwt';
import { stringify } from 'querystring';
import Moment from 'moment';
import { AppRoutes } from '../../Router';


export const LoginPagare = () => {
  const [codeDigits, setCodeDigits] = useState(['', '', '', '', '', '']);
  const [isSucessful, setIsSucessful] = React.useState(false);
  const [errorCode, setErrorCode] = useState<boolean>(false);
  const { sign, setSign } = useSignContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [counter, setCounter] = useState(300);
  const [token, setToken] = useState<string>('')

  useEffect(()=>{
      let valorBusqueda= sessionStorage.getItem('token')!      
      setToken(valorBusqueda);
    },[]);

    useEffect(() => {
      if (counter > 0) {
        const timer = setInterval(() => {
          setCounter((prevCounter) => prevCounter - 1);
        }, 1000);
  
        return () => clearInterval(timer);
      }
    }, [counter]);

  const handleCodeDigitChange = (index:any, value:any) => {
    if (/^[0-9]?$/.test(value)) {
      const newCodeDigits = [...codeDigits];
      newCodeDigits[index] = value;
      setCodeDigits(newCodeDigits);

      if (value && index < 5) {
        const nextInput = document.querySelector(`input[data-index="${index + 1}"]`) as HTMLInputElement | null;
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const clickVerifyCode = async (e:any) => {
    console.log(token);
    
    let iden = sessionStorage.getItem('identificacion')  
    let tipIden = sessionStorage.getItem('tipoidentificacion')    

    try {
        const codeStringValue = codeDigits.join('');
        if (codeStringValue.length < 6) return;
        const data = {
            codes: codeStringValue,
            token,
            identificacion: iden,
            tipoIdentificacion: tipIden,
        };
        console.log(data);
        
        const response = await WLOCreditAPIService.verifyCodeRegOtp(data);
        console.log(response);
        if(response.message == "Has firmado correctamente"){
          setErrorCode(false);
          setIsSucessful(true);
          navigate(AppRoutes.SOLCLIENTESPAGARE, {replace: true});

        }else{
          setErrorCode(false);
        }
    } catch (error: any) {
        const { errors } = error;
        console.log(errors);
        if (errors?.invalidCode || errors?.invalidToken) {
            setErrorCode(true);
        }
    }
};

    const ReenviarOtp = async (e:any)=>{
        e.preventDefault();
        let persona = sessionStorage.getItem('person')  
        let numSol = sessionStorage.getItem('num_solicitud')    
        let iden = sessionStorage.getItem('identificacion')  
        let tipIden = sessionStorage.getItem('tipoidentificacion')  
        let correo = sessionStorage.getItem('correo')  
        let tel = sessionStorage.getItem('tel')  
        
        const dataDefault = {
            Pws_Num_solicitud: numSol, 
            Pws_Identificacion: iden,
            Pws_Tip_Identificacion: tipIden,
            Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
            Pws_estado_resotp: "1",
            Pws_Tip_val: "1",
            email: correo,
            Pws_phone: tel,
        };
        (async () => {
            const regOTP = await WLOCreditAPIService.sendOTP(dataDefault);
            const {payload} = regOTP;
            const {token: tokenValue} = payload;
            setToken(tokenValue);
    
        })();
    }

  return (
    <div className="loginPagare-container">
        <div className='contenido'>
            <h1>Al ingresar el codigo, aceptas ser consultado en  listas restrictivas y sagrilaft.</h1>
            <p>Por favor inserte aquí</p>
            {codeDigits.map((digit, index) => (
                <input
                key={index}
                type="text"
                value={digit}
                onChange={(e) => handleCodeDigitChange(index, e.target.value)}
                onKeyUp={(e) => {
                    if (e.key === 'Backspace' && index > 0) {
                        const prevInput = document.querySelector(`input[data-index="${index - 1}"]`) as HTMLInputElement | null;
                        if (prevInput) {
                        prevInput.focus();
                        }
                    }
                }}
                data-index={index}
                maxLength={1}
                autoFocus={index === 0}
                className="code-input"
                />
            ))}
            <p>
              {counter > 0 ? `Faltan ${counter} segundos para poder enviar de nuevo el código` : 'Si no recibió el token, por favor haga click en el botón de reenviar OTP'}
            </p>
            <button type="submit" onClick={ReenviarOtp} className="continue-button">
                Reenviar OTP
            </button>
            <button type="submit" onClick={clickVerifyCode} className="continue-button">
                Continuar
            </button>
        </div>
    </div>
  );
};
