import { StringSchema } from "yup";
import {English, SpanishLatam} from "./languages";

export interface AppStrings {
    continue: string;
    button_continue: string;
    button_send_mail: string;
    button_calculate_quote: string;
    button_back: string;
    button_next: string;
    button_answer: string;
    button_yes: string;
    button_no: string;
    button_cancel: string;
    button_add: string;
    button_step_credit_information: string;
    button_step_person_information: string;
    button_step_finance_information: string;
    button_step_reference_information: string;
    login_title: string;
    login_subtitle: string;
    form_login_document: string;
    form_login_password: string;
    form_login_lost_password: string;
    form_credit_information_title: string;
    form_credit_information_subtitle: string;
    form_credit_information_selection: string;
    form_credit_information_note: string;
    form_credit_information_promotion_title: string;
    form_credit_information_promotion_subtitle: string;
    form_applicant_information_title: string;
    form_financial_information_title: string;
    form_financial_operations_title: string;
    form_financial_information_subtitle: string;
    form_financial_main_income: string;
    form_financial_other_income: string;
    form_financial_other_income_placeholder: string;
    form_financial_expenditure_value: string;
    form_financial_applicant_properties: string;
    form_financial_income_tax_filer: string;
    form_financial_foreign_currency: string;
    form_financial_foreign_currency_which: string;
    form_financial_currency: string;
    form_financial_bill_foreign_currency: string;
    form_financial_bank_foreign_currency: string;
    form_financial_number_foreign_currency: string;
    form_financial_country_foreign_currency: string;
    form_financial_city_foreign_currency: string;
    form_financial_pep_title: string;
    form_financial_pep_subtitle: string;
    form_documents_information_title: string;
    option_credit: string;
    initial_quote_value: string;
    value_to_finance: string;
    value_to_request: string;
    value_of_quote: string;
    term: string;
    placeholder_document_type: string;
    placeholder_occupation_type: string;
    placeholder_identification_type: string;
    placeholder_identification: string;
    placeholder_document_number: string;
    placeholder_field_name: string;
    placeholder_phone_1: string;
    placeholder_phone_2: string;
    placeholder_mail: string;
    placeholder_password: string;
    placeholder_mail_confirmation: string;
    placeholder_dropdown: string;
    placeholder_initial_quote_text: string;
    placeholder_initial_quote_symbol: string;
    placeholder_field_names: string;
    placeholder_field_last_names: string;
    placeholder_expedition_date: string;
    placeholder_expedition_place: string;
    placeholder_date_birth: string;
    placeholder_marital_status: string;
    placeholder_company_address: string;
    placeholder_direct_boss: string;
    placeholder_job_that_performs: string;
    placeholder_labor_old: string;
    placeholder_type_of_contract: string;
    placeholder_date_of_admission: string;
    placeholder_company_name: string;
    placeholder_spouse: string;
    placeholder_educational_level: string;
    placeholder_type_of_housing: string;
    placeholder_number_of_people_in_charge: string;
    placeholder_gender: string;
    placeholder_social_stratum: string;
    title_confirm_your_profile_to_continue: string;
    welcome: string;
    motorcycles: string;
    why_your_cred: string;
    loyalty: string;
    who_accredits_motorcycle: string;

    credit_manager: string;
    admin: string;
    seller: string;
    self_management: string;
    how_can_we_help_you_today: string;
    credit_applications: string;
    manage_credit: string;
    change_of_password: string;
    choose_the_one_of_your_preference: string;
    it_is_calculated_automatically: string;
    peps_question_1: string;
    peps_question_2: string;
    peps_question_3: string;
    peps_question_4: string;
    peps_question_5: string;
    relationship: string;
    identification_card: string;
    rental_value: string;
    describe: string;
    placeholder_mobile_phone_1: string;
    placeholder_mobile_phone_2: string;
    residence_address: string;
    department: string;
    gender: string;
    enter: string;
    full_name_spouse: string;
    identification_spouse: string;
    spouse_data: string;
    add_reference: string;
    english: string;
    spanish: string;
    languages: string;
    toast_validating: string;
    toast_processing: string;
    toast_charging: string;
    button_process: string;
    do_search_catalog: string;
    toast_failed_identity_validation: string;
    client_successfully_credit_application: string;
    client_successfully_credit_application_note: string;
    button_go_to_init: string;
    nro_quotes: string;
    attach_documents: string;
    required_documentation: string;
    required_documentation_note_1: string;
    required_documentation_note_2: string;
}

export const messages: { [lang: string]: AppStrings } = {
    en: English,
    es: SpanishLatam,
};