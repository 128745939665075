import React, { useState, useEffect, useMemo } from 'react';
import { NewHeader } from '../../components/shared/Header';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import { Palate } from '../../components/shared/Palate';
import { Popup } from '../../components/shared/Popup';
import { AppRoutes } from '../../Router';
import { AdminService } from '../../services';
import './datos.scss';
export const Datos = () => {
  const [colorPalate, setcolorPalate] = useState([
    {
      bg: '#828282',
      br: '1px solid #828282',
      name: 'secondary'
    },
    {
      bg: '#00908E',
      br: '1px solid #00908E',
      name: 'primary'
    },
    {
      bg: '#303030',
      br: '1px solid #303030',
      name: 'tartiary'
    },
    {
      bg: '#ffffff',
      br: '1px solid #000000',
      name: 'fourth'
    },
  ]);
  const [color, setColor] = useState(false);
  const [one,setone] = useState(true);
  useEffect(()=>{
      if(one){
          AdminService.getColors().then((valor)=>{
          document.documentElement.style.setProperty('--primary-color', valor.payload?.primary);
          document.documentElement.style.setProperty('--secondary-color', valor.payload?.secondary);
          document.documentElement.style.setProperty('--tartiary-color', valor.payload?.tartiary);
          document.documentElement.style.setProperty('--fourth-color', valor.payload?.fourth);
          setcolorPalate([
            {
              bg: valor.payload?.secondary,
              br: '1px solid #828282',
              name: 'secondary'
            },
            {
              bg: valor.payload?.primary,
              br: '1px solid #00908E',
              name: 'primary'
            },
            {
              bg: valor.payload?.tartiary,
              br: '1px solid #303030',
              name: 'tartiary'
            },
            {
              bg: valor.payload?.fourth,
              br: '1px solid #000000',
              name: 'fourth'
            },
          ])
          })
          setone(false)
      }
  },[
      one 
  ])

  return (
    <>
        {
          color
          ?<Popup setfunc={setColor} Body={<Palate palate={colorPalate} setFunc={setColor} />} />
          :<></>
        }
        <ResponsiveAppBar/>
        <NewHeader next={`none`}  backReturn={AppRoutes.MENU_USER}/>
        <h4 className="redhead">Administración de imagen corporativa</h4>
        <div className="datos">
            <div className="first-part">
              <img src="img/corporative.png" alt="corporativa" />
            </div>
            <div className="second-part">
              <div className="upper-part">
                <div className="left-side">
                  <img src="img/logo.png" alt="logo" />
                  <div className="logotext">
                    <p>foto actual</p>
                    <h6>Editar</h6>
                  </div>
                </div>
                <div className="right-side">
                  <p>Nombre de la compañia</p>
                  <p>Dirección de la compañía</p>
                  <p>Teléfono fijo: 4207914</p>
                  <p>Teléfono móvil: 3173454556</p>
                  <p>Correo: ejemplo@gmail.com</p>
                  <h6>Editar información </h6>
                </div>
              </div>
              <div className="lower-part">
                <div className="upside">
                  {
                    colorPalate.map((palate, i)=> {
                      return(
                        <div key={i} className="box" style={{background: palate.bg, border: palate.br}}></div>
                      )
                    })
                  }
                </div>
                <div className="downside">
                  <p>Paleta de colores</p>
                  <h4 onClick={()=>setColor(true)} >Editar</h4>
                </div>
              </div>
            </div>
        </div>
    </>
  )
}
