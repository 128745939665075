import React, { useEffect, useState } from 'react';
import './dcrt.scss';
import { ArrowBack, ArrowForward, CleaningServices, NoteAltTwoTone, Search } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import { Switch } from "@mui/material";
import { FUllPill } from '../Pill/Pill';
import { useNavigate, useLocation } from 'react-router-dom';
import { Doc, Person, File, Edit, Document, Check, Documentos, Documentosred, GreenCheck } from '../../../assets/icons/component';
import { typeIdentification, estractos, cities, Ocupaciones } from '../../../__mocks__/data';
import { AppRoutes } from "../../../Router";
import { WLOCreditAPIService, AdminService } from '../../../services';
import { WloCreditoService } from '../../../types';
import toast from 'react-hot-toast';
import { Field, Form, Formik } from "formik";
import Moment from 'moment';
import { useIntl } from "react-intl";
import { useLoginContext } from "../../../hooks/useLoginContext";
import ParseNumberThousand from "../../shared/Utilities/ParseNumberThousand";
import _ from "lodash";
import { useRequestContext } from '../../../hooks/useRequestContext';
import ClockLoader from "react-spinners/ClockLoader";
import { FormSpouseCodeudor } from '../../Steps/ApplicantInfo/ModalSpouse/FormSpouseCodeudor';
import { ModalReferencesCodeudor } from '../../Steps/DocumentsInformation/ModalReferences/ModalReferenceCodeudores';
import NumberFormat from 'react-number-format';

export const Box2 = (valorPersonal?: any) => {
    const { person } = valorPersonal.valorPersonal;
    const { dataConyuge } = valorPersonal.valorPersonal;
    const { request, setRequest } = useRequestContext();
    const [persona, DatoPersona] = React.useState({} as any);
    const [update, setUpdate] = React.useState(false);
    const [NoReadCedula, setReadCedula] = React.useState(true);
    const intl = useIntl();
    const [one] = React.useState(false)
    const [ciudades, setCiudades] = React.useState(cities as any);
    const [ciudades2, setCiudades2] = React.useState(cities as any);
    const [departamentos, setDepartamentos] = React.useState(cities as any)
    const [allCities, setAllCiudades] = React.useState(cities as any);
    const [EstadosCivil, setEstadosCivil] = useState([] as any);
    const [tipovivi, setTipoVivienda] = useState([]);
    const [NivelEsti, setNivelEstudio] = useState([]);
    const [TipoContrato, setAllTipoContrato] = useState([] as any);
    const [allOcupaciones, SetAllOcupaciones] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const handleSelectChange = (event: any) => {
        setSelectedOption(event.target.value)
        valorPersonal.valorPersonal.person.Pws_Estado_Civil = event.target.value
        if (event.target.value === "1" || event.target.value === "3") {
            setModal(!modal);
        }
    };


    useEffect(() => {
        AdminService.getAllCiudades().then((Datos: any) => {
            if (parseInt(Datos.payload.data.ListCiudad.Datos_Ciudad[0].s_codigo) > 0) {
                setAllCiudades(Datos.payload.data)
            }
        })
        getAlldata()
    }, [one])
    const toggle = () => setModal(!modal);
    const getAlldata = async () => {

        const estadosCvil = await AdminService.getEstadosCiviles();
        if (estadosCvil.payload.data.listEstCivil.Datos_EstCivil.length > 0 &&
            estadosCvil.payload.data.listEstCivil.Datos_EstCivil[0].s_codigo > 0) {
            setEstadosCivil(estadosCvil.payload.data.listEstCivil.Datos_EstCivil)
        } else {
            let estadocivil = [
                { s_codigo: "1", s_descripcion: "CASADO" },
                { s_codigo: "2", s_descripcion: "SOLTERO" },
                { s_codigo: "3", s_descripcion: "UNION LIBRE" },
                { s_codigo: "4", s_descripcion: "VIUDO" },
                { s_codigo: "5", s_descripcion: "SEPARADO" }
            ]
            setEstadosCivil(estadocivil)
        }
        const tipovivienda = await AdminService.gettipovivienda();
        if (tipovivienda.payload.data.tip_viv.datos_tip_vivi.length > 0) {
            setTipoVivienda(tipovivienda.payload.data.tip_viv.datos_tip_vivi)
        }
        const nivelEstudi = await AdminService.getNivelEstudio();
        if (nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio.length > 0) {
            setNivelEstudio(nivelEstudi.payload.data.listTipoNivEstudio.Datos_TiposNivelEstudio)
        }
        const tipocon = await AdminService.getTipoContrato();
        if (tipocon.payload.data.listTipoContr.Datos_TipoContrato.length > 0 &&
            parseInt(tipocon.payload.data.listTipoContr.Datos_TipoContrato[0].s_codigo) > 0) {
            setAllTipoContrato(tipocon.payload.data.listTipoContr.Datos_TipoContrato)
        } else {
            let tipContrato = [
                { s_codigo: "1", s_descripcion: "INDEFINIDO" },
                { s_codigo: "2", s_descripcion: "TERMINO FIJO" },
                { s_codigo: "3", s_descripcion: "N/A" },
                { s_codigo: "4", s_descripcion: "OBRA O LABOR" },
                { s_codigo: "5", s_descripcion: "TEMPORAL" }
            ]
            setAllTipoContrato(tipContrato)
        }


        const ocupaciones = await AdminService.getOcupaciones();
        if (ocupaciones.payload.data.ListOcup.Datos_Ocupaciones.length > 0) {
            SetAllOcupaciones(ocupaciones.payload.data.ListOcup.Datos_Ocupaciones)
        }
    }
    useEffect(() => {
        if (request.direcciones) {
            valorPersonal.valorPersonal.person.Pws_Direccion_res = request.direcciones.road + " " + request.direcciones.mainno + " " + request.direcciones.suffix + " " + request.direcciones.secondaryno + " " + request.direcciones.suffix2 + " " + request.direcciones.complementaryno + " " + request.direcciones.Complement + " " + request.direcciones.complementaryno2 + " " + request.direcciones.Complement3 + " " + request.direcciones.complementaryno3 + " " + request.direcciones.Neighborhood
            valorPersonal.valorPersonal.person.Pws_Direccion_res = request.direcciones.road + " " + request.direcciones.mainno + " " + request.direcciones.suffix + " " + request.direcciones.secondaryno + " " + request.direcciones.suffix2 + " " + request.direcciones.complementaryno + " " + request.direcciones.Complement + " " + request.direcciones.complementaryno2 + " " + request.direcciones.Complement3 + " " + request.direcciones.complementaryno3 + " " + request.direcciones.Neighborhood
        }
        if (request.dataModifcable) {
            if (request.dataModifcable.idUser) {
                setReadCedula(false)
            }
        }

    }, [request, setRequest])
    const handleSearch = async () => {
        setIsLoading(true);
        if (!_.isEmpty(valorPersonal.valorPersonal.person.Pws_Tip_Identificacion!) || !_.isEmpty(valorPersonal.valorPersonal.person.Pws_Identificacion!)) {
            if (valorPersonal.valorPersonal.person.Pws_Identificacion.length < 5) {
                toast.error('Debe indicar un Número de Identificación entre 5 y 10 caracteres');
            } else {
                try {
                    const person2 = await WLOCreditAPIService.consultaSolicitudCygnus({
                        Pws_Tip_Identificacion: valorPersonal.valorPersonal.person.Pws_Tip_Identificacion!,
                        Pws_Identificacion: valorPersonal.valorPersonal.person.Pws_Identificacion!
                    });
                    if (!_.isEmpty(person2.payload)) {
                        if (person2.payload.result === "1") {
                            person.Pws_Apellidos1 = person2.payload.data.datos_persona.Datos_persona[0].Pws_Apellidos1
                            person.Pws_Apellidos2 = person2.payload.data.datos_persona.Datos_persona[0].Pws_Apellidos2
                            person.Pws_Tip_person = person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_person
                            person.Pws_Nombres = person2.payload.data.datos_persona.Datos_persona[0].Pws_Nombres
                            person.Pws_Tip_Perfil = person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_Perfil
                            person.Pws_Telefono1 = person2.payload.data.datos_persona.Datos_persona[0].Pws_Telefono1
                            person.Pws_Telefono2 = person2.payload.data.datos_persona.Datos_persona[0].Pws_Telefono2
                            person.Pws_Correo = person2.payload.data.datos_persona.Datos_persona[0].Pws_Correo
                            person.Pws_Tip_ocupa = person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_ocupa
                            person.Pws_Fec_expe = person2.payload.data.datos_persona.Datos_persona[0].Pws_Fec_expe.split("-00")[0]
                            person.Pws_Lugar_exp = person2.payload.data.datos_persona.Datos_persona[0].Pws_Lugar_exp == 0 ? "" : person2.payload.data.datos_persona.Datos_persona[0].Pws_Lugar_exp
                            person.Pws_Fec_nacime = person2.payload.data.datos_persona.Datos_persona[0].Pws_Fec_nacime.split("-00")[0]
                            person.Pws_Estado_Civil = person2.payload.data.datos_persona.Datos_persona[0].Pws_Estado_Civil
                            person.Pws_Direccion_res = person2.payload.data.datos_persona.Datos_persona[0].Pws_Direccion_res
                            person.Pws_Departamento = person2.payload.data.datos_persona.Datos_persona[0].Pws_Departamento
                            person.Pws_Ciudad_res = person2.payload.data.datos_persona.Datos_persona[0].Pws_Ciudad_res
                            person.Pws_Genero = person2.payload.data.datos_persona.Datos_persona[0].Pws_Genero
                            person.Pws_Estrato_per = person2.payload.data.datos_persona.Datos_persona[0].Pws_Estrato_per
                            person.Pws_Per_cargo = person2.payload.data.datos_persona.Datos_persona[0].Pws_Per_cargo
                            person.Pws_Tip_vivienda = person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_vivienda
                            person.Pws_Niv_estudio = person2.payload.data.datos_persona.Datos_persona[0].Pws_Niv_estudio
                            person.Pws_Nom_empre = person2.payload.data.datos_persona.Datos_persona[0].Pws_Nom_empre
                            person.Pws_fec_ingemp = person2.payload.data.datos_persona.Datos_persona[0].Pws_fec_ingemp.split("-00")[0]
                            person.Pws_Fideliza = person2.payload.data.datos_persona.Datos_persona[0].Pws_Fideliza
                            person.Pws_Tip_contra = person2.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra
                            person.Pws_Ant_labo = person2.payload.data.datos_persona.Datos_persona[0].Pws_Ant_labo
                            person.Pws_Car_emp = person2.payload.data.datos_persona.Datos_persona[0].Pws_Car_emp
                            person.Pws_Nom_Jefedi = person2.payload.data.datos_persona.Datos_persona[0].Pws_Nom_Jefedi
                            person.Pws_Direc_emp = person2.payload.data.datos_persona.Datos_persona[0].Pws_Direc_emp
                            person.Pws_Ciud_emp = person2.payload.data.datos_persona.Datos_persona[0].Pws_Ciud_emp
                            person.Pws_tel_emp1 = person2.payload.data.datos_persona.Datos_persona[0].Pws_tel_emp1
                            person.Pws_tel_emp2 = person2.payload.data.datos_persona.Datos_persona[0].Pws_tel_emp2
                            setReadCedula(false)
                        } else {
                            const personANI = await WLOCreditAPIService.consultaSolicitudANI({
                                Pws_Tip_Identificacion: valorPersonal.valorPersonal.person.Pws_Tip_Identificacion!,
                                Pws_Identificacion: valorPersonal.valorPersonal.person.Pws_Identificacion!
                            });
                            const fecha = personANI.fechaExpedicion.split('/');
                            person.Pws_Apellidos1 = personANI.primerApellido
                            person.Pws_Apellidos2 = personANI.segundoApellido
                            person.Pws_Tip_person = "N"
                            person.Pws_Nombres = personANI.primerNombre + " " + personANI.segundoNombre
                            person.Pws_Telefono1 = ""
                            person.Pws_Telefono2 = ""
                            person.Pws_Correo = ""
                            person.Pws_Tip_ocupa = ""
                            person.Pws_Fec_expe = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
                            person.Pws_Lugar_exp= _.filter( allCities.ListCiudad.Datos_Ciudad, 
                                { s_nivel: '3',s_nombre: personANI.municipioExpedicion}).length>0?
                                _.filter( allCities.ListCiudad.Datos_Ciudad, 
                                {s_nivel: '3', s_nombre: personANI.municipioExpedicion})[0].s_codigo:""
                            person.Pws_Fec_nacime = ""
                            person.Pws_Estado_Civil = ""
                            person.Pws_Direccion_res = ""
                            person.Pws_Departamento = ""
                            person.Pws_Ciudad_res = ""
                            person.Pws_Genero = ""
                            person.Pws_Estrato_per = ""
                            person.Pws_Per_cargo = ""
                            person.Pws_Tip_vivienda = ""
                            person.Pws_Niv_estudio = ""
                            person.Pws_Nom_empre = ""
                            person.Pws_fec_ingemp = ""
                            person.Pws_Fideliza = ""
                            person.Pws_Tip_contra = ""
                            person.Pws_Ant_labo = ""
                            person.Pws_Car_emp = ""
                            person.Pws_Nom_Jefedi = ""
                            person.Pws_Direc_emp = ""
                            person.Pws_Ciud_emp = ""
                            person.Pws_tel_emp1 = ""
                            person.Pws_tel_emp2 = ""
                            setReadCedula(false)
                        }
                    }
                } catch (e) {
                    const error = e as any;
                    console.log(error)
                }
            }
        }
        setIsLoading(false);
    }
    useEffect(() => {
        if (allCities) {

            let citiesDepent = {
                "ListCiudad": {
                    "Datos_Ciudad": [] as any
                }
            }
            citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '2' });
            setDepartamentos(citiesDepent)
        }
    }, [allCities])

    const handleCities = (e: { target: { value: string; }; }) => {
        let citiesDepent = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3', s_ubicacion: e.target.value });
        setCiudades(citiesDepent);
        console.log(ciudades)
    }

    useEffect(() => {
        const personainfo = valorPersonal.valorPersonal.person
        const personaRequest = {
            Pws_Identificacion: "",
            Pws_Tip_Identificacion: "",
            Pws_Tip_Estado: "2", // Valor constante para confirmación de información.
            Pws_Tip_Perfil: "",
            Pws_Tip_person: "",
            Pws_Tip_ocupa: "",
            Pws_Nombres: "",
            Pws_Apellidos1: "",
            Pws_Apellidos2: "",
            Pws_Telefono1: "",
            Pws_Telefono2: "",
            Pws_Correo: "",
            Pws_Fec_expe: "",
            Pws_Lugar_exp: "",
            Pws_Fec_nacime: "",
            Pws_Estado_Civil: "",
            Pws_Direccion_res: "",
            Pws_Departamento: "",
            Pws_Ciudad_res: "",
            Pws_Genero: "",
            Pws_Estrato_per: "",
            Pws_Per_cargo: "",
            Pws_Tip_vivienda: "",
            Pws_Niv_estudio: "",
            Pws_Nom_empre: "",
            Pws_fec_ingemp: "",
            Pws_Fideliza: "",
            Pws_Tip_contra: "",
            Pws_Ant_labo: "",
            Pws_Car_emp: "",
            Pws_Nom_Jefedi: "",
            Pws_Direc_emp: "",
            Pws_Ciud_emp: "",
            Pws_tel_emp1: "",
            Pws_tel_emp2: "",
        }

        const infoUpdatePerson = { ...personaRequest, Pws_i_codigo: personainfo.Pws_i_codigo };
        DatoPersona(infoUpdatePerson)
        if (!valorPersonal.valorPersonal.update) {
            let citiesDepent = {
                "ListCiudad": {
                    "Datos_Ciudad": [] as any
                }
            }
            citiesDepent.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3', s_ubicacion: persona.Pws_Departamento });
            if (citiesDepent) {
                setCiudades(citiesDepent);
            }
        }

        let citiesDepent2 = {
            "ListCiudad": {
                "Datos_Ciudad": [] as any
            }
        }
        citiesDepent2.ListCiudad.Datos_Ciudad = _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3' });
        setCiudades2(citiesDepent2);
        setUpdate(valorPersonal.valorPersonal.update)
    }, [valorPersonal]);


    const cambios = (evento: any) => {
        var dateStart = new Date((evento.target.value).replace("-", "/"));
        var dateEnd = new Date();
        if (dateStart.getTime() > dateEnd.getTime()) {
            toast("La fecha de ingreso no debe ser mayor a la fecha actual")
        } else {
            var one_day = 1000 * 60 * 60 * 24;
            let result
            //result = Math.ceil(((dateEnd.getTime()-dateStart.getTime())/(one_day))/365)

            var results = dateEnd.getFullYear() - dateStart.getFullYear()
            let diferencia = dateEnd.getTime() - dateStart.getTime();
            let diasDeDiferencia = diferencia / 1000 / 60 / 60 / 24;
            if (diasDeDiferencia > 365 && dateStart.getMonth() <= dateEnd.getMonth() && dateStart.getDate() <= dateEnd.getDate()) {
                valorPersonal.valorPersonal.person.Pws_Ant_labo = results

            } else {
                valorPersonal.valorPersonal.person.Pws_Ant_labo = results - 1
            }
        }
    }

    const cambiarUpdat = () => {
        valorPersonal.valorPersonal.setUpdate(true)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.checked)
        if (event.target.checked == false) {
            valorPersonal.valorPersonal.person.Pws_Fideliza = "0"
        } else {
            valorPersonal.valorPersonal.person.Pws_Fideliza = "1"
        }
        console.log(valorPersonal.valorPersonal.person)
    };
    return (

        <div className="box">
            <ClockLoader id='spinner' color={"#d72222"} loading={isLoading} size={100} />
            <ul className="title">
                <li>1.</li>
                <li>Información personal </li>
                <Person />
                {valorPersonal.valorPersonal.arreglo.length > 3 ? <>
                    <div onClick={cambiarUpdat}>

                        <Edit />
                    </div>
                </> : <>
                </>}

            </ul>
            <div className="sub-box">

                <ul>
                    <li>
                        <label>¿Quién acredita la moto?</label>
                        {
                            update ? <>
                                <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Tip_person"
                                >
                                    <option>{intl.formatMessage({ id: "placeholder_dropdown" })}</option>
                                    <option value={'N'}>{'Natural'}</option>
                                    {/* <option value={'J'}>{'Juridico'}</option> */}
                                </Field>
                            </> : <>
                                <p>{person ? (person.Pws_Tip_person === "N" ? "Natural" : "Juridico") : ""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Tipo de ocupación</label>
                        {
                            update ? <>
                                <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Tip_ocupa">
                                    <option>{intl.formatMessage({ id: "placeholder_dropdown" })}</option>
                                    {allOcupaciones && allOcupaciones.map((item: any, key) => {
                                        return (<option value={item.s_codigo}>{item.s_descripcion}</option>)
                                    })}
                                </Field>
                            </> : <>
                                <p>
                                    {Ocupaciones && Ocupaciones.map((item: any, key: number) => {
                                        if (Number(item.id) === Number(person.Pws_Tip_ocupa)) {
                                            return (item.name)
                                        } else {
                                            return ("")
                                        }
                                    })}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label> {intl.formatMessage({ id: "loyalty" })}</label>
                        <div className="col-3 toggle-app">
                            {intl.formatMessage({ id: "button_no" })}
                            <Switch
                                name="Pws_Fideliza"
                                type="checkbox"
                                value="Pws_Fideliza"
                                onChange={handleChange}
                            />
                            {intl.formatMessage({ id: "button_yes" })}
                        </div>
                    </li>
                </ul>
            </div>
            <div className="sub-box redBox">
                <h3>Información del solicitante</h3>
                <ul>
                    <li>
                        <label>Nombres</label>
                        {
                            update ? <>
                                <Field id={'Pws_Nombres'}
                                    className="form-know-you-item-input"
                                    name={'Pws_Nombres'}
                                    type={"text"}
                                    disabled />
                            </> : <>
                                <p>{person ? (person.Pws_Nombres ? person.Pws_Nombres : "") : ""}</p>
                            </>
                        }

                    </li>
                    <li>
                        <label>Apellidos</label>
                        {
                            update ? <>
                                <Field id={'Pws_Apellidos1'}
                                    className="form-know-you-item-input"
                                    name={'Pws_Apellidos1'}
                                    type={"text"}
                                    disabled />

                                <Field id={'Pws_Apellidos2'}
                                    style={{ marginTop: "5px" }}
                                    className="form-know-you-item-input"
                                    name={'Pws_Apellidos2'}
                                    type={"text"}
                                    disabled />
                            </> : <>
                                <p>{person ? (person.Pws_Apellidos1 ? person.Pws_Apellidos1 + " " + person.Pws_Apellidos2 : "") : ""} </p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Tipo de identificación</label>

                        {
                            update ? <>
                                <Field as="select"
                                    className="form-know-you-item-input"
                                    name="Pws_Tip_Identificacion">
                                    {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item, key) => {

                                        return (<option value={item.s_codigo}>{item.s_descripcion}</option>)

                                    })}
                                </Field>
                            </> : <>
                                <p> {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item, key) => {
                                    if (Number(item.s_codigo) === Number(person.Pws_Tip_Identificacion)) {
                                        return (item.s_descripcion)

                                    } else {

                                        return ("")
                                    }
                                })}</p>
                            </>
                        }

                    </li>
                    <li>
                        <label>Identificacion</label>
                        {
                            update ? <>
                                <Field id={'Pws_Identificacion'}
                                    className="form-know-you-item-input"
                                    name={'Pws_Identificacion'}
                                    type={"text"}
                                    onBlur={handleSearch} />
                            </> : <>
                                <p>{person ? (person.Pws_Identificacion ? person.Pws_Identificacion : "") : ""}</p>
                            </>
                        }

                    </li>
                    <li>
                        <label>Fecha de expedición</label>
                        {
                            update ? <>
                                <Field id={'Pws_Fec_expe'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Fec_expe'}
                                    type={"date"}
                                    placeholder={'MM/dd/YYYY'} />
                            </> : <>
                                <p>{person.Pws_Fec_expe ? person.Pws_Fec_expe : ""} </p>
                            </>
                        }


                    </li>
                    <li>
                        <label>Lugar de expedición</label>
                        {
                            update ? <>
                                <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Lugar_exp"
                                >
                                    {allCities && _.filter(allCities.ListCiudad.Datos_Ciudad).map((item: any, key: number) => {
                                        return (
                                                    <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                                )
                                    })}
                                    
                                </Field>

                            </> : <>
                                <p>{ciudades2 && ciudades2.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                    if (item.s_codigo === person.Pws_Lugar_exp) {
                                        return (item.s_nombre)

                                    } else {
                                        return ("")
                                    }
                                })}</p>
                            </>
                        }
                    </li>
                </ul>
                <ul>
                    <li>
                        <label>Fecha de nacimiento</label>
                        {
                            update ? <>
                                <Field id={'Pws_Fec_nacime'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Fec_nacime'}
                                    type={"date"}
                                    placeholder={'MM/dd/YYYY'} />
                            </> : <>
                                <p>{person.Pws_Fec_nacime ? person.Pws_Fec_nacime : ""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Estado civil</label>
                        {
                            update ? <>
                                <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Estado_Civil"
                                   // value={selectedOption}
                                    onChange={handleSelectChange}>
                                    <option value="">{intl.formatMessage({ id: "placeholder_dropdown" })}</option>
                                    {EstadosCivil && EstadosCivil.map((item: any, key: any) => {
                                        return (                              
                                        <option value={item.s_codigo} key={key}>{item.s_descripcion}</option>)

                                    })}
                                </Field>

                            </> : <>
                                <p>{EstadosCivil && EstadosCivil.map((item: any, key: number) => {
                                    if (Number(item.s_codigo) === Number(person.Pws_Estado_Civil)) {
                                        return (item.s_descripcion)
                                    } else {
                                        return ("")

                                    }

                                })} </p>
                            </>
                        }

                    </li>
                    <li>
                        <label>Dirección residencia</label>
                        {
                            update ? <>
                                <Field id={'Pws_Direccion_res'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Direccion_res'}
                                //  onFocus={()=>{
                                //     setRequest({
                                //         ...request,
                                //         valoresDireccion: {identificacion:person.Pws_Identificacion,
                                //             tipoindentificacion:person.Pws_Tip_Identificacion}
                                //     })
                                //     valorPersonal.valorPersonal.setPopup("open")
                                // }}
                                // readonly='readonly'
                                />
                            </> : <>
                                <p>{person ? person.Pws_Direccion_res : ""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Departamentos</label>
                        {
                            update ? <>
                                <Field as="select"
                                    disabled={NoReadCedula}
                                    className="form-know-you-item-input"
                                    name="Pws_Departamento"
                                    onClick={handleCities}
                                >
                                    <option>{intl.formatMessage({ id: "placeholder_dropdown" })}</option>
                                    {departamentos && departamentos.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                        return (
                                            <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                        )
                                    })}
                                </Field>

                            </> : <>
                                <p>{departamentos && departamentos.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                    if (item.s_codigo === person.Pws_Departamento) {
                                        return (item.s_nombre)

                                    } else {
                                        return ("")
                                    }
                                })}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Ciudad</label>
                        {
                            update ? <>
                                <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Ciudad_res"
                                >
                                    {ciudades && ciudades.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                        return (
                                            <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                        )
                                    })}
                                </Field>

                            </> : <>
                                <p>{allCities.ListCiudad.Datos_Ciudad && allCities.ListCiudad.Datos_Ciudad.map((item: any, key: number) => {
                                    if (Number(item.s_codigo) === Number(person.Pws_Ciudad_res)) {
                                        return (item.s_nombre)

                                    } else {
                                        return ("")
                                    }
                                })}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Teléfono móvil 1</label>
                        {
                            update ? <>
                                <Field id={'Pws_Telefono1'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Telefono1'}
                                />
                            </> : <>
                                <p>{person ? person.Pws_Telefono1 : ""} </p>
                            </>
                        }
                    </li>
                </ul>
                <ul>
                    <li>
                        <label>Teléfono móvil 2</label>
                        {
                            update ? <>
                                <Field id={'Pws_Telefono2'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Telefono2'}
                                />
                            </> : <>
                                <p>{person ? person.Pws_Telefono2 : ""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Email</label>
                        {
                            update ? <>
                                <Field id={'Pws_Correo'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Correo'}
                                />
                            </> : <>
                                <p>{person ? person.Pws_Correo : ""} </p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Género</label>
                        {
                            update ? <>
                                <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Genero">
                                    <option>{intl.formatMessage({ id: "placeholder_dropdown" })}</option>
                                    <option value={'F'}>{'Femenino'}</option>
                                    <option value={'M'}>{'Masculino'}</option>
                                </Field>
                            </> : <>
                                <p>{person ? (person.Pws_Genero === "F" ? "Femenino" : "Masculino") : ""}</p>
                            </>
                        }

                    </li>
                    <li>
                        <label>Estrato social</label>
                        {
                            update ? <>
                                <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Estrato_per">
                                    <option>{intl.formatMessage({ id: "placeholder_dropdown" })}</option>
                                    {estractos && estractos.WS_ESTRACTOS_SOCResult.estratos_soc.Datos_estratos_soc.map((item, key) => {
                                        return (<option value={item.i_codigo}>{item.c_descripcion}</option>)

                                    })}
                                </Field>
                            </> : <>
                                <p> {estractos && estractos.WS_ESTRACTOS_SOCResult.estratos_soc.Datos_estratos_soc.map((item, key: number) => {
                                    if (Number(item.i_codigo) === Number(person.Pws_Estrato_per)) {
                                        return (item.c_descripcion)
                                    } else {
                                        return ("")

                                    }
                                })} </p>
                            </>
                        }

                    </li>
                    <li>
                        <label>N° de personas a cargo</label>
                        {
                            update ? <>
                                <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Per_cargo">
                                    <option>{intl.formatMessage({ id: "placeholder_dropdown" })}</option>
                                    <option value={'0'}>{'0'}</option>
                                    <option value={'1'}>{'1'}</option>
                                    <option value={'2'}>{'2'}</option>
                                    <option value={'3'}>{'3'}</option>
                                    <option value={'4'}>{'4'}</option>
                                    <option value={'5'}>{'5'}</option>
                                    <option value={'6'}>{'6'}</option>
                                    <option value={'7'}>{'7'}</option>
                                    <option value={'8'}>{'8'}</option>
                                    <option value={'9'}>{'9'}</option>
                                    <option value={'10'}>{'10'}</option>
                                </Field>
                            </> : <>
                                <p>{person ? person.Pws_Per_cargo : ""}</p>
                            </>
                        }

                    </li>
                </ul>
                <ul>
                    <li>
                        <label>Tipo de vivienda </label>
                        {
                            update ? <>
                                <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Tip_vivienda">
                                    <option>{intl.formatMessage({ id: "placeholder_dropdown" })}</option>
                                    {tipovivi && tipovivi.map((item: any, key) => {

                                        return (<option value={item.i_codigo}>{item.c_descripcion}</option>)

                                    })}
                                </Field>
                            </> : <>
                                <p>{tipovivi && tipovivi.map((item: any, key: number) => {
                                    if (Number(item.i_codigo) === Number(person.Pws_Tip_vivienda)) {
                                        return (item.c_descripcion)
                                    } else {
                                        return ("")

                                    }
                                })}  </p>
                            </>
                        }

                    </li>
                    <li>
                        <label>Nivel de estudios </label>
                        {
                            update ? <>
                                <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Niv_estudio">
                                    <option>{intl.formatMessage({ id: "placeholder_dropdown" })}</option>
                                    {NivelEsti && NivelEsti.map((item: any, key) => {

                                        return (<option value={item.s_codigo}>{item.s_descripcion}</option>)

                                    })}
                                </Field>
                            </> : <>
                                <p> {NivelEsti && NivelEsti.map((item: any, key: number) => {
                                    if (Number(item.s_codigo) === Number(person.Pws_Niv_estudio)) {
                                        return (item.s_descripcion)
                                    } else {
                                        return ("")

                                    }
                                })}</p>
                            </>
                        }

                    </li>

                    <FormSpouseCodeudor dataConyuge={dataConyuge} values={person} show={modal} onHide={toggle} solicitud={valorPersonal.valorPersonal.arreglo[0]} />
                </ul>
            </div>
            <div className="sub-box redBox">
                <h3>Actividad del solicitante</h3>
                <ul>
                    <li>
                        <label>Nombre de la Compañia</label>
                        {
                            update ? <>
                                <Field id={'Pws_Nom_empre'}
                                    className="form-know-you-item-input"
                                    name={'Pws_Nom_empre'}
                                    disabled={NoReadCedula}
                                />
                            </> : <>
                                <p>{person ? person.Pws_Nom_empre : ""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Fecha de ingreso</label>
                        {
                            update ? <>
                                <Field id={'Pws_fec_ingemp'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_fec_ingemp'}
                                    type={"date"}
                                    onBlur={cambios}
                                    placeholder={'MM/dd/YYYY'} />
                            </> : <>
                                <p>{person.Pws_fec_ingemp ? new Date(person.Pws_fec_ingemp).toLocaleDateString() : ""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Tipo de contrato</label>
                        {
                            update ? <>
                                <Field as="select"
                                    disabled={NoReadCedula}
                                    name="Pws_Tip_contra">
                                    <option>{intl.formatMessage({ id: "placeholder_dropdown" })}</option>
                                    {TipoContrato && TipoContrato.map((item: any, key: any) => {

                                        return (<option value={item.s_codigo} key={key}>{item.s_descripcion}</option>)

                                    })}
                                </Field>
                            </> : <>
                                <p>{TipoContrato && TipoContrato.map((item: any, key: number) => {
                                    if (Number(item.s_codigo) === Number(person.Pws_Tip_contra)) {
                                        return (item.s_descripcion)
                                    } else {
                                        return ("")

                                    }
                                })} </p>
                            </>
                        }

                    </li>
                    <li>
                        <label>Antigüedad laboral (años)</label>
                        {
                            update ? <>
                                <Field id={'Pws_Ant_labo'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Ant_labo'}
                                />
                            </> : <>
                                <p>{person ? person.Pws_Ant_labo : ""} año </p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Cargo que desempeña</label>
                        {
                            update ? <>
                                <Field id={'Pws_Car_emp'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Car_emp'}
                                />
                            </> : <>
                                <p>{person ? person.Pws_Car_emp : ""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Jefe directo</label>
                        {
                            update ? <>
                                <Field id={'Pws_Nom_Jefedi'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Nom_Jefedi'}
                                />
                            </> : <>
                                <p>{person ? person.Pws_Nom_Jefedi : ""}</p>
                            </>
                        }
                    </li>
                </ul>
                <ul>
                    <li>
                        <label>Dirección de la empresa</label>
                        {
                            update ? <>
                                <Field id={'Pws_Direc_emp'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_Direc_emp'}
                                />
                            </> : <>
                                <p>{person ? person.Pws_Direc_emp : ""} </p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Ciudad</label>
                        {
                            update ? <>
                                <Field as="select"
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name="Pws_Ciud_emp"
                                >
                                    {_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3' }) && _.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3' }).map((item: any, key: number) => {
                                        return (
                                            <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                                        )
                                    })}
                                </Field>

                            </> : <>
                                <p>{_.filter(allCities.ListCiudad.Datos_Ciudad, { s_nivel: '3' }).map((item: any, key: number) => {
                                    if (item.s_codigo === person.Pws_Ciud_emp) {
                                        return (item.s_nombre)

                                    } else {
                                        return ("")
                                    }
                                })}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Teléfono 1</label>
                        {
                            update ? <>
                                <Field id={'Pws_tel_emp1'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_tel_emp1'}
                                />
                            </> : <>
                                <p>{person ? person.Pws_tel_emp1 : ""}</p>
                            </>
                        }
                    </li>
                    <li>
                        <label>Teléfono 2</label>
                        {
                            update ? <>
                                <Field id={'Pws_tel_emp2'}
                                    className="form-know-you-item-input"
                                    disabled={NoReadCedula}
                                    name={'Pws_tel_emp2'}
                                />
                            </> : <>
                                <p>{person ? person.Pws_tel_emp2 : ""} </p>
                            </>
                        }
                    </li>
                </ul>
            </div>
        </div>
    );
}
export const Box8 = (valorFinancie: any) => {
    const [option1, setoption1] = React.useState(false);
    const [option2, setoption2] = React.useState(false);
    const [option3, setoption3] = React.useState(false);
    const [option4, setoption4] = React.useState(false);
    const [option5, setoption5] = React.useState(false);

    const [option1s, setoption1s] = React.useState({} as any);
    const [option2s, setoption2s] = React.useState({} as any);
    const [option3s, setoption3s] = React.useState({} as any);
    const [option4s, setoption4s] = React.useState({} as any);
    const [option5s, setoption5s] = React.useState({} as any);
    useEffect(() => {
        if (valorFinancie.valorFinancie.datosdocuments.length > 0) {
            valorFinancie.valorFinancie.datosdocuments.forEach((element: any) => {
                if (Number(element.Pws_Doc_Tipo) == 1) {
                    setoption1(true)
                    setoption1s(element)
                } else if (Number(element.Pws_Doc_Tipo) == 2) {
                    setoption2(true)
                    setoption2s(element)

                } else if (Number(element.Pws_Doc_Tipo) == 3) {
                    setoption3(true)
                    setoption3s(element)

                }else if (Number(element.Pws_Doc_Tipo) == 4) {
                    setoption4(true)
                    setoption4s(element)

                }else if (Number(element.Pws_Doc_Tipo) == 12) {
                    setoption5(true)
                    setoption5s(element)

                }

            });
        }
        if (valorFinancie.valorFinancie.update && valorFinancie.valorFinancie.arreglo.length > 3) {
            setoption1(false)
            setoption3(false)
            setoption2(false)
            setoption4(false)
            setoption5(false)
        }
    }, [valorFinancie.valorFinancie])
    const handleFileInputChange = (e: any) => {
        const target = e.target
        if (target.files && target.files[0]) {
            const maxAllowedSize = 5 * 1024 * 1024;
            if (target.files[0].size > maxAllowedSize) {
                toast.error('Seleccione un archivo de tamaño máximo 5mb.');
                target.value = ''
            }
        }
        let file: any = '';
        file = e.target.files[0];
        const dataElement = target.closest("[data-id]");
        if (Number(dataElement.dataset.id) === 1) {
            setoption1(true)
        }
        if (Number(dataElement.dataset.id) === 2) {
            setoption2(true)
        }
        if (Number(dataElement.dataset.id) === 3) {
            setoption3(true)
        }
        if (Number(dataElement.dataset.id) === 4) {
            setoption4(true)
        }
        if (Number(dataElement.dataset.id) === 12) {
            setoption5(true)
        }

    };

    ;
    return (
        <div className="footer">
            <div className="footer-text">
                <h4>Adjuntar documentos</h4>
                <p><span>{`Documetación requerida`}</span>{`(Los documentos. Se permiten PDF, JPG Y PNG. Tamaño máximo de 5Mb) `}</p>
            </div>
            <div className="footer-body">
                <div className={option1 ? "document check" : "document"} onClick={(eve) => {
                    if (option1) {
                        if (option1s.Pws_Clave_doc) {
                            const link = document.createElement('a');
                            link.href = option1s.Pws_Clave_doc;
                            link.setAttribute(
                                'download',
                                option1s.Pws_Doc_nombre,
                            );
                            link.setAttribute("target", "_blank")
                            // Append to html link element page
                            document.body.appendChild(link);

                            // Start download
                            link.click();
                            link.remove();
                        } else {
                            eve.currentTarget.querySelector('input')?.click()
                        }

                    } else {
                        eve.currentTarget.querySelector('input')?.click()

                    }
                }}>
                    <input

                        data-id="1"
                        type="file"
                        name="file-input"
                        id="file-input"
                        className="file-input__input"

                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                        onChange={handleFileInputChange}
                    />
                    {option1 ? <><Check /></> : <><Document /></>}
                    <p>Copia de cedula</p>
                </div>
                <div className={option2 ? "document check" : "document"} onClick={(eve) => {
                    if (option2) {
                        if (option2s.Pws_Clave_doc) {

                            const link = document.createElement('a');
                            link.href = option2s.Pws_Clave_doc;
                            link.setAttribute(
                                'download',
                                option2s.Pws_Doc_nombre,
                            );
                            link.setAttribute("target", "_blank")
                            // Append to html link element page
                            document.body.appendChild(link);

                            // Start download
                            link.click();
                            link.remove();
                        } else {
                            eve.currentTarget.querySelector('input')?.click()

                        }
                    } else {
                        eve.currentTarget.querySelector('input')?.click()

                    }
                }}>
                    <input
                        data-id="2"
                        type="file"
                        name="file-input"
                        id="file-input"
                        className="file-input__input"

                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                        onChange={handleFileInputChange}
                    />
                    {option2 ? <><Check /></> : <><Document /></>}

                    <p>Recibo de servicio publico</p>
                </div>
                <div className={option3 ? "document check" : "document"} onClick={(eve) => {
                    if (option3) {
                        if (option3s.Pws_Clave_doc) {
                            const link = document.createElement('a');
                            link.href = option3s.Pws_Clave_doc;
                            link.setAttribute(
                                'download',
                                option3s.Pws_Doc_nombre,
                            );
                            link.setAttribute("target", "_blank")
                            // Append to html link element page
                            document.body.appendChild(link);

                            // Start download
                            link.click();
                            link.remove();
                        } else {
                            eve.currentTarget.querySelector('input')?.click()

                        }

                    } else {
                        eve.currentTarget.querySelector('input')?.click()

                    }
                }}>
                    <input
                        data-id="3"
                        type="file"
                        name="file-input"
                        id="file-input"
                        className="file-input__input"

                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                        onChange={handleFileInputChange}
                    />
                    {option3 ? <><Check /></> : <><Document /></>}
                    <p>Soportes de ingresos y/o garantías</p>
                </div>


                <div className={option4 ? "document check" : "document"} onClick={(eve) => {
                    if (option4) {
                        const usuario:any= sessionStorage.getItem("User");  
                        const user = JSON.parse(usuario);
                        const tipoPerfil = user.idProfile;
                        if (tipoPerfil != 4){
                            if (option4s.Pws_Clave_doc) {
                                const link = document.createElement('a');
                                link.href = option4s.Pws_Clave_doc;
                                link.setAttribute(
                                    'download',
                                    option4s.Pws_Doc_nombre,
                                );
                                link.setAttribute("target", "_blank")
                                // Append to html link element page
                                document.body.appendChild(link);
    
                                // Start download
                                link.click();
                                link.remove();
                            } else {
                               // eve.currentTarget.querySelector('input')?.click()
    
                            }
                        }
                    } else {
                        toast.error('No tiene autorizacion para descargar esto.');
                    }
                }}>
                    <input
                        data-id="4"
                        type="file"
                        name="file-input"
                        id="file-input"
                        className="file-input__input"

                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                        //onChange={handleFileInputChange}
                    />
                    {option4 ? <><Check /></> : <><Document /></>}

                    <p>Sagrilaft</p>
                </div>

                <div className={option5 ? "document check" : "document"} onClick={(eve) => {
                    if (option5) {
                        if (option5s.Pws_Clave_doc) {

                            const link = document.createElement('a');
                            link.href = option5s.Pws_Clave_doc;
                            link.setAttribute(
                                'download',
                                option5s.Pws_Doc_nombre,
                            );
                            link.setAttribute("target", "_blank")
                            // Append to html link element page
                            document.body.appendChild(link);

                            // Start download
                            link.click();
                            link.remove();
                        } else {
                            //eve.currentTarget.querySelector('input')?.click()

                        }
                    } else {
                       // eve.currentTarget.querySelector('input')?.click()

                    }
                }}>
                    <input
                        data-id="12"
                        type="file"
                        name="file-input"
                        id="file-input"
                        className="file-input__input"

                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                        //onChange={handleFileInputChange}
                    />
                    {option5 ? <><Check /></> : <><Document /></>}

                    <p>Documento Firmado</p>
                </div>
            </div>
        </div>
    )
}
export const Box3 = (valorFinancie: any) => {
    const { valorinic } = valorFinancie.valorFinancie
    const [values, valoresIncial] = React.useState({} as any);
    const [update, setUpdate] = React.useState(false);
    useEffect(() => {
        if (valorFinancie.valorFinancie.values) {
            const operFinancieras = {
                s_num_solicitud: valorFinancie.valorFinancie.datosIngr[0],
                s_identificacion: valorFinancie.valorFinancie.datosIngr[1],
                s_tipo_identificacion: valorFinancie.valorFinancie.datosIngr[2],
                s_ingreso_principal: valorFinancie.valorFinancie.values.Pws_Ingreso_Principal,
                s_otros_ingresos: valorFinancie.valorFinancie.values.Pws_otros_ingresos,
                s_otros_egresos: valorFinancie.valorFinancie.values.Pws_Otros_egresos,
                s_arriendo: valorFinancie.valorFinancie.values.Pws_Arriendo,
                s_concep_otr_ingre: valorFinancie.valorFinancie.values.Pws_Concep_otr_ingre,
                s_declarante_ren: valorFinancie.valorFinancie.values.Pws_Declarante_ren ? true : false,
                s_moneda_ext: valorFinancie.valorFinancie.values.Pws_Moneda_ext ? true : false,
                s_monext_oper: valorFinancie.valorFinancie.values.Pws_Monext_oper,
                s_tip_monext: valorFinancie.valorFinancie.values.Pws_Tip_monext,
                s_cuent_ext: valorFinancie.valorFinancie.values.Pws_Cuent_ext ? true : false,
                s_cuen_extban: valorFinancie.valorFinancie.values.Pws_Cuen_extban,
                s_cuen_extnum: valorFinancie.valorFinancie.values.Pws_Cuen_extnum,
                s_cuen_extpais: valorFinancie.valorFinancie.values.Pws_Cuen_extpais,
                s_cuen_extciudad: valorFinancie.valorFinancie.values.Pws_Cuen_extciudad,
                s_pep_recpublic: valorFinancie.valorFinancie.values.Pws_Pep_recpublic ? true : false,
                s_pep_poderpublic: valorFinancie.valorFinancie.values.Pws_Pep_poderpublic ? true : false,
                s_pep_reconpublic: valorFinancie.valorFinancie.values.Pws_Pep_Reconpublic ? true : false,
                s_pep_pubexpue: valorFinancie.valorFinancie.values.Pws_Pep_pubexpue ? true : false,
                s_pep_seggraconsa: valorFinancie.valorFinancie.values.Pws_Pep_seggraconsa ? true : false,
                s_pep_nompepseg: valorFinancie.valorFinancie.values.Pws_Pep_nompepseg,
                s_pep_paren: valorFinancie.valorFinancie.values.Pws_Pep_paren,
                s_pep_identif: valorFinancie.valorFinancie.values.Pws_Pep_Identif,
            }
            valoresIncial(operFinancieras)
        }
        setUpdate(valorFinancie.valorFinancie.update)

    }, [valorFinancie]);

    return (
        <div className="box">
            <ul className="title">
                <li>2.</li>
                <li>Información financiera </li>
                <Doc />
            </ul>
            <div className="sub-box redBox">
                <h3>Ingresos del solicitante</h3>
                <ul>
                    <li>
                        <label>Ingreso principal</label>
                        {
                            update ?
                                <>
                                    <Field id="s_ingreso_principal"
                                        name="s_ingreso_principal"
                                        component={ParseNumberThousand} />
                                </> : <>
                                    <p>{valorinic.s_ingreso_principal} </p>
                                </>
                        }

                    </li>
                    <li>
                        <label>Otros ingresos</label>
                        {
                            update ?
                                <>
                                    <Field id="s_otros_ingresos"
                                        name="s_otros_ingresos"
                                        component={ParseNumberThousand} />
                                </> : <>
                                    <p>{valorinic.s_otros_ingresos} </p>
                                </>
                        }
                    </li>
                    <li>
                        <label>Valor egresos</label>
                        {
                            update ?
                                <>
                                    <Field id="s_otros_egresos"
                                        name="s_otros_egresos"
                                        component={ParseNumberThousand} />
                                </> : <>
                                    <p>{valorinic.s_otros_egresos}</p>
                                </>
                        }
                    </li>
                    <li>
                        <label>Concepto de otros ingresos</label>
                        {
                            update ?
                                <>
                                    <Field id="s_concep_otr_ingre"
                                        className="form-know-you-item-input"
                                        name="s_concep_otr_ingre"
                                        type="text" />
                                </> : <>
                                    <p>{valorinic.s_concep_otr_ingre}</p>
                                </>
                        }
                    </li>
                </ul>
                <h3>Propiedades del solicitante</h3>
                <ul>
                    <li>
                        <label>Declarante de renta</label>
                        {
                            update ?
                                <>
                                    <div >
                                        No
                                        <Field id={'s_declarante_ren'}
                                            name="s_declarante_ren" component={Switch}
                                            type="checkbox" />
                                        Si
                                    </div>

                                </> : <>
                                    <FUllPill check={valorinic.s_declarante_ren} disabled={true} id={"s_declarante_ren"} name={"s_declarante_ren"} />
                                </>
                        }
                    </li>
                    <li>
                        <label>Vivienda</label>
                        <p>Propia </p>
                    </li>
                </ul>
            </div>
            <div className="sub-box redBox">
                <h3> operaciones financieras </h3>
                <div className="pillInfo">
                    <p>¿Realizas operaciones en moneda extranjera?</p>
                    {
                        update ?
                            <>
                                <div >
                                    No
                                    <Field id={'s_moneda_ext'}
                                        name="s_moneda_ext" component={Switch}
                                        type="checkbox" />
                                    Si
                                </div>

                            </> : <>
                                <FUllPill check={valorinic.s_moneda_ext} disabled={true} id={'s_moneda_ext'}
                                    name={'s_moneda_ext'} />
                            </>
                    }

                </div>
                <div className="pillInfo">
                    <p>Posees cuentas en moneda extranjera</p>
                    {
                        update ?
                            <>
                                <div >
                                    No
                                    <Field id={'s_cuent_ext'}
                                        name="s_cuent_ext" component={Switch}
                                        type="checkbox" />
                                    Si
                                </div>

                            </> : <>
                                <FUllPill check={valorinic.s_cuent_ext} disabled={true} id={"s_cuent_ext"} name={"s_cuent_ext"} />
                            </>
                    }
                </div>
                <h3>{`Información PEP. (Personas expuestas Políticamente)`}</h3>
                <div className="pillInfo">
                    <p>¿Por tu cargo manejas recursos públicos? </p>
                    {
                        update ?
                            <>
                                <div >
                                    No
                                    <Field id={'s_pep_recpublic'}
                                        name="s_pep_recpublic" component={Switch}
                                        type="checkbox" />
                                    Si
                                </div>

                            </> : <>
                                <FUllPill check={valorinic.s_pep_recpublic} disabled={true} id={"s_pep_recpublic"} name={"s_pep_recpublic"} />
                            </>
                    }
                </div>
                <div className="pillInfo">
                    <p>¿Por tu cargo o actividad ejerces algún grado de poder público? </p>
                    {
                        update ?
                            <>
                                <div >
                                    No
                                    <Field id={'s_pep_poderpublic'}
                                        name="s_pep_poderpublic" component={Switch}
                                        type="checkbox" />
                                    Si
                                </div>

                            </> : <>
                                <FUllPill check={valorinic.s_pep_poderpublic} disabled={true} id={"s_pep_poderpublic"} name={"s_pep_poderpublic"} />
                            </>
                    }
                </div>
                <div className="pillInfo">
                    <p>¿Gozas de reconocimiento público? </p>{
                        update ?
                            <>
                                <div >
                                    No
                                    <Field id={'s_pep_reconpublic'}
                                        name="s_pep_reconpublic" component={Switch}
                                        type="checkbox" />
                                    Si
                                </div>

                            </> : <>
                                <FUllPill check={valorinic.s_pep_reconpublic} disabled={true} id={"s_pep_reconpublic"} name={"s_pep_reconpublic"} />
                            </>
                    }
                </div>
                <div className="pillInfo">
                    <p>¿Eres familiar de alguna persona políticamente expuesta? </p>
                    {
                        update ?
                            <>
                                <div>
                                    No
                                    <Field id={'s_pep_pubexpue'}
                                        name="s_pep_pubexpue" component={Switch}
                                        type="checkbox" />
                                    Si
                                </div>

                            </> : <>
                                <FUllPill check={valorinic.s_pep_pubexpue} disabled={true} id={"s_pep_pubexpue"} name={"s_pep_pubexpue"} />
                            </>
                    }
                </div>
                <div className="pillInfo">
                    <p>{`¿Tienes 2° de afinidad y 2° de consanguinidad (Padres, hermanos, esposa, primos, suegros, cuñados o concuñados) con PEP?`}</p>
                    {
                        update ?
                            <>
                                <div >
                                    No
                                    <Field id={'s_pep_seggraconsa'}
                                        name="s_pep_seggraconsa" component={Switch}
                                        type="checkbox" />
                                    Si
                                </div>

                            </> : <>
                                <FUllPill check={valorinic.s_pep_seggraconsa} disabled={true} id={"s_pep_seggraconsa"} name={"s_pep_seggraconsa"} />
                            </>
                    }
                </div>
            </div>

        </div>
    );
}
interface RenderContainerProps {
    setPopup: React.Dispatch<React.SetStateAction<string>>;
}
export const Box5 = (valorReferencias: any) => {
    const [valuesInicial, valorInicial] = React.useState({});
    const [modalShow, setModalShow] = React.useState(false);
    const [listReferencias, setListReferen] = useState([])
    const [datosReferenciaPersonales, ReferenciaPersonales] = React.useState([]);
    const [one] = useState(false);
    const toggle = () => {
        setModalShow(!modalShow);
        DatosReferenciasPersonales(
            valorReferencias.valorReferencias.arreglo[0],
            valorReferencias.valorReferencias.person.Pws_Identificacion,
            valorReferencias.valorReferencias.person.Pws_Tip_Identificacion)
    };


    useEffect(() => {
        ReferenciaPersonales(valorReferencias.valorReferencias.datosrefe)
    }, [valorReferencias]);

    useEffect(() => {
        getAll()
    }, [one])

    const intl = useIntl();

    const getAll = async () => {
        const refer = await AdminService.getReferencias();
        if (refer.payload.data.ListRef.Datos_Referencias.length > 0) {
            setListReferen(refer.payload.data.ListRef.Datos_Referencias)
        }
    }
    const DatosReferenciasPersonales = async (solicitud: string, identificacion: string, tipo_identificacion: string) => {
        const referencias1 = await WLOCreditAPIService.addReferences({
            s_num_solicitud: solicitud!,
            s_identificacion: identificacion!,
            s_tipo_identificacion: tipo_identificacion!,
            s_ref_tipo: "",
            s_ref_parentes: "",
            s_ref_nomcomple: "",
            s_ref_telfij: "",
            s_ref_telcel: ""
        })
        if (referencias1.payload.result === "1") {
            ReferenciaPersonales(referencias1.payload.data.datos_referencia.datos_refer)
        }
    }

    const ListItem: React.FC<any> = ({ info }) => {
        if (info) {
            let ref = (info.i_ref_tip == 1 ? 'FAMILIAR' : 'PERSONAL')
            let parent = info.n_pws_vinculo
            let nomcom = (info.c_pws_ref_nomcomple)
            let telf = (info.i_pws_ref_telfu)
            let telmo = (info.i_pws_ref_telcle)
            return (
                <ul>
                    <li className="listItem">{ref}</li>
                    <li className="listItem">{parent}</li>
                    <li className="listItem">{nomcom}</li>
                    <li className="listItem">{telf}</li>
                    <li className="listItem">{telmo}</li>
                </ul>
            )
        } else {
            return (<></>)
        }
    }

    return (
        <div className="box">
            <ul className="title">
                <li>3.</li>
                <li>Referencias </li>
            </ul>
            <div className="table">
                {/* <ReferenceTable references={{refer:referencias,Busquedatotals,values:valorReferencias.valorReferencias.datosValorRefer,codeudor:true}}/> */}
                <div className="referenceTable">
                    <div className="table">
                        <div className="responsiveFix">
                            <div className="responsiveAuto">
                                <ul>
                                    <li className="listItem listHeader">Tipo de referencia</li>
                                    <li className="listItem listHeader">Parentesco</li>
                                    <li className="listItem listHeader">Nombre completo</li>
                                    <li className="listItem listHeader">{`Teléfono fijo (Si tiene)`}</li>
                                    <li className="listItem listHeader">Teléfono movil</li>
                                </ul>
                                {
                                    datosReferenciaPersonales.map((info, i) => {
                                        return (
                                            <ListItem key={i} info={info} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="agregar">
                            <button type='button' onClick={() => { setModalShow(!modalShow) }}>Agregar</button>
                        </div>
                    </div>
                    <ModalReferencesCodeudor
                        valuesperson={valorReferencias.valorReferencias.person}
                        solicitud={valorReferencias.valorReferencias.arreglo[0]}
                        values={valorReferencias.valorReferencias.datosValorRefer}
                        show={modalShow}
                        onHide={toggle}
                    />
                </div>
            </div>
        </div>
    );
}

export const Box4 = (valorDocuments: any) => {
    const [update, setUpdate] = React.useState(false);
    const [UpdateDocumentosLegales, setUpdateDocumentosLegales] = React.useState(false);
    const [alldocuments, setDocuments] = React.useState([{
        Pws_Doc_Tipo: 1,
        Pws_Doc_nombre: "",
        Pws_Clave_doc: "",
        Pws_Doc_estado: "",
        Pws_Doc_ruta: ""
    }, {
        Pws_Doc_Tipo: 2,
        Pws_Doc_nombre: "",
        Pws_Clave_doc: "",
        Pws_Doc_estado: "",
        Pws_Doc_ruta: ""
    }, {
        Pws_Doc_Tipo: 3,
        Pws_Doc_nombre: "",
        Pws_Clave_doc: "",
        Pws_Doc_estado: "",
        Pws_Doc_ruta: ""
    }, {
        Pws_Doc_Tipo: 4,
        Pws_Doc_nombre: "",
        Pws_Clave_doc: "",
        Pws_Doc_estado: "",
        Pws_Doc_ruta: ""
    }, {
        Pws_Doc_Tipo: 5,
        Pws_Doc_nombre: "",
        Pws_Clave_doc: "",
        Pws_Doc_estado: "",
        Pws_Doc_ruta: ""
    }]);
    const { user, setUser } = useLoginContext();
    useEffect(() => {
        if (valorDocuments.valorDocuments.alldocuments) {
            if (valorDocuments.valorDocuments.alldocuments.length > 0) {
                let valordocumentos = [{
                    Pws_Doc_Tipo: 1,
                    Pws_Doc_nombre: "",
                    Pws_Clave_doc: "",
                    Pws_Doc_estado: "",
                    Pws_Doc_ruta: ""
                }, {
                    Pws_Doc_Tipo: 2,
                    Pws_Doc_nombre: "",
                    Pws_Clave_doc: "",
                    Pws_Doc_estado: "",
                    Pws_Doc_ruta: ""
                }, {
                    Pws_Doc_Tipo: 3,
                    Pws_Doc_nombre: "",
                    Pws_Clave_doc: "",
                    Pws_Doc_estado: "",
                    Pws_Doc_ruta: ""
                }, {
                    Pws_Doc_Tipo: 4,
                    Pws_Doc_nombre: "",
                    Pws_Clave_doc: "",
                    Pws_Doc_estado: "",
                    Pws_Doc_ruta: ""
                }, {
                    Pws_Doc_Tipo: 5,
                    Pws_Doc_nombre: "",
                    Pws_Clave_doc: "",
                    Pws_Doc_estado: "",
                    Pws_Doc_ruta: ""
                }] as any
                valorDocuments.valorDocuments.alldocuments.forEach((element: any) => {
                    if (element.Pws_Doc_estado == "1") valordocumentos[Number(element.Pws_Doc_Tipo) - 1] = element
                });
                setDocuments(valordocumentos)
            }
        }
    }, [valorDocuments]);
    const cambiarUpdat = () => {
        if (update) {
            valorDocuments.valorDocuments.Busquedatotal()
        }
        setUpdate(!update)
    }

    const handleFileInputChange = (e: any) => {
        const target = e.target
        if (target.files && target.files[0]) {
            const maxAllowedSize = 5 * 1024 * 1024;
            if (target.files[0].size > maxAllowedSize) {
                toast.error('Seleccione un archivo de tamaño máximo 5mb.');
                target.value = ''
            }
        }
        let file: any = '';
        file = e.target.files[0];
        getBase64(file)
            .then(async result => {
                const document = result as string;
                const documentRequest = {
                    Pws_num_solicitud: valorDocuments.valorDocuments.valor[0],
                    Pws_Identificacion: valorDocuments.valorDocuments.valorCodeudor.cedula,
                    Pws_Tip_Identificacion: valorDocuments.valorDocuments.valorCodeudor.type,
                    Pws_Doc_Tipo: target.dataset.id,
                    Pws_Doc_nombre: file.name,
                    Pws_Doc_estado: "1",
                    Pws_Doc_ruta: "1",
                    Pws_Clave_doc: document,
                };
                (async () => {
                    const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);
                    if (Number(solicitud.payload.result) > 0) {
                        toast.success("Subido el Nuevo Archivo")
                    }

                })();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getBase64 = (file: any) => {
        return new Promise(resolve => {
            let baseURL: string | ArrayBuffer | null = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };
    return (
        <div className="box">
            <ul className="title">
                <li>4.</li>
                <li>Documentos de la solicitud </li>
                <File />
                <div onClick={cambiarUpdat}>
                    <Edit />
                </div>
            </ul>
            <div className="documents">
                {alldocuments.map((valorDocumentss: any, index) => {
                    let tipo = ""
                    let documentss = ""
                    let pasar = true;
                    if (Number(valorDocumentss.Pws_Doc_Tipo) == 1) {
                        tipo = "Copia de Cedula"
                        documentss = valorDocumentss.Pws_Doc_nombre
                    } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 2) {
                        tipo = "Recibo de Servicio publico"
                        documentss = valorDocumentss.Pws_Doc_nombre
                    } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 3) {
                        tipo = "Soportes de Ingresos y/o Garantias"
                        documentss = valorDocumentss.Pws_Doc_nombre
                    } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 5) {
                        tipo = "Otros"
                        documentss = valorDocumentss.Pws_Doc_nombre
                    } else if (Number(valorDocumentss.Pws_Doc_Tipo) == 4) {
                        tipo = "Sagrilaft"
                        documentss = valorDocumentss.Pws_Doc_nombre
                        if (Number(user.idProfile) == 1) {
                        } else {
                            pasar = false
                        }
                    }
                    const download = () => {
                        const link = document.createElement('a');
                        link.href = valorDocumentss.Pws_Clave_doc;
                        link.setAttribute(
                            'download',
                            documentss,
                        );
                        link.setAttribute("target", "_blank")
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                    if (pasar) {
                        return (<>
                            <div key={index} className={update ? "redBox" : documentss ? "redBox check" : "redBox"} onClick={(eve: any) => {
                                if (update) {
                                    eve.currentTarget.querySelector('input')?.click()
                                } else {
                                    if (documentss) {
                                        download()
                                    }
                                }
                            }}>
                                {update ? <>
                                    <input
                                        data-id={valorDocumentss.Pws_Doc_Tipo}
                                        type="file"
                                        name="file-input"
                                        id="file-input"
                                        className="file-input__input"
                                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                                        onChange={handleFileInputChange}
                                    />
                                </> : <>
                                </>}
                                {update ? <>
                                    <Documentos /></> : !documentss ? <>
                                        <Documentos /></> : <>
                                    <Documentosred />
                                </>}
                                <p>{tipo}{update ? " (Upload)" : ""}</p>
                            </div>
                        </>)
                    } else {
                        return (<></>)
                    }
                })}
            </div>
        </div>
    );
}

export const Box9 = (terceros: any) => {
    const [revisado, setRevisado] = React.useState(true)
    const [tercero, agregapro] = React.useState([] as any)
    const [infoPers, setInfoPers] = React.useState([] as any)
    const [revisadoDisable, setRevisadoDisable] = React.useState(true)
    useEffect(() => {
        agregapro(terceros.terceros.valores)
        setInfoPers(terceros.terceros.infoCredito)
        if (terceros.terceros.valores.length == 0) {
            setRevisadoDisable(false)
        } else {
            setRevisadoDisable(true)
        }
    }, [terceros])
    return (
        <div className="box">
            <ul className="title">
                <li>4.</li>
                <li>Consulta a terceros</li>
                <Person />
            </ul>
            <div className="table">
                <ConsultaTercerosTable terceros={{ tercero: tercero, infoPers: infoPers }} />
            </div>
        </div>
    );
}

export const ConsultaTercerosTable = (terceros: any) => {
    const [tercero, agregapro] = React.useState([] as any)
    const [infoPers, setInfoPers] = React.useState([] as any)
    useEffect(() => {
        agregapro(terceros.terceros.tercero)
        setInfoPers(terceros.terceros.infoPers)
    }, [terceros])
    return (
        <div className="referenceTable">
            <div className="table">
                <div className="responsiveFix">
                    <div className="responsiveAuto">
                        <ul>
                            <li className="listItem listHeader">Entidad</li>
                            <li className="listItem listHeader">Puntaje</li>
                            <li className="listItem listHeader">Cuotas Data</li>
                            <li className="listItem listHeader">Riesgo</li>
                            <li className="listItem listHeader">Consultar</li>
                        </ul>
                        {
                            tercero?.map((info: any, i: any) => {
                                return (
                                    <SolicItem key={i} info={info} infoPers={infoPers} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const SolicItem = (info: any) => {
    const [detalleSol, setdetalleSol] = React.useState([] as any);
    useEffect(() => {
        setdetalleSol(info.infoPers)
    })

    const datacredito = async () => {
        const estadoTerce = await AdminService.entidadTer("");
        let puntaje = 0
        let cuotaData = 0
        let consulta
        let estadoDatacredito = "3"
        let sends: WloCreditoService.SolicitudDetalla = {
            Pws_Num_Solicitud: detalleSol.Pws_num_solicitud,
            Pws_Identificacion: detalleSol.Pws_Ideoastntificacion,
            Pws_Tip_Identificacion: detalleSol.Pws_Tip_Identificacion,
            Pws_Perfil_Acceso: "",
            Pws_Fec_Solicitud: "",
        }
        consulta = await WLOCreditAPIService.consultaSolicitudCygnusDetal(sends)
        console.log(consulta.payload.data.datos_persona.Datos_persona[0], "Aqui")
        const data4 = {
            identificacion: detalleSol.Pws_Identificacion,
            primer_apellido: consulta.payload.data.datos_persona.Datos_persona[0].Pws_Apellidos1,
            tipoIdentificacion: String(typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.filter(valor => valor.s_codigo == Number(detalleSol.Pws_Tip_Identificacion))[0].s_datacredito)
        }
        const response3 = await WLOCreditAPIService.consultDataCredito2(data4);
        if (response3.payload.success) {
            puntaje = response3.payload.data.Informes.Informe[0].Score[0].$.puntaje
            cuotaData = response3.payload.data.Informes.Informe[0].InfoAgregada[0].Resumen[0].Saldos[0].$.cuotaMensual
            if (puntaje >= 1 && puntaje < 500) {
                estadoDatacredito = "1"
            } else if (puntaje >= 500) {
                estadoDatacredito = "0"
            }
        }
        const data5 = {
            Pws_num_solicitud: String(detalleSol.Pws_num_solicitud),
            Pws_Identificacion: String(detalleSol.Pws_Identificacion!),
            Pws_Tip_Identificacion: String(detalleSol.Pws_Tip_Identificacion!),
            Pws_Fec_gen: String(Moment(new Date()).format('MM/DD/YYYY')),
            Pws_estado_resotp: estadoDatacredito,
            Pws_Tip_val: '2',
            Pws_Puntaje: String(puntaje),
            Pws_Entidad_Consul: '4',
            Pws_Num_cuodat: String(cuotaData * 1000),
        }
        if (estadoTerce.payload.data.ListRef.Datos_Referencias[2].s_descripcion_legal == "1") {
            const response5 = await WLOCreditAPIService.regDataCreditoAndSagrilaft(data5);
            toast.success(response5.payload.data.Pws_R_s_mensaje)
        } else {
            toast.error(response3.payload.data.mensaje)
        }
    }

    const sagrilaft = async () => {
        let sagrilaftRiesgo
        let tipodocumento = '';
        if (detalleSol.Pws_Tip_Identificacion === "12") tipodocumento = "ti"
        if (detalleSol.Pws_Tip_Identificacion === "13") tipodocumento = "cc"
        if (detalleSol.Pws_Tip_Identificacion === "22") tipodocumento = "ce"
        if (detalleSol.Pws_Tip_Identificacion === "31") tipodocumento = "nit"
        if (detalleSol.Pws_Tip_Identificacion === "41") tipodocumento = "pa"

        const data = {
            datoConsultar: detalleSol.Pws_num_solicitud, //19123402
            tipoDocumento: tipodocumento,
            numeroSolicitud: detalleSol.Pws_num_solicitud, //No tenemos un ws que nos diga el nro de solicitud
            fechaSolicitud: Moment(new Date()).format('MM/DD/YYYY'),
            email: ""
        }

        const response = await WLOCreditAPIService.consultaConsolidada(data);
        if (response.payload.presentaRiesgo) {
            sagrilaftRiesgo = "1"
        } else {
            sagrilaftRiesgo = "0"
        }
        //guarda sagrilaft
        const response2 = await WLOCreditAPIService.regDataCreditoAndSagrilaft({
            Pws_num_solicitud: detalleSol.Pws_num_solicitud,
            Pws_Identificacion: detalleSol.Pws_Identificacion,
            Pws_Tip_Identificacion: detalleSol.Pws_Tip_Identificacion,
            Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
            Pws_estado_resotp: sagrilaftRiesgo,
            Pws_Tip_val: '1',
            Pws_Puntaje: '0',
            Pws_Entidad_Consul: '2',
            Pws_Num_cuodat: '',
        });
    }

    const consultaScore = async () => {
        let scorepuntaje
        let enestudio
        let scorecygnus
        const response10 = await WLOCreditAPIService.EvaluaScore({
            ps_radicado: String(detalleSol.Pws_num_solicitud),
            ps_identificacion: String(detalleSol.Pws_Identificacion),
            ps_cod_lin: String(detalleSol.Pws_linea_credito),
            ps_tipoCliente: String(detalleSol.Pws_tipo_cliente),
            ps_tip_deucode: "1"
        })
        if (response10.payload.data.estado === "APROBADO") { scorecygnus = true } else {
            enestudio = true
        };
        scorepuntaje = response10.payload.data.puntaje_obtenido

        const data6 = {
            Pws_num_solicitud: String(detalleSol.Pws_num_solicitud),
            Pws_Identificacion: String(detalleSol.Pws_Identificacion),
            Pws_Tip_Identificacion: detalleSol.Pws_Tip_Identificacion,
            Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
            Pws_estado_resotp: scorecygnus ? "0" : "1",
            Pws_Tip_val: '4',
            Pws_Puntaje: String(scorepuntaje),
            Pws_Entidad_Consul: '5',
            Pws_Num_cuodat: '',
        }
        const response6 = await WLOCreditAPIService.regDataCreditoAndSagrilaft(data6);
    }

    const irpara = (descripcion: string) => {
        if (descripcion == "Score Cygnus") {
            consultaScore();
        }
        if (descripcion == "DataCredito") {
            datacredito();
        }
        if (descripcion == "Sagrilaft") {
            sagrilaft();
        }
    }
    return (
        <>
            <ul>
                <li className="listItem firstItem">{info.info.c_descripcion}</li>
                <li className="listItem">{info.info.puntaje} </li>
                <li className="listItem">{
                    info.info.cuotas_data == "0" ?
                        "-" : <NumberFormat value={info.info.cuotas_data} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                }
                </li>
                <li className="listItem">{info.info.estado_restop == "1" ? "SI" : "NO"}             </li>

                <li className="listItem">
                    {info.info.estado_restop == "3" ?
                        <div onClick={() => { irpara(info.info.c_descripcion) }}><Search /></div> : <div><GreenCheck /></div>
                    }
                </li>
            </ul>
        </>
    )
}

export const DetailCreditRequire = (props: RenderContainerProps) => {
    const { setPopup } = props;
    const { user } = useLoginContext();
    const navigate = useNavigate();
    const buscar = useLocation();
    const [isupdate, setUpdate] = React.useState(true);
    const [persona, DatoPersona] = React.useState({} as any);
    const [dataConyuge, setDataConyuge] = React.useState({} as any);
    const [codigoCodeudor, setCodigoCodeudor] = React.useState({});
    const [alldocuments, setallDocuments] = React.useState([]);
    const { request, setRequest } = useRequestContext();
    const [one] = React.useState({});
    const [arregloValor, valorArreglo] = React.useState([] as any);
    const [arregloValorCodeudor, valorArregloCodeudor] = React.useState([] as any);
    const [radicados, setRadicado] = React.useState({} as any);
    let [isLoading, setIsLoading] = useState(false);
    const [datosReferenciaPersonales, ReferenciaPersonales] = React.useState([]);
    const [datosValorRefer, DatosReferencia] = React.useState({});
    const [listCredit, setListcredit] = useState([]);
    const [infoCredito, setInformacionCredito] = React.useState({} as any);
    const [listadoCon, setListadoCon] = React.useState([] as any);

    useEffect(()=>{
        getValueListCreditos()
        
    },[one])

    const getValueListCreditos =async () =>{
        const valor= await AdminService.getlineascredito();
        if(valor.payload.data.ListLineaCredito.Datos_LineasCredito.length>0){
            setListcredit(valor.payload.data.ListLineaCredito.Datos_LineasCredito)
        }     
    }
    useEffect(() => {
        if (buscar) {
            var valorBusqueda = buscar.search.replace("?", "")
            let arreglo = String(valorBusqueda).split("-")
            valorArreglo(arreglo)
            if (arreglo.length == 3) {
                let codeudors: any = {
                    Pws_codigo: "",
                    Pws_num_solicitud: arreglo[0],
                    Pws_Identificacion: arreglo[1],
                    Pws_Radic_Codeu: arreglo[0],
                    Pws_Tip_Identificacion: arreglo[2],
                    Pws_Iden_Code: "",
                    Pws_Tip_Identificacion_Code: "",
                    Pws_Tip_Codeu: ""
                }
                setRequest({
                    ...request,
                    dataModifcable: {}
                })
                WLOCreditAPIService.addCodeudor(codeudors).then((GetCodeudors) => {
                    if (Number(GetCodeudors.payload.result) > 0) {
                    }
                })
                setCodigoCodeudor(0)
            } else if (arreglo.length > 3) {
                let codeudors: any = {
                    Pws_codigo: "",
                    Pws_num_solicitud: arreglo[0],
                    Pws_Identificacion: arreglo[1],
                    Pws_Radic_Codeu: arreglo[0],
                    Pws_Tip_Identificacion: arreglo[2],
                    Pws_Iden_Code: "",
                    Pws_Tip_Identificacion_Code: "",
                    Pws_Tip_Codeu: ""
                }
                WLOCreditAPIService.addCodeudor(codeudors).then((GetCodeudors) => {
                    if (Number(GetCodeudors.payload.result) > 0) {
                        let paso = false
                        let cedula = ""
                        let type = ""
                        let arregloCodeudor: any = {}
                        GetCodeudors.payload.data.datos_codeu.Datos_codeudor.forEach((element: any) => {
                            if (Number(element.Pws_codigo) == Number(arreglo[3])) {
                                paso = true
                                cedula = element.Pws_Iden_Code
                                type = element.Pws_Tip_Identificacion_Code
                                arregloCodeudor = element
                            }
                        });
                        if (paso) {
                            setUpdate(false)
                            putDatainView(cedula, type, arreglo)
                            valorArregloCodeudor(arregloCodeudor)
                            extraerdatcredRiesgo(arreglo[0], cedula, type)
                            console.log("clg en el use effect")
                        } else {
                            toast.error("No es el codeudor Requerido")
                        }
                    }
                })
                setCodigoCodeudor(arreglo[3])
            }

        }
        document.getElementsByClassName("title")[0].scrollIntoView()
    }, [buscar, navigate])
    const putDatainView = (cedulaSearhc: any, typeCedul: any, arreglo: any) => {
        let sends: WloCreditoService.SolicitudDetalla = {
            Pws_Num_Solicitud: arreglo[0],
            Pws_Identificacion: cedulaSearhc,
            Pws_Tip_Identificacion: typeCedul,
            Pws_Perfil_Acceso: "",
            Pws_Fec_Solicitud: "",
        }
        WLOCreditAPIService.consultaSolicitudCygnusDetal(sends).then((valor) => {
            if (Number(valor.payload.result) > 0) {

                if (valor.payload.data.datos_persona) {
                    if (valor.payload.data.datos_persona.Datos_persona.length > 0) {
                        if (valor.payload.data.datos_operfinan) {
                            if (valor.payload.data.datos_peps) {
                                if (valor.payload.data.datos_peps.Datos_peps.length > 0) {
                                    if (valor.payload.data.datos_operfinan.Datos_operfinan.length > 0) {
                                        let dataCygnus = valor.payload.data.datos_persona.Datos_persona[0]
                                        console.log(dataCygnus)
                                        let dataCygnusOperfin = valor.payload.data.datos_operfinan.Datos_operfinan[0]
                                        let dataCygnusPeps = valor.payload.data.datos_peps.Datos_peps[0]
                                        let dataCygnusCony = valor.payload.data.datos_conyugue?.Datos_conyugue[0];
                                        const datainicial = {
                                            Pws_Identificacion: dataCygnus.Pws_Identificacion,
                                            Pws_Tip_Identificacion: dataCygnus.Pws_Tip_Identificacion,
                                            Pws_Tip_Estado: dataCygnus.Pws_Tip_Estado, // Valor constante para confirmación de información.
                                            Pws_Tip_Perfil: dataCygnus.Pws_Tip_Perfil,
                                            Pws_Tip_person: dataCygnus.Pws_Tip_person,
                                            Pws_Tip_ocupa: dataCygnus.Pws_Tip_ocupa,
                                            Pws_Nombres: dataCygnus.Pws_Nombres,
                                            Pws_Apellidos1: dataCygnus.Pws_Apellidos1,
                                            Pws_Apellidos2: dataCygnus.Pws_Apellidos2,
                                            Pws_Telefono1: dataCygnus.Pws_Telefono1,
                                            Pws_Telefono2: dataCygnus.Pws_Telefono2,
                                            Pws_Correo: dataCygnus.Pws_Correo,
                                            Pws_Fec_expe: dataCygnus.Pws_Fec_expe.split("-00")[0],
                                            Pws_Lugar_exp: dataCygnus.Pws_Lugar_exp,
                                            Pws_Fec_nacime: dataCygnus.Pws_Fec_nacime.split("-00")[0],
                                            Pws_Estado_Civil: dataCygnus.Pws_Estado_Civil,
                                            Pws_Direccion_res: dataCygnus.Pws_Direccion_res,
                                            Pws_Departamento: dataCygnus.Pws_Departamento,
                                            Pws_Ciudad_res: dataCygnus.Pws_Ciudad_res,
                                            Pws_Genero: dataCygnus.Pws_Genero,
                                            Pws_Estrato_per: dataCygnus.Pws_Estrato_per,
                                            Pws_Per_cargo: dataCygnus.Pws_Per_cargo,
                                            Pws_Tip_vivienda: dataCygnus.Pws_Tip_vivienda,
                                            Pws_Niv_estudio: dataCygnus.Pws_Niv_estudio,
                                            Pws_Nom_empre: dataCygnus.Pws_Nom_empre,
                                            Pws_fec_ingemp: dataCygnus.Pws_fec_ingemp.split("-00")[0],
                                            Pws_Fideliza: dataCygnus.Pws_Fideliza,
                                            Pws_Tip_contra: dataCygnus.Pws_Tip_contra,
                                            Pws_Ant_labo: dataCygnus.Pws_Ant_labo,
                                            Pws_Car_emp: dataCygnus.Pws_Car_emp,
                                            Pws_Nom_Jefedi: dataCygnus.Pws_Nom_Jefedi,
                                            Pws_Direc_emp: dataCygnus.Pws_Direc_emp,
                                            Pws_Ciud_emp: dataCygnus.Pws_Ciud_emp,
                                            Pws_tel_emp1: dataCygnus.Pws_tel_emp1,
                                            Pws_tel_emp2: dataCygnus.Pws_tel_emp2,
                                            s_num_solicitud: dataCygnusOperfin.Pws_Num_Solicitud,
                                            s_identificacion: dataCygnus.Pws_Identificacion,
                                            s_tipo_identificacion: dataCygnus.Pws_Tip_Identificacion,
                                            s_ingreso_principal: dataCygnusOperfin.Pws_Ingreso_Principal,
                                            s_otros_ingresos: dataCygnusOperfin.Pws_otros_ingresos,
                                            s_otros_egresos: dataCygnusOperfin.Pws_Otros_egresos,
                                            s_arriendo: dataCygnusOperfin.Pws_Arriendo,
                                            s_concep_otr_ingre: dataCygnusOperfin.Pws_Concep_otr_ingre,
                                            s_declarante_ren: Number(dataCygnusOperfin.Pws_Declarante_ren) == 0 ? false : true,
                                            s_moneda_ext: Number(dataCygnusOperfin.Pws_Moneda_ext) == 0 ? false : true,
                                            s_monext_oper: dataCygnusOperfin.Pws_Monext_oper,
                                            s_tip_monext: dataCygnusOperfin.Pws_Tip_monext,
                                            s_cuent_ext: Number(dataCygnusOperfin.Pws_Cuent_ext) == 0 ? false : true,
                                            s_cuen_extban: dataCygnusOperfin.Pws_Cuen_extban,
                                            s_cuen_extnum: dataCygnusOperfin.Pws_Cuen_extnum,
                                            s_cuen_extpais: dataCygnusOperfin.Pws_Cuen_extpais,
                                            s_cuen_extciudad: dataCygnusOperfin.Pws_Cuen_extciudad,
                                            s_pep_recpublic: Number(dataCygnusPeps.Pws_Pep_recpublic) == 0 ? false : true,
                                            s_pep_poderpublic: Number(dataCygnusPeps.Pws_Pep_poderpublic) == 0 ? false : true,
                                            s_pep_reconpublic: Number(dataCygnusPeps.Pws_Pep_Reconpublic) == 0 ? false : true,
                                            s_pep_pubexpue: Number(dataCygnusPeps.Pws_Pep_pubexpue) == 0 ? false : true,
                                            s_pep_seggraconsa: Number(dataCygnusPeps.Pws_Pep_seggraconsa) == 0 ? false : true,
                                            s_pep_nompepseg: dataCygnusPeps.Pws_Pep_nompepseg,
                                            s_pep_paren: dataCygnusPeps.Pws_Pep_paren,
                                            s_pep_identif: dataCygnusPeps.Pws_Pep_Identif,
                                        }
                                        const dataInicialConyuge = {
                                            Pws_Con_nomsol: dataCygnusCony?.Pws_Con_nomsol,
                                            Pws_Con_Tip_identif: dataCygnusCony?.Pws_Con_Tip_identif,
                                            Pws_Con_identif: dataCygnusCony?.Pws_Con_identif,
                                            Pws_Con_tel:dataCygnusCony?.Pws_Con_tel,
                                        }
                                        buscarDocumentos(arreglo[0], dataCygnus)
                                        DatosReferenciasPersonales(arreglo[0], dataCygnus.Pws_Identificacion, dataCygnus.Pws_Tip_Identificacion);
                                        DatoPersona(datainicial)
                                        setDataConyuge(dataInicialConyuge);
                                        setRequest({
                                            ...request,
                                            dataModifcable: {
                                                identificacion: dataCygnus.Pws_Identificacion,
                                                tipoindentificacion: dataCygnus.Pws_Tip_Identificacion,
                                                codeudorID: arreglo[3],
                                                idUser: dataCygnus.Pws_i_codigo
                                            }
                                        })
                                    }
                                }
                            }

                        }
                    }
                }


            }
        }, (error) => {
            console.log("error")
            console.log(error)
        })

    }

    const onSubmit = async (values: any, formikBag: { setSubmitting: any; }) => {

        setIsLoading(true);
        if (arregloValor.length > 2) {
            registarDatosreque(arregloValor[0], values, arregloValor[1], arregloValor[2])
        } else {
            toast("No puede Grabar la informacion")
        }

        // /*TEST EBS*/
        // const emailInfo = {
        //     email: String(request.email),
        //     dateSolicitud: new Date().toDateString(),
        //     numsol:String(request.numSol!),
        //     ps_cod_lin:values.pws_cod_credi,
        //     ps_tipoCliente:values.Pws_Tip_ocupa,
        //     radicado: radicados,
        //     Ps_tip_deucode:"1",
        //     rotativo:request.isRotativo
        // }
        // const respuesta   = await WLOCreditAPIService.sendEmailFirmaInicial(emailInfo);

    }


    useEffect(() => {
        const datainicial = {
            Pws_Identificacion: "",
            Pws_Tip_Identificacion: "",
            Pws_Tip_Estado: "2", // Valor constante para confirmación de información.
            Pws_Tip_Perfil: "2",
            Pws_Tip_person: "",
            Pws_Tip_ocupa: "",
            Pws_Nombres: "",
            Pws_Apellidos1: "",
            Pws_Apellidos2: "",
            Pws_Telefono1: "",
            Pws_Telefono2: "",
            Pws_Correo: "",
            Pws_Fec_expe: "",
            Pws_Lugar_exp: "",
            Pws_Fec_nacime: "",
            Pws_Estado_Civil: "",
            Pws_Direccion_res: "",
            Pws_Departamento: "",
            Pws_Ciudad_res: "",
            Pws_Genero: "",
            Pws_Estrato_per: "",
            Pws_Per_cargo: "",
            Pws_Tip_vivienda: "",
            Pws_Niv_estudio: "",
            Pws_Nom_empre: "",
            Pws_fec_ingemp: "",
            Pws_Fideliza: "",
            Pws_Tip_contra: "",
            Pws_Ant_labo: "",
            Pws_Car_emp: "",
            Pws_Nom_Jefedi: "",
            Pws_Direc_emp: "",
            Pws_Ciud_emp: "",
            Pws_tel_emp1: "",
            Pws_tel_emp2: "",
            s_num_solicitud: "",
            s_ref_tipo: '',
            s_ref_parentes: '',
            s_ref_nomcomple: '',
            s_ref_telfij: '',
            s_ref_telcel: '',
            s_identificacion: "",
            s_tipo_identificacion: "",
            s_ingreso_principal: "",
            s_otros_ingresos: "",
            s_otros_egresos: "",
            s_arriendo: "",
            s_concep_otr_ingre: "",
            s_declarante_ren: false,
            s_moneda_ext: false,
            s_monext_oper: "",
            s_tip_monext: "",
            s_cuent_ext: false,
            s_cuen_extban: "",
            s_cuen_extnum: "",
            s_cuen_extpais: "",
            s_cuen_extciudad: "",
            s_pep_recpublic: false,
            s_pep_poderpublic: false,
            s_pep_reconpublic: false,
            s_pep_pubexpue: false,
            s_pep_seggraconsa: false,
            s_pep_nompepseg: "",
            s_pep_paren: "",
            s_pep_identif: "",
        }
        DatoPersona(datainicial)
    }, [one])

    const buscarDocumentos = async (numsol: any, values: any) => {
        const documentRequest = {
            Pws_num_solicitud: numsol!,
            Pws_Identificacion: values.Pws_Identificacion,
            Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
        };
        const solicitud = await WLOCreditAPIService.ConsultarDocumentos(documentRequest);
        if (solicitud.payload.data.Pws_R_s_cod == "1") {
            if (solicitud.payload.data.datos_doc.datos_Doc) {
                setallDocuments(solicitud.payload.data.datos_doc.datos_Doc)

            } else {
                setallDocuments([])

            }
        }

    }
    const enviarDocumentos = (values: any, numsol: string) => {
        document.getElementsByName("file-input").forEach((element) => {
            var valor = element as HTMLInputElement
            if (valor.files) {
                if (valor.files.length > 0) {
                    var file = valor.files[0];
                    getBase64(file)
                        .then(async result => {
                            const document = result as string;
                            const documentRequest = {
                                Pws_num_solicitud: numsol!,
                                Pws_Identificacion: values.Pws_Identificacion,
                                Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
                                Pws_Doc_Tipo: valor.dataset.id,
                                Pws_Doc_nombre: file.name,
                                Pws_Doc_estado: "1",
                                Pws_Doc_ruta: "1",
                                Pws_Clave_doc: document,
                            };

                            (async () => {
                                const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);
                                if (Number(solicitud.payload.result) > 0) {
                                    toast.success("Subido el Nuevo Archivo")
                                }

                            })();

                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }
        });

    }

    const valorCheck = (): Boolean => {
        var letValorAceptado = true
        if (alldocuments.length > 2) {

        } else {
            document.getElementsByName("file-input").forEach((element) => {
                var valor = element as HTMLInputElement
                if (valor.files) {
                    if (valor.files.length > 0) {

                    } else {
                        letValorAceptado = true
                    }
                } else {
                    letValorAceptado = true
                }
            })
        }


        return letValorAceptado
    }
    const valuesFinancieros = async (numsol: string, values: any, pws_identifica: string, pws_tip_identifica: string) => {

        enviarDocumentos(values, arregloValor[0])
        const operFinancieras = {
            s_num_solicitud: numsol!,
            s_identificacion: values.Pws_Identificacion,
            s_tipo_identificacion: values.Pws_Tip_Identificacion,
            s_ingreso_principal: values.s_ingreso_principal,
            s_otros_ingresos: values.s_otros_ingresos,
            s_otros_egresos: values.s_otros_egresos,
            s_arriendo: values.s_arriendo,
            s_concep_otr_ingre: values.s_concep_otr_ingre,
            s_declarante_ren: values.s_declarante_ren ? "1" : "0",
            s_moneda_ext: values.s_moneda_ext ? "1" : "0",
            s_monext_oper: values.s_monext_oper,
            s_tip_monext: values.s_tip_monext,
            s_cuent_ext: values.s_cuent_ext,
            s_cuen_extban: values.s_cuen_extban,
            s_cuen_extnum: values.s_cuen_extnum,
            s_cuen_extpais: values.s_cuen_extpais,
            s_cuen_extciudad: values.s_cuen_extciudad
        }
        const result2 = await WLOCreditAPIService.addFinancialInformation(operFinancieras);
        const peps = {
            s_num_solicitud: numsol!,
            s_identificacion: values.Pws_Identificacion,
            s_tipo_identificacion: values.Pws_Tip_Identificacion,
            s_pep_recpublic: values.s_pep_recpublic ? "1" : "0",
            s_pep_poderpublic: values.s_pep_poderpublic ? "1" : "0",
            s_pep_reconpublic: values.s_pep_reconpublic ? "1" : "0",
            s_pep_pubexpue: values.s_pep_pubexpue ? "1" : "0",
            s_pep_seggraconsa: values.s_pep_seggraconsa ? "1" : "0",
            s_pep_nompepseg: values.s_pep_nompepseg,
            s_pep_paren: values.s_pep_paren,
            s_pep_identif: values.s_pep_identif,
        }
        const result = await WLOCreditAPIService.addPeps(peps);
        agregarCygnus(numsol, values, pws_identifica, pws_tip_identifica)

    }
    const agregarCygnus = async (numsol: string, values: any, pws_identifica: string, pws_tip_identifica: string) => {
        const cignus = await WLOCreditAPIService.grabar_cignus({
            Pws_Identificacion: values.Pws_Identificacion,
            Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
            Pws_Tip_Perfil: values.Pws_Tip_Perfil!
        })

        if (Number(cignus.payload.result) > 0) {
            let dataCodeudor = {
                Pws_num_solicitud: numsol,
                Pws_Identificacion: pws_identifica,
                Pws_Radic_Codeu: numsol,
                Pws_Tip_Identificacion: pws_tip_identifica,
                Pws_Iden_Code: values.Pws_Identificacion,
                Pws_Tip_Identificacion_Code: values.Pws_Tip_Identificacion,
                Pws_Tip_NomCodeu: "Requerido",
                Pws_Tip_Codeu: "2",
                Pws_Estado: "1"
            }
            if (codigoCodeudor > 0) {
                toast.success('Actualizado Correctamente');
                navigate(-1)

            } else {
                SendEmailCodeudor(numsol, values, pws_identifica, pws_tip_identifica)
                /*             const codeudor = await WLOCreditAPIService.addCodeudor(dataCodeudor)
                            console.log(codeudor.payload)
                            if(Number(codeudor.payload.result)>0){
                                console.log(codeudor.payload)
                            }else{
                                toast.error(codeudor.payload.message)
                                
                            }    */
            }

        } else {
            toast.error('Tienes error en el Registro cygnus');
        }
    }
    const SendEmailCodeudor = async (numsol: string, values: any, pws_identifica: string, pws_tip_identifica: string) => {
        let dataCodeudor = {
            Pws_num_solicitud: arregloValor[0],
            Pws_Identificacion: arregloValor[1],
            Pws_Radic_Codeu: arregloValor[0],
            Pws_Tip_Identificacion: arregloValor[2],
            Pws_Iden_Code: values.Pws_Identificacion,
            Pws_Tip_Identificacion_Code: values.Pws_Tip_Identificacion,
            Pws_Tip_Codeu: "2",
            Pws_Estado: "3"
        }
        const valorcodeudor = await WLOCreditAPIService.addCodeudor(dataCodeudor)
        if (Number(valorcodeudor.payload.result) > 0) {
            let valorBusqueda = {} as any
            if (valorcodeudor.payload.data.datos_codeu.Datos_codeudor) {
                valorcodeudor.payload.data.datos_codeu.Datos_codeudor.forEach((valorcode: any) => {
                    if (valorcode.Pws_Iden_Code == values.Pws_Identificacion && valorcode.Pws_Tip_Identificacion_Code == values.Pws_Tip_Identificacion) {
                        valorBusqueda = valorcode
                        setRadicado(valorcode)
                    }
                })
                if (valorBusqueda.Pws_Iden_Code) {
                    const emailInfo = {
                        email: String(values.Pws_Correo),
                        dateSolicitud: new Date().toDateString(),
                        numsol: String(numsol!),
                        ps_cod_lin: request.valorSolicitud.pws_cod_credi,
                        ps_tipoCliente: values.Pws_Tip_ocupa,
                        radicado: valorBusqueda,
                        Ps_tip_deucode: "2",
                        rotativo: false
                    }
                    const resultEnvioCorreo = await WLOCreditAPIService.sendEmailFirmaInicial(emailInfo);
                    if (user.isSuccessfulLogin) {
                        toast("Se Guardo correctametne el Codeudor Requerido, verifique el correo")
                        navigate(-1)
                    } else {
                        navigate(AppRoutes.CONFIRMATION)
                    }
                    setIsLoading(false)
                    toast("Registrado Correctamente la informacion")
                } else {
                    setIsLoading(false)
                    toast("Tienes error en la consulta del Envio")

                }
            } else {
                toast(valorcodeudor.payload.message)

            }


        } else {
            toast.error(valorcodeudor.payload.message);
            setIsLoading(false)

        }

    }
    const registarDatosreque = async (numsol: string, values: any, pws_identifica: string, pws_tip_identifica: string) => {
        if (valorCheck()) {
            const personaRequest = {
                Pws_Identificacion: values.Pws_Identificacion,
                Pws_Tip_Identificacion: values.Pws_Tip_Identificacion,
                Pws_Tip_Estado: values.Pws_Tip_Estado, // Valor constante para confirmación de información.
                Pws_Tip_Perfil: values.Pws_Tip_Perfil,
                Pws_Tip_person: values.Pws_Tip_person,
                Pws_Tip_ocupa: values.Pws_Tip_ocupa,
                Pws_Nombres: values.Pws_Nombres,
                Pws_Apellidos1: values.Pws_Apellidos1,
                Pws_Apellidos2: values.Pws_Apellidos2,
                Pws_Telefono1: values.Pws_Telefono1,
                Pws_Telefono2: values.Pws_Telefono2,
                Pws_Correo: values.Pws_Correo,
                Pws_Fec_expe: Moment(values.Pws_Fec_expe!).format('MM/DD/YYYY'),
                Pws_Lugar_exp: values.Pws_Lugar_exp,
                Pws_Fec_nacime: Moment(values.Pws_Fec_nacime!).format('MM/DD/YYYY'),
                Pws_Estado_Civil: values.Pws_Estado_Civil,
                Pws_Direccion_res: values.Pws_Direccion_res,
                Pws_Departamento: values.Pws_Departamento,
                Pws_Ciudad_res: values.Pws_Ciudad_res,
                Pws_Genero: values.Pws_Genero,
                Pws_Estrato_per: values.Pws_Estrato_per,
                Pws_Per_cargo: values.Pws_Per_cargo,
                Pws_Tip_vivienda: values.Pws_Tip_vivienda,
                Pws_Niv_estudio: values.Pws_Niv_estudio,
                Pws_Nom_empre: values.Pws_Nom_empre,
                Pws_fec_ingemp: Moment(values.Pws_fec_ingemp!).format('MM/DD/YYYY'),
                Pws_Fideliza: values.Pws_Fideliza,
                Pws_Tip_contra: values.Pws_Tip_contra,
                Pws_Ant_labo: values.Pws_Ant_labo,
                Pws_Car_emp: values.Pws_Car_emp,
                Pws_Nom_Jefedi: values.Pws_Nom_Jefedi,
                Pws_Direc_emp: values.Pws_Direc_emp,
                Pws_Ciud_emp: values.Pws_Ciud_emp,
                Pws_tel_emp1: values.Pws_tel_emp1,
                Pws_tel_emp2: values.Pws_tel_emp2,
            }
            const resultUpdatePersona = await WLOCreditAPIService.addSolicitudPersona(personaRequest)            
            if (Number(resultUpdatePersona.payload.result) > 0 || Number(resultUpdatePersona.payload.result) == -4) {
                if (Number(resultUpdatePersona.payload.result) == -4) {
                    const resultConsultaPersona = await WLOCreditAPIService.consultaSolicitudCygnus({
                        Pws_Identificacion: values.Pws_Identificacion,
                        Pws_Tip_Identificacion: values.Pws_Tip_Identificacion
                    });
                    if (!_.isEmpty(resultConsultaPersona.payload) && resultConsultaPersona.payload.result > 0) {
                        const personaConsultaResult = resultConsultaPersona.payload.data.datos_persona.Datos_persona[0];
                        const infoUpdatePerson = { ...personaRequest, Pws_i_codigo: personaConsultaResult ? personaConsultaResult.Pws_i_codigo : 0 };
                        const resultUpdatePersona2 = await WLOCreditAPIService.addSolicitudPersona(infoUpdatePerson);
                        if (resultUpdatePersona2.payload.result > 0) {
                            valuesFinancieros(numsol, values, pws_identifica, pws_tip_identifica)
                            setIsLoading(false)
                        } else {
                            setIsLoading(false)
                            toast.error(resultUpdatePersona2.payload.message);
                        }
                    }
                } else {
                    setIsLoading(false)
                    valuesFinancieros(numsol, values, pws_identifica, pws_tip_identifica)
                }
            } else {
                setIsLoading(false)
                toast.error(resultUpdatePersona.payload.MensajeError);

            }
        } else {
            toast.error("Debes completar los Documentos Necesarios")
        }

    }

    const getBase64 = (file: any) => {
        return new Promise(resolve => {
            let baseURL: string | ArrayBuffer | null = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    }

    const DatosReferenciasPersonales = (solicitud: string, identificacion: string, tipo_identificacion: string) => {
        WLOCreditAPIService.addReferences({
            s_num_solicitud: solicitud!,
            s_identificacion: identificacion!,
            s_tipo_identificacion: tipo_identificacion!,
            s_ref_tipo: "",
            s_ref_parentes: "",
            s_ref_nomcomple: "",
            s_ref_telfij: "",
            s_ref_telcel: ""
        }).then((ValorReferencias) => {
            if (Number(ValorReferencias.payload.result) > 0) {
                ReferenciaPersonales(ValorReferencias.payload.data.datos_referencia.datos_refer)
            }
            DatosReferencia({
                s_num_solicitud: solicitud!,
                s_identificacion: identificacion!,
                s_tipo_identificacion: tipo_identificacion!,
                s_ref_tipo: "",
                s_ref_parentes: "",
                s_ref_nomcomple: "",
                s_ref_telfij: "",
                s_ref_telcel: ""
            })
        })
    }
    const forwardingFirma = async () => {
        const result = await WLOCreditAPIService.addSolicitud({
            Pws_num_solicitud:arregloValor[0],
            Pws_Identificacion: arregloValor[1],
            Pws_Tip_Identificacion: arregloValor[2],
        })
        setInformacionCredito(result.payload.data.Datos_Sol.Datos_Sol[0])
        let rotativo = false
        const lista = listCredit.filter((valor: any) =>
            valor.s_codigo == Number(infoCredito.Pws_linea_credito)).length > 0 ? listCredit.filter(
                (valor: any) => valor.s_codigo == infoCredito.Pws_linea_credito)[0]['s_rotativo'] : "0"
        if (Number(lista) == 1) {
            rotativo = true
        }
        const emailInfo = {
            email: persona.Pws_Correo,
            dateSolicitud: new Date().toDateString(),
            numsol: arregloValor[0],
            ps_cod_lin: infoCredito.Pws_linea_credito,
            ps_tipoCliente: persona.Pws_Tip_ocupa,
            radicado: arregloValorCodeudor,
            Ps_tip_deucode: "2",
            rotativo: rotativo
        }
        setIsLoading(true);
        const resultEnvioCorreo = await WLOCreditAPIService.sendEmailFirmaInicial(emailInfo);
        if (resultEnvioCorreo.response.substr(0, 12) == "250 2.0.0 Ok") {
            setIsLoading(false);
            toast.success("Firma enviada correctamente")
        } else {
            toast.error("Se presento un error al momento de enviar la firma, intente nuevamente")
        }
    }

    const extraerdatcredRiesgo = async (valor: string, valor2: string, valor3: string) => {
        const result = await WLOCreditAPIService.regDataCreditoAndSagrilaft({
            Pws_num_solicitud: valor,
            Pws_Identificacion: valor2,
            Pws_Tip_Identificacion: valor3,//Valor que pide el como  ws pero a nivel de UI no existe
        })
        var i_codigo = ""
        const entidadter = await AdminService.entidadTer(i_codigo);
        if (result.payload) {
            if (result.payload.data) {
                if (result.payload.data.datos_datacre) {
                    if (result.payload.data.datos_datacre.datos_datacredito) {
                        var listado = new Array()
                        var listDataCredito = result.payload.data.datos_datacre.datos_datacredito
                        for (let index = 0; index < listDataCredito.length; index++) {
                            let listadoConsultas = {
                                estado_restop: String,
                                tipo_valida: String,
                                c_descripcion: String,
                                puntaje: String,
                                cuotas_data: String
                            }

                            listadoConsultas.estado_restop = listDataCredito[index].Pws_estado_resotp
                            listadoConsultas.tipo_valida = listDataCredito[index].Pws_Tip_val
                            listadoConsultas.c_descripcion = entidadter.payload.data.ListRef.Datos_Referencias[parseInt(listDataCredito[index].Pws_Entidad_Consul) - 1].s_descripcion
                            listadoConsultas.puntaje = listDataCredito[index].Pws_Puntaje
                            listadoConsultas.cuotas_data = listDataCredito[index].n_Pws_Num_cuodat
                            listado[index] = listadoConsultas
                        }
                        setListadoCon(listado)

                    }
                }
            }
        }
    }


    return (
        <div className="detailCreditR" >
            <ClockLoader id='spinner' color={"#d72222"} loading={isLoading} size={100} />
            <div className="header">
                <Fab variant="extended" color="neutral" aria-label="add"
                    onClick={() => {
                        navigate(-1)
                    }}
                    style={{ float: "left" }} className="hooverButtonRed">
                    <ArrowBack sx={{ mr: 1 }} className="hooverButtonRedsvg" />
                    {"Atras"}
                </Fab>
                <h2 className="title">Detalle del codeudor requerido</h2>
                
                    
                {!isupdate ? <>
                    {/* <div onClick={() => envioFirma()}>
                    <NoteAltTwoTone
                        fontSize='large'
                        htmlColor='#00908E'
                        titleAccess='Enviar Firma' />
                    </div> */}
                    <ul className="submit">  
                        <button onClick={()=>forwardingFirma()}>Enviar firma</button>
                    </ul>
                </> : <>
                    <Fab variant="extended" color="error" aria-label="add" onClick={() => {
                        document.getElementById("saveutton")?.click()
                    }} className="hooverButtonsubmit">
                        {'Agregar'}
                        <ArrowForward className="hooverButtonRedsvgsubmit buttonAgregarCoRe" sx={{ mr: 1 }} />
                    </Fab>
                </>}
            </div>
            <div className="mainDetail">
                <Formik
                    enableReinitialize={true}
                    initialValues={persona}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting, values, errors }) => (
                        <>
                            <Form>
                                <Box2 valorPersonal={{ dataConyuge: dataConyuge, person: values, update: isupdate, setPopup, arreglo: arregloValor, setUpdate, errors }} />
                                <Box3 valorFinancie={{ valorinic: values, datopeps: values, update: isupdate }} />
                                <Box5 valorReferencias={{ datosrefe: datosReferenciaPersonales, values: datosValorRefer, arreglo: arregloValor, person: values, putDatainView }} />
                                {isupdate ? <>
                                    
                                </> : <>
                                <Box9 terceros={{ valores: listadoCon, arreglos: arregloValor, infoCredito }} />
                                </>
                                }
                                <Box8 valorFinancie={{ valorinic: values, update: isupdate, datosdocuments: alldocuments, arreglo: arregloValor }} />
                                <button type="submit" style={{ display: "none" }} id="saveutton"></button>
                            </Form>
                        </>)}

                </Formik>
            </div>
        </div>
    )
}
