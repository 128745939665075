import React from 'react';
import './SolicitudesCliente.scss';
import { SolicitudesCliente } from './SolicitudesCliente';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';


export const SolicitudClienteContainer = () => {
  return (
    <>
      <ResponsiveAppBar/>
      <SolicitudesCliente />
    </>
  )
}
