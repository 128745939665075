import React from 'react';
import './upl.scss';
import ResponsiveAppBar from '../../components/shared/Menubar/ResponsiveAppBar';
import { ProfileManagement } from '../ProfileManagement/ProfileManagement'; 

export const ProfilesPlataform = () => {
  return (
    <>
      <ResponsiveAppBar/>
      <ProfileManagement />
    </>
  )
}
