import {AppStrings} from '../messages';

export const SpanishLatam: AppStrings = {
    continue: 'continuar',
    button_continue: 'Continuar',
    button_send_mail: 'Enviar correo',
    button_calculate_quote: 'Calcular cuota',
    button_back: 'Atrás',
    button_next: 'Siguiente',
    button_answer: 'Responder',
    button_yes: 'SI',
    button_no: 'NO',
    button_cancel: 'Cancelar',
    button_process: 'Procesar',
    button_add: 'Agregar',
    button_step_credit_information: 'Información del crédito',
    button_step_person_information: 'Información solicitante',
    button_step_finance_information: 'Información financiera',
    button_step_reference_information: 'Referencias y documentos',
    login_title: 'Antes que todo validemos tu identidad',
    login_subtitle: 'Ingresa los siguientes datos para continuar',
    form_login_document: 'Documento',
    form_login_password: 'Contraseña',
    form_login_lost_password: 'Olvide mi contraseña.',
    form_credit_information_title: 'Queremos conocerte un póco más',
    form_credit_information_subtitle: 'Por favor diligencia los siguientes datos',
    form_credit_information_selection: 'Si deseas continuar, ingresa el valor de cuota inicial y verifica las cuotas que deseas pagar según el plazo.',
    form_credit_information_note: 'El valor de la cuota está sujeta a la aprobación del cupo',
    form_credit_information_promotion_title: 'Para cumplir tu sueño',
    form_credit_information_promotion_subtitle: 'Te ofrecemos un crédito',
    form_applicant_information_title: 'Ahora confirmemos tu información',
    form_financial_information_title: 'Información financiera',
    form_financial_operations_title: 'Cuéntanos  sobre tus operaciones financieras',
    form_financial_information_subtitle: 'Ingresos del solicitante',
    form_financial_main_income: 'Ingreso principal',
    form_financial_other_income: 'Otros ingresos',
    form_financial_other_income_placeholder: 'Describa el concepto de otros ingresos',
    form_financial_expenditure_value: 'Valor egresos',
    form_financial_applicant_properties: 'Propiedades del solicitante',
    form_financial_income_tax_filer: 'Declarante de renta',
    form_financial_foreign_currency: '¿Realizas operaciones en moneda extranjera?',
    form_financial_foreign_currency_which: '¿Cuales operaciones?',
    form_financial_currency: 'Moneda',
    form_financial_bill_foreign_currency: 'Posees cuentas en moneda extranjera',
    form_financial_bank_foreign_currency: 'Banco',
    form_financial_number_foreign_currency: 'Número de cuenta',
    form_financial_country_foreign_currency: 'País',
    form_financial_city_foreign_currency: 'Ciudad',
    form_financial_pep_title: 'Información PEP. (Personas expuestas Publicamente)',
    form_financial_pep_subtitle: 'Por tu seguridad responde las siguientes preguntas',
    form_documents_information_title: 'Referencias y documentos',
    option_credit: 'Escoge una opción de crédito',
    initial_quote_value: 'Valor cuota inicial',
    value_to_request: 'Valor a solicitar',
    value_to_finance: 'Valor a financiar',
    value_of_quote: 'Valor cuota',
    term: 'Plazo',
    placeholder_document_type: 'Tipo de documento',
    placeholder_occupation_type: 'Tipo de ocupación',
    placeholder_identification_type: 'Tipo de Identificación',
    placeholder_identification: 'Identificación',
    placeholder_document_number: 'Número de documento',
    placeholder_field_name: 'Nombres y apellidos',
    placeholder_phone_1: 'Teléfono 1',
    placeholder_phone_2: 'Teléfono 2',
    placeholder_mail: 'Correo',
    placeholder_password: 'Contraseña',
    placeholder_mail_confirmation: 'Confirmación del correo',
    placeholder_dropdown: 'Selecciona',
    placeholder_initial_quote_text: 'Se cálcula automáticamente',
    placeholder_initial_quote_symbol: '$',
    placeholder_field_names: 'Nombres',
    placeholder_field_last_names: 'Apellidos',
    placeholder_expedition_date: 'Fecha de expedición',
    placeholder_expedition_place: 'Lugar de expedición',
    placeholder_date_birth: 'Fecha de nacimiento',
    placeholder_marital_status: 'Estado Civil',
    placeholder_company_address: 'Dirección de la empresa',
    placeholder_direct_boss: 'Jefe directo',
    placeholder_job_that_performs: 'Cargo que desempeña',
    placeholder_labor_old: 'Antigüedad laboral (Años)',
    placeholder_type_of_contract: 'Tipo de contrato',
    placeholder_date_of_admission: 'Fecha de ingreso',
    placeholder_company_name: 'Nombre de la empresa',
    placeholder_spouse: 'Cónyugue',
    placeholder_educational_level: 'Nivel de estudios',
    placeholder_type_of_housing: 'Tipo de vivienda',
    placeholder_number_of_people_in_charge: 'N° de personas a cargo',
    placeholder_gender: 'Género',
    placeholder_social_stratum: 'Estrato social',
    title_confirm_your_profile_to_continue: 'CONFIRMA TU PERFIL PARA CONTINUAR',
    welcome: 'BIENVENIDO',
    motorcycles: 'MOTOS',
    why_your_cred: '¿Para Qué Quieres Tu Crédito?',
    loyalty: 'Fidelización',
    who_accredits_motorcycle: '¿Quién acredita la moto?',
    credit_manager: 'Gestor de crédito',
    admin: 'Administrador',
    seller: 'Vendedor',
    self_management: 'Autogestión',
    how_can_we_help_you_today: '¿Cómo te podemos ayudar hoy?',
    credit_applications: 'Solicitudes de crédito',
    manage_credit: 'Gestionar crédito',
    change_of_password: 'Cambio de contraseña',
    choose_the_one_of_your_preference: 'Elige el de tu preferencia',
    it_is_calculated_automatically: "Se calcula automaticamente",
    peps_question_1: '¿Por tu cargo manejas recursos públicos?',
    peps_question_2: '¿Por tu cargo o actividad ejerces algún grado de poder público?',
    peps_question_3: '¿Gozas de reconocimiento público?',
    peps_question_4: 'Tienes algún vínculo con una persona considerada públicamente expuesta?',
    peps_question_5: '¿Tienes 2° de afinidad y 2° de consanguinidad (Padres, hermanos, esposa, primos, suegros, cuñados o concuñados) con PEP?',
    relationship: 'Parentesco',
    identification_card: 'Cédula',
    rental_value: 'Valor arriendo',
    describe: 'Describe',
    placeholder_mobile_phone_1: 'Teléfono móvil 1',
    placeholder_mobile_phone_2: 'Teléfono móvil 2',
    residence_address: 'Dirección de residencia',
    department: 'Departamento',
    gender: 'Género',
    enter: 'Ingrese',
    full_name_spouse: 'Nombre completo (Cónyugue)',
    identification_spouse: 'Identificación (Cónyugue)',
    spouse_data: 'Datos del cónyugue',
    add_reference: 'Agregar referencia',
    english: 'Inglés',
    spanish: 'Español',
    languages: 'Idiomas',
    toast_validating: 'Validando...',
    toast_processing: 'Procesando...',
    toast_charging: 'Cargando...',
    toast_failed_identity_validation: 'Los datos ingresados no coinciden',
    do_search_catalog: 'Se hace la búsqueda y se filtra automáticamente',
    client_successfully_credit_application: 'el cliente se ingreso con éxito para continuar con la solicitud de crédito',
    client_successfully_credit_application_note: 'Estaremos comunicandonos con el cliente por medio del correo electrónico y celular previamente registrados para que firme las autorizaciones correspondientes y poder continuar con la solicitud.',
    button_go_to_init: 'Ir a inicio',
    nro_quotes: 'Nro. Cuotas',
    attach_documents: 'Adjuntar documentos',
    required_documentation: 'Documentación requerida ',
    required_documentation_note_1: '(Los documentos con * son obligatorios. ',
    required_documentation_note_2: 'Se permiten PDF, JPG Y PNG. Tamaño máximo de 5Mb)',
}